import { createRouter, createWebHashHistory } from "vue-router";

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      name: "version",
      path: "/public/:version",
      component: () => import("@/pages/api/views/swagger/SwaggerVue.vue"),
    },
    {
      name: "redirect",
      path: "/redirect",
      component: () => import("@/pages/api/views/Redirect.vue"),
    },
    {
      name: "default",
      path: "/:pathMatch(.*)*",
      redirect: "/redirect",
    },
  ],
  scrollBehavior: (to, from, savedPosition): any => {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    } else {
      return { x: 0, y: 0, behavior: "smooth" };
    }
  },
});

export default router;
