export async function searchByText(text: string) {
  const url = `https://photon.komoot.io/api/?q=${text}&limit=10`;
  const response = await fetch(url);

  const data = await response.json();

  const adresses: IAdress[] = [];

  data.features.forEach((element: any) => {
    try {
      const postcode = element.properties.postcode.split(";")[0];
      const ville = element.properties.city;
      const region = element.properties.state;
      if (element.properties.postcode && ville && region) {
        adresses.push({
          adresse:
            element.properties.name ||
            element.properties.housenumber + " " + element.properties.street,
          cp: postcode,
          ville: ville,
          region: region,
          departement: element.properties.county,
          pays: element.properties.country,
        });
      }
    } catch (error) {
        console.error(error);
    }
  });
  //retourner les 5 premiers résultats
  return adresses.slice(0, 5);
}

export interface IAdress {
  adresse: string;
  cp: string;
  ville: string;
  region: string;
  departement: string;
  pays: string;
}
