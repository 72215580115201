import { IProjetLotAdjudication, IProjetLotOffre } from "@/@models/projets";
import { projetsService } from "@/api";
import { checkData, isUUIDValid, isValidStatus } from "@/utils";
import { isRef, reactive, ref, watch } from "vue";
import { useToast } from "vue-toastification";

import type { ComputedRef, Ref, WatchStopHandle } from "vue";
import dayjs from "dayjs";

interface UseProjetAdjudicationProps {
  uidProjet: string | Ref<string> | ComputedRef<string>;

  /**
   * Si vrai, charge immediatement la projet et recharge à chaque changement de 'uidProjet'
   * Il faut recreer l'instance si changer apres
   */
  autoFetch?: boolean;
  disableToast?: boolean;
}

export function useProjetAdjudication({
  uidProjet,
  autoFetch,
  disableToast,
}: UseProjetAdjudicationProps) {
  const lots = ref<IProjetLotAdjudication[]>([]);

  const loading = ref<boolean>(false);
  const isFetched = ref<boolean>(false);

  const fetchError = ref<Error>(null);

  let watchStop: WatchStopHandle = null;

  async function fetch() {
    const toastification = useToast();

    fetchError.value = null;

    const _uidProjet = isRef(uidProjet) ? uidProjet.value : uidProjet;

    if (!isUUIDValid(_uidProjet)) {
      lots.value = [];
      isFetched.value = false;
      loading.value = false;
      console.error("Error: UUID Projet vide ou invalide !");
      return;
    }

    loading.value = true;

    try {
      const { status, data } = await projetsService.getProjetAdjudicationLots(
        _uidProjet
      );

      if (!isValidStatus(status) || !checkData(data)) {
        lots.value = [];
        if (!disableToast) {
          toastification.error(
            "La récupération des informations d'adjudication a échouée."
          );
        }
      } else {
        lots.value = data.lots;
        isFetched.value = true;
      }
    } catch (err) {
      lots.value = [];
      fetchError.value = err;
    } finally {
      loading.value = false;
    }
  }

  watch(
    () => autoFetch,
    (value) => {
      if (value) {
        if (typeof watchStop == "function") {
          watchStop();
        }
        watchStop = watch(
          isRef(uidProjet) ? uidProjet : () => uidProjet,
          fetch,
          {
            immediate: true,
          }
        );
      } else {
        if (typeof watchStop == "function") {
          watchStop();
        }
      }
    },
    {
      immediate: true,
    }
  );

  const reset = () => {
    lots.value = [];
    isFetched.value = false;
    loading.value = false;
    fetchError.value = null;
  };

  return {
    lots,

    loading,

    isFetched,
    fetchError,

    fetch,
    reset,
  };
}
