import { UserAccess } from "@/stores";
import { RouteRecordRaw } from "vue-router";

export const entreprisesRoutesName = [
  "entreprise",
  "identite",
  "informations",
  "contacts",
  "documents",
  "competences",
  "historique",
  "box",
];

export const entreprisesRoutes: Array<RouteRecordRaw> = [
  {
    name: "entreprise",
    path: "entreprise",
    component: () =>
      import("@/pages/ao/views/entreprise/EspaceEntreprisePage.vue"),
    redirect: {
      name: "identite",
    },
    meta: {
      title: "Espace entreprise",
      access: [UserAccess.espaceEntreprise],
    },
    children: [
      {
        path: "identite",
        name: "identite",
        component: () =>
          import("@/pages/ao/views/entreprise/subviews/EntrepriseIdentite.vue"),
        meta: {
          title: "Identité",
          access: [UserAccess.espaceEntreprise],
        },
      },
      {
        path: "informations",
        name: "entreprise-informations",
        component: () =>
          import(
            "@/pages/ao/views/entreprise/subviews/EntrepriseInformations.vue"
          ),
        meta: {
          title: "Informations",
          access: [UserAccess.espaceEntreprise],
        },
      },
      {
        path: "contacts",
        name: "contacts",
        component: () =>
          import("@/pages/ao/views/entreprise/subviews/EntrepriseContacts.vue"),
        meta: {
          title: "Contacts",
          access: [UserAccess.espaceEntreprise],
        },
      },
      {
        path: "documents",
        name: "entreprise-documents",
        component: () =>
          import(
            "@/pages/ao/views/entreprise/subviews/EntrepriseDocuments.vue"
          ),
        meta: {
          title: "Documents",
          access: [UserAccess.espaceEntreprise],
        },
      },
      {
        path: "competences",
        name: "competences",
        component: () => import("@/views/BlankPage.vue"),
        meta: {
          title: "Competences",
          access: [UserAccess.espaceEntreprise],
        },
      },
      {
        path: "historique",
        name: "historique",
        component: () =>
          import(
            "@/pages/ao/views/entreprise/subviews/EntrepriseHistorique.vue"
          ),
        meta: {
          title: "historique",
          access: [UserAccess.espaceEntreprise],
        },
      },

      {
        path: "box",
        name: "box",
        component: () =>
          import("@/pages/ao/views/entreprise/subviews/EntrepriseBox.vue"),
        meta: {
          title: "box",
          access: [UserAccess.espaceEntreprise],
        },
      },
    ],
  },
];
