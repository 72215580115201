<template>
  <div class="relative w-full">
    <input
      type="range"
      min="0"
      max="100"
      step="1"
      v-model="model"
      :style="{
        backgroundSize: backgroundSize,
        '--color-front': fColor,
        '--color-back': bColor,
      }"
      class="rounded-full "
      style="height: 10px;"
    />
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  computed,
  watch,
  defineProps,
  defineEmits,
  WritableComputedRef,
} from "vue";
const props = defineProps({
  modelValue: [Object, String, Number, Boolean],

  min: {
    type: Number,
    default: 0,
  },
  max: {
    type: Number,
    default: 100,
  },
  frontColor: {
    type: String,
    default: "rgb(var(--color-warning)",
  },
  backColor: {
    type: String,
    default: "rgb(var(--color-dark)",
  },
});

const fColor = computed(() => {
  if (props.frontColor.includes("primary")) {
    return "rgb(var(--color-primary))";
  } else if (props.frontColor.includes("warning")) {
    return "rgb(var(--color-warning))";
  } else if (props.frontColor.includes("danger")) {
    return "rgb(var(--color-danger))";
  } else if (props.frontColor.includes("success")) {
    return "rgb(var(--color-success))";
  } else if (props.frontColor.includes("info")) {
    return "rgb(var(--color-info))";
  } else {
    return props.frontColor;
  }
});
const bColor = computed(() => {
  if (props.backColor.includes("primary")) {
    return "rgb(var(--color-primary))";
  } else if (props.backColor.includes("warning")) {
    return "rgb(var(--color-warning))";
  } else if (props.backColor.includes("danger")) {
    return "rgb(var(--color-danger))";
  } else if (props.backColor.includes("success")) {
    return "rgb(var(--color-success))";
  } else if (props.backColor.includes("info")) {
    return "rgb(var(--color-info))";
  } else {
    return props.backColor;
  }
});

const emit = defineEmits(["update:modelValue"]);

const model = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", parseInt(value)),
});

const backgroundSize = ref("0% 100%");

function updateSlider(clickedElement) {
  const min = clickedElement.min,
    max = clickedElement.max,
    val = clickedElement.value;

  backgroundSize.value = ((val - min) * 100) / (max - min) + "% 100%";
}

onMounted(() => {
  updateSlider({
    min: props.min,
    max: props.max,
    value: props.modelValue,
  });
});
watch(
  () => props.modelValue,
  (newValue) => {
    updateSlider({
      min: props.min,
      max: props.max,
      value: newValue,
    });
  }
);
</script>

<style scoped>
input[type="range"] {
  -webkit-appearance: none;
  display: block;
  width: 100%;
  margin: 16px 0;
  background: var(--color-back);
  background-image: -webkit-gradient(
    linear,
    20% 0%,
    20% 100%,
    color-stop(0%, var(--color-front)),
    color-stop(100%, var(--color-front))
  );
  background-image: -webkit-linear-gradient(
    left,
    var(--color-front) 0%,
    var(--color-front) 100%
  );
  background-image: -moz-linear-gradient(
    left,
    var(--color-front) 0%,
    var(--color-front) 100%
  );
  background-image: -o-linear-gradient(
    to right,
    var(--color-front) 0%,
    var(--color-front) 100%
  );
  background-image: linear-gradient(
    to right,
    var(--color-front) 0%,
    var(--color-front) 100%
  );
  background-repeat: no-repeat;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  box-shadow: none;
  background: transparent;
  border-radius:0px;
  border: none;
}
input[type="range"]::-webkit-slider-thumb {
  box-shadow: none;
  border: 6px solid var(--color-front);
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: var(--color-back);
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -6px;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: transparent;
}
input[type="range"]::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  box-shadow: none;
  background: transparent;
  border-radius: 0px;
  border: none;
}
input[type="range"]::-moz-range-thumb {
  box-shadow: none;
  border: 10px solid var(--color-front);
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background: var(--color-back);
  cursor: pointer;
}
input[type="range"]::-ms-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type="range"]::-ms-fill-lower {
  background: transparent;
  border: none;
  border-radius: 0px;
  box-shadow: none;
}
input[type="range"]::-ms-fill-upper {
  background: transparent;
  border: none;
  border-radius: 0px;
  box-shadow: none;
}
input[type="range"]::-ms-thumb {
  box-shadow: none;
  border: 6px solid var(--color-front);
  height: 20px;
  width: 20px;
  border-radius: 0;
  background: #ffffff;
  cursor: pointer;
  height: 4px;
}
input[type="range"]:focus::-ms-fill-lower {
  background: transparent;
}
input[type="range"]:focus::-ms-fill-upper {
  background: transparent;
}
/* End Range Slider */
</style>
