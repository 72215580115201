import { rgbToHex, toBase64 } from "@/utils";
import { defineStore, storeToRefs } from "pinia";
import { usePreviewStore } from "./preview";
import { useSocietesStore } from "@/stores";
import { ref } from "vue";
import { IPageContext } from "@/@models/preview";

/**
 * Contient les prefs global pour la partie appel d'offre
 */
export const usePreviewLoginStore = defineStore("preview-login", () => {
  const previewStore = usePreviewStore();
  const societeStore = useSocietesStore();

  const currentView = ref<"login" | "password" | "disabled">("login");

  const { color, desactive, image, logo, slogan, instruction } =
    storeToRefs(societeStore);

  async function initialize() {
    societeStore.clearPrefs();

    const data = await previewStore.fetchContext({
      societe_prefs: null,
    });

    if (data?.societe_prefs) {
      const prefs = data.societe_prefs;
      if (prefs.web_ao_couleur) {
        if (Number.isInteger(prefs.web_ao_couleur)) {
          const colorInt = parseInt(prefs.web_ao_couleur);
          const r = Math.floor(colorInt / (256 * 256));
          const g = Math.floor(colorInt / 256) % 256;
          const b = colorInt % 256;
          color.value = rgbToHex(r, g, b);
        } else {
          color.value = prefs.web_ao_couleur;
        }
      }

      if (prefs.web_ao_img_bandeau) {
        image.value = prefs.web_ao_img_bandeau;
      }

      if (prefs.web_ao_img_grandlogo) {
        logo.value = prefs.web_ao_img_grandlogo;
      }

      if (prefs.web_ao_instruction) {
        instruction.value = prefs.web_ao_instruction;
      }

      if (prefs.web_ao_slogan) {
        slogan.value = prefs.web_ao_slogan;
      }
    }
  }

  async function save() {
    const previewStore = usePreviewStore();

    const previewBlob = await previewStore.getPreviewImg("blob");
    const previewB64 = previewBlob ? await toBase64(previewBlob as any) : null;

    const data: Partial<IPageContext> = {
      societe_prefs: {
        web_ao_slogan: slogan.value,
        web_ao_instruction: instruction.value,
        web_ao_img_bandeau: image.value,
        web_ao_img_grandlogo: logo.value,
        web_ao_previsualisation: previewB64 as string,
        web_ao_couleur: color.value,
      },
    };

    await previewStore.saveContext(data);
  }

  return {
    color,
    desactive,
    image,
    logo,
    slogan,
    instruction,

    currentView,

    initialize,
    save,
  };
});
