import { SectionPlane } from "@/@types/xeokit";
import { AssociativeUtils } from "@/utils";
import { computed } from "vue";
import { useViewer } from "@/composables/viewer";

const events = {};

export function useViewerSectionPlugin() {

    const { bimcradle } = useViewer();

    const sectionPlugin = computed(() => bimcradle.value && bimcradle.value.sectionPlanesPlugin)

    const getSections = () => {
        if (sectionPlugin.value) {
            return AssociativeUtils.toArray(sectionPlugin.value.sectionPlanes)
        }
        return [];
    }


    const createSection = (id: string = null, active = true) => {
        if (sectionPlugin.value) {
            const section = sectionPlugin.value.createSectionPlane({
                id
            })
            if (section) {
                section.active = active;
                section.meta.name = "Nouvelle section"
            }
            addEvents(section);
            if (events["created"]) {
                events["created"](section)
                section.fire("created", section)
            }
        }
    }

    const addSection = (pSection) => {
        if (sectionPlugin.value) {
            const section = sectionPlugin.value.createSectionPlane({
                id: pSection.id,
                pos: AssociativeUtils.toArray(pSection.pos),
                dir: AssociativeUtils.toArray(pSection.dir)
            })
            if (section) {
                section.active = pSection.active;
                section.meta = pSection.meta;
            }
            addEvents(section);
        }
    }

    const addEvents = (section: SectionPlane) => {
        if (section) {
            section.on("dir", () => events["updated"] ? events["updated"](section) : null)
            section.on("pos", () => events["updated"] ? events["updated"](section) : null)
            section.on("destroyed", () => {
                if (section) {
                    section.off("dir")
                    section.off("pos")
                    section.off("destroyed")
                    events["deleted"] ? events["deleted"](section) : null
                }
            })
        }
    }

    const deleteSection = (id: string) => {
        if (sectionPlugin.value) {
            sectionPlugin.value.destroySectionPlane(id);
        }
    }

    const setControl = (id?: string, activeOnSetControl = true) => {
        if (sectionPlugin.value) {
            if (id) {
                sectionPlugin.value.showControl(id);
                if (sectionPlugin.value.sectionPlanes[id]) {
                    sectionPlugin.value.sectionPlanes[id].active = activeOnSetControl;
                }
            } else {
                sectionPlugin.value.hideControl();
            }
        }
    }

    const hasControl = (id): boolean => {
        if (sectionPlugin.value) {
            return sectionPlugin.value.getShownControl() == id
        }
        return false;
    }

    const deleteAll = () => {
        if (sectionPlugin.value) {
            getSections().forEach(s => deleteSection(s.id))
        }
    }

    const subscribe = (eventName: "created" | "deleted" | "updated", callback: (section: SectionPlane) => void) => {
        events[eventName] = callback
    }

    const unsubscribe = (eventName: "created" | "deleted" | "updated") => {
        delete events[eventName];
    }

    return {
        sectionPlugin,

        getSections,
        createSection,
        addSection,
        deleteSection,
        setControl,
        hasControl,
        deleteAll,

        subscribe,
        unsubscribe
    }
}