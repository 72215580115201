import { IConsultationPieceAFournir } from "@/@models/consultations";
import { IProjetLotOffre } from "@/@models/projets";
import {
  IProjetIntervenant,
  IProjet,
  IProjetLot,
  IProjetIntervenantContact,
  IProjetConsultation,
  IProjetAppelOffre,
  IProjetLotInfo,
  IProjetLotAdjudication,
  IProjetAdjudicationOffre,
} from "@/@models/projets";
import axios from "@/plugins/axios";

export const projetsService = {
  /**
   * Recupere toutes les societes
   */
  getProjets() {
    return axios.get<{
      projets: IProjet[];
    }>(`ENTREPRISE/PROJETS`);
  },
  /**
   * Recupere un projet
   */
  getProjet(uid_projet: string) {
    return axios.get<{
      projet: IProjet;
    }>(`ENTREPRISE/PROJETS/${uid_projet}`);
  },
  /**
   * Recupere le logo d'un projet
   */
  getProjetLogo(uid_projet: string, image_id: string) {
    return axios.get(`ENTREPRISE/PROJETS/${uid_projet}/LOGO/${image_id}`, {
      responseType: "blob",
    });
  },
  /**
   * Recupere les intervenants d'un projet
   */
  getProjetIntervenants(uid_projet: string) {
    return axios.get<{
      intervenants: IProjetIntervenant[];
    }>(`ENTREPRISE/PROJETS/${uid_projet}/INTERVENANTS`);
  },
  /**
   * Recupere un intervenant d'un projet
   */
  getProjetIntervenant(uid_projet: string, index_intervenant: number) {
    return axios.get<{
      intervenant: IProjetIntervenant;
    }>(`ENTREPRISE/PROJETS/${uid_projet}/INTERVENANTS/${index_intervenant}`);
  },
  /**
   * Recupere les contacts d'un intervenant d'un projet
   */
  getProjetIntervenantContacts(uid_projet: string, index_intervenant: number) {
    return axios.get<{
      contacts: IProjetIntervenantContact[];
    }>(
      `ENTREPRISE/PROJETS/${uid_projet}/INTERVENANTS/${index_intervenant}/CONTACTS`
    );
  },
  /**
   * Recupere les contacts d'un intervenant d'un projet
   */
  getProjetIntervenantLogo(uid_projet: string, index_intervenant: number) {
    return axios.get(
      `ENTREPRISE/PROJETS/${uid_projet}/INTERVENANTS/${index_intervenant}/LOGO`,
      {
        responseType: "blob",
      }
    );
  },
  /**
   * Recupere les intervenants d'un projet
   */
  getProjetLots(uid_projet: string) {
    return axios.get<{
      lots: IProjetLot[];
    }>(`ENTREPRISE/PROJETS/${uid_projet}/LOTS`);
  },
  /**
   * Recupere les consultations d'un projet
   */
  getProjetConsultations(uid_projet: string) {
    return axios.get<{
      consultations: IProjetConsultation[];
      appelOffre: IProjetAppelOffre;
    }>(`ENTREPRISE/PROJETS/${uid_projet}/CONSULTATIONS`);
  },
  /**
   * Recupere les informations sur lots de la consultation du projet
   */
  getProjetConsultationLotsInfo(uid_projet: string, uid_consultation: string) {
    return axios.get<{
      lots: IProjetLotInfo[];
    }>(
      `ENTREPRISE/PROJETS/${uid_projet}/CONSULTATIONS/${uid_consultation}/LOTS/INFO`
    );
  },
  /**
   * Recupere les informations sur lots de la consultation du projet
   */
  getProjetAdjudicationLots(uid_projet: string) {
    return axios.get<{
      lots: IProjetLotAdjudication[];
    }>(`ENTREPRISE/PROJETS/${uid_projet}/ADJUDICATION/LOTS`);
  },
  /**
   * Recupere les informations sur offres d'un lot d'une adjudication
   */
  getProjetAdjudicationLotOffres(uid_projet: string, uid_lot: string) {
    return axios.get<{
      offres: IProjetAdjudicationOffre[];
      documents: any[];
    }>(`ENTREPRISE/PROJETS/${uid_projet}/ADJUDICATION/LOTS/${uid_lot}/OFFRES`);
  },
  /**
   * Recupere toutes les offres d'une entreprise sur un lot
   */
  getProjetAdjudicationEntrepriseLotOffres(
    uid_projet: string,
    index_entrepriseConsult: number,
    uid_lot: string
  ) {
    return axios.get<{
      offres: IProjetLotOffre[];
    }>(
      `ENTREPRISE/PROJETS/${uid_projet}/ADJUDICATION/ENTREPRISES/${index_entrepriseConsult}/LOTS/${uid_lot}/OFFRES`
    );
  },
  /**
   * Recupere l'intervenant d'une entreprise adjudication
   */
  getProjetAdjudicationEntrepriseIntervenant(
    uid_projet: string,
    index_entrepriseConsult: number
  ) {
    return axios.get<{
      intervenant: IProjetIntervenant & {
        contacts: IProjetIntervenantContact[];
      };
    }>(
      `ENTREPRISE/PROJETS/${uid_projet}/ADJUDICATION/ENTREPRISES/${index_entrepriseConsult}/INTERVENANT`
    );
  },
  /**
   * Recupere les informations sur les pieces à fournir d'une entreprise
   * @param uid_projet
   * @param index_entrepriseConsult
   */
  getProjetAdjudicationEntrepriseCandidature(
    uid_projet: string,
    index_entrepriseConsult: number
  ) {
    return axios.get<{
      pieces: IConsultationPieceAFournir[];
    }>(
      `ENTREPRISE/PROJETS/${uid_projet}/ADJUDICATION/ENTREPRISES/${index_entrepriseConsult}/CANDIDATURE`
    );
  },
};
