import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.elemClass.main)
  }, [
    (_ctx.hasHeaderSlot)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.elemClass.header),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('header', $event)))
        }, [
          _renderSlot(_ctx.$slots, "header")
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.elemClass.input)
    }, [
      (_ctx.hasAppendSlot)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "flex-shrink",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('append', $event)))
          }, [
            _renderSlot(_ctx.$slots, "append")
          ]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default"),
      (_ctx.hasPrependSlot)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "flex-shrink",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('prepend', $event)))
          }, [
            _renderSlot(_ctx.$slots, "prepend")
          ]))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.hasFooterSlot)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(_ctx.elemClass.footer),
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('footer', $event)))
        }, [
          _renderSlot(_ctx.$slots, "footer")
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}