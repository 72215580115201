import {
  ICategoryFilters,
  ICreateGroup,
  IGroupParticipant,
  IMessage,
  IOptionalGroupParticipant,
} from "@/pages/bureau/@models/fil.model";
import { IPagination } from "@/pages/bureau/@types/api";
import axios from "./index";

const BASE_ENDPOINT = "BUREAU";

export const filService = {
  getFilsNonLus() {
    return axios.get<{
      nonLus: {
        uidGroup: string;
        nbMessNonLu: number;
      }[];
    }>(`${BASE_ENDPOINT}/FIL/NONLUS`);
  },
  getCategories(filters?: ICategoryFilters) {
    return axios.post<{
      categories: any[];
    }>(`${BASE_ENDPOINT}/FIL/CATEGORIES`, filters);
  },
  getCategorieImage(uidGroup: string) {
    return axios.get(`${BASE_ENDPOINT}/FIL/${uidGroup}/IMAGE`, {
      responseType: "blob",
    });
  },

  getPaginatedMessages(
    uidGroup: string,
    page: number,
    {
      filtreFavoris = false,
      filtreDocuments = false,
    }: { filtreFavoris?: boolean; filtreDocuments?: boolean }
  ) {
    return axios.post<{
      messages: IMessage[];
      pagination: IPagination;
    }>(`${BASE_ENDPOINT}/FIL/${uidGroup}`, {
      page,
      filtreFavoris,
      filtreDocuments,
    });
  },

  setFilLu(uidGroup: string) {
    return axios.post(`${BASE_ENDPOINT}/FIL/${uidGroup}/LU`);
  },

  createMessage(uidGroup: string, message: string) {
    return axios.put(`${BASE_ENDPOINT}/FIL/${uidGroup}/MESSAGE`, {
      message,
    });
  },
  updateMessage(uidGroup: string, uidMessage: string, message: string) {
    return axios.post(
      `${BASE_ENDPOINT}/FIL/${uidGroup}/MESSAGE/${uidMessage}`,
      {
        message,
      }
    );
  },
  removeMessage(uidGroup: string, uidMessage: string) {
    return axios.delete(
      `${BASE_ENDPOINT}/FIL/${uidGroup}/MESSAGE/${uidMessage}`
    );
  },

  createMessageDoc(uidGroup: string, b64: string, fileName: string) {
    return axios.put(`${BASE_ENDPOINT}/FIL/${uidGroup}/DOC`, {
      b64,
      fileName,
    });
  },
  /**
   *
   * @ create/join group
   */
  createGroup(type: string, data: ICreateGroup) {
    return axios.put<{
      uidGroup: string;
    }>(`${BASE_ENDPOINT}/FIL/GROUP/${type}`, data);
  },

  getFilParticipants(uidGroup: string) {
    return axios.get<{
      participants: IGroupParticipant[];
    }>(`${BASE_ENDPOINT}/FIL/${uidGroup}/PARTICIPANTS`);
  },

  /**
   *
   * @addArray : array of user index to add
   * @removeArray : array of participant uid to remove
   * @addNamesArray : array of user names to add
   * @removeNamesArray : array of participant names to remove
   */
  updateFilParticipants(
    uidGroup: string,
    data: {
      addArray: number[];
      removeArray: string[];
      addNamesArray: string[];
      removeNamesArray: string[];
    }
  ) {
    return axios.post(`${BASE_ENDPOINT}/FIL/${uidGroup}/PARTICIPANTS`, data);
  },

  /**
   *
   * @addArray : array of user index to add
   * @removeArray : array of participant uid to remove
   * @addNamesArray : array of user names to add
   * @removeNamesArray : array of participant names to remove
   */
  updateFilParticipant(
    uidGroup: string,
    uidParticipant: string,
    data: IOptionalGroupParticipant
  ) {
    return axios.post(
      `${BASE_ENDPOINT}/FIL/${uidGroup}/PARTICIPANT/${uidParticipant}`,
      data
    );
  },

  setFavoris(uidGroup: string, uidMessage: string, estFavoris: boolean) {
    return axios.post(
      `${BASE_ENDPOINT}/FIL/${uidGroup}/MESSAGE/${uidMessage}/FAVORIS`,
      {
        estFavoris,
      }
    );
  },
};
