

// Basé sur le composant ListBox de HeadlessUI
// https://github.com/tailwindlabs/headlessui/blob/main/packages/%40headlessui-vue/src/components/listbox/listbox.ts

import { Focus } from "@/utils/calculate-active-index"
import { ComputedRef, inject, InjectionKey, Ref } from "vue"
import { ThemeType } from "../type"

export function defaultComparator<T>(a: T, z: T): boolean {
    return a === z
}

export enum ListboxStates {
    Open,
    Closed,
}

export enum ValueMode {
    Single,
    Multi,
}

export enum ActivationTrigger {
    Pointer,
    Other,
}

export function nextFrame(cb: () => void) {
    requestAnimationFrame(() => requestAnimationFrame(cb))
}

export type ListboxOptionData = {
    selected: boolean | ComputedRef<boolean>;
    textValue: string;
    disabled: boolean;
    value: unknown;
    domRef: Ref<HTMLElement | null>;
}

export type StateDefinition = {
    // State
    listboxState: Ref<ListboxStates>;
    value: ComputedRef<unknown>;
    orientation: Ref<'vertical' | 'horizontal'>;

    mode: ComputedRef<ValueMode>;

    label?: ComputedRef<string>;
    theme: {
        name: ThemeType,
        enable: string,
    };

    compare: (a: unknown, z: unknown) => boolean

    labelRef?: Ref<HTMLLabelElement | null>
    buttonRef: Ref<HTMLButtonElement | null>
    optionsRef: Ref<HTMLDivElement | null>

    disabled: Ref<boolean>
    options: Ref<{ id: string; dataRef: ComputedRef<ListboxOptionData> }[]>
    searchQuery: Ref<string>
    activeOptionIndex: Ref<number | null>
    activationTrigger: Ref<ActivationTrigger>

    // State mutators
    closeListbox(): void
    openListbox(): void
    goToOption(focus: Focus, id?: string, trigger?: ActivationTrigger): void
    search(value: string): void
    clearSearch(): void
    registerOption(id: string, dataRef: ComputedRef<ListboxOptionData>): void
    unregisterOption(id: string): void
    select(value: unknown): void

    //helper
    getSelectedOption(): Ref<{ id: string; dataRef: ComputedRef<ListboxOptionData> }> | null
}

export const BListboxContext = Symbol('BListboxContext') as InjectionKey<StateDefinition>

export function useListboxContext(component: string) {
    const context = inject(BListboxContext, null)

    if (context === null) {
        const err = new Error(`<${component} /> is missing a parent <BListbox /> component.`)
        if (Error.captureStackTrace) Error.captureStackTrace(err, useListboxContext)
        throw err
    }

    return context
}