import { ILocalisationGlobal } from "@/pages/bureau/@types/localisation";

const localisationGlobal: ILocalisationGlobal = {
  home: {
    perso: "Gestion personnelle",
    persoLink: "Gestion perso",
    data: "Données",
    setting: "Paramètres",
    expenses: "Frais depuis le",
    pointages: "Pointages de cette semaine",
    fil: "Messages non lus",
    rdv: "Dont {count} aujoud'hui",
    tasks: "Dont {count} en retard",
    projet: "Projets en cours",
    intervenant: "Intervenants",
    article: "Articles de base de connaissances",
  },

  settings: {
    title: "Paramètres",
  },
  navBar: {
    settings: "Paramètres",
    data: "Données",
    personal: "Gestion perso",
    logout: "Déconnexion",
  },
  button: {
    confirm: "Valider",
    cancel: "Annuler",
    add: "Ajouter",
    back: "Retour",
    close: "Fermer",
    edit: "Modifier",
    delete: "Supprimer",
    show: "Voir",
    open: "Ouvrir",
    remove: "Retirer",
    new: "Nouveau",
    create: "Créer",
    submit: "Valider",
    save: "Enregistrer",
    reset: "Réinitialiser",
  },

  notFound: {
    title: "Page non trouvée",
    message:
      "La ressource demandée n'a pas été trouvée, la plateforme est peut-être indisponible ou l'URL est invalide",
    action: "Retourner à l'accueil",
  },

  loading: {
    default: "Chargement...",
  },
  pwa: {
    offlineReady: "L'application est à jour !",
    needUpdate: "Nouvelle mise à jour détectée !",
    needUpdateButton: "Mettre à jour",
    needUpdateClose: "Fermer",
  },
};

export default localisationGlobal;
