import { bimService, FiltreElementCaoIfcParameter } from '@/api';
import { useViewer } from '@/composables/viewer';
import { AssociativeUtils, checkData, isValidStatus } from '@/utils';
import { defineStore } from 'pinia'
import { useFichiersCaoStore } from './viewer-fichiers-cao';


export enum ElementStatut {
    visible,
    hidden,
}

export interface IViewerFilters {
    disable: boolean

    floors: string[],
    layers: string[],
    types: string[],
    buildings: string[],
    apartments: string[],

    /**
     * Contient tous les elements visible par les filtres
     */
    elements: { [idIfc: string]: ElementIfc }
}

export type ElementIfc = {
    idIfc: string;
    name: string;

    statut: ElementStatut;
    hideOnClick?: boolean;
}

export const useViewerFiltresStore = defineStore('viewer-filtres', {
    state: (): IViewerFilters => {
        return {
            disable: false,

            floors: [],
            layers: [],
            buildings: [],
            apartments: [],
            types: [],

            elements: {}
        }
    },
    getters: {
        isFilterFloor(state) {
            return (name: string): boolean => state.floors.indexOf(name) >= 0;
        },
        isFilterLayer(state) {
            return (name: string): boolean => state.layers.indexOf(name) >= 0;
        },
        isFilterBuilding(state) {
            return (name: string): boolean => state.buildings.indexOf(name) >= 0;
        },
        isFilterApartment(state) {
            return (name: string): boolean => state.apartments.indexOf(name) >= 0;
        },
        isFilterType(state) {
            return (name: string): boolean => state.types.indexOf(name) >= 0;
        },
        getElements(state) {
            return AssociativeUtils.toArray(state.elements);
        }
    },
    actions: {
        addFloor(name: string) {
            if (!this.floors.includes(name)) {
                this.floors.push(name)
            }
        },
        removeFloor(name: string) {
            const index = this.floors.indexOf(name);
            if (index >= 0) {
                this.floors.splice(index, 1)
            }
        },


        addLayer(name: string) {
            if (!this.layers.includes(name)) {
                this.layers.push(name)
            }
        },
        removeLayer(name: string) {
            const index = this.layers.indexOf(name);
            if (index >= 0) {
                this.layers.splice(index, 1)
            }
        },


        addBuilding(name: string) {
            if (!this.buildings.includes(name)) {
                this.buildings.push(name)
            }
        },
        removeBuilding(name: string) {
            const index = this.buildings.indexOf(name);
            if (index >= 0) {
                this.buildings.splice(index, 1)
            }
        },

        addApartment(name: string) {
            if (!this.apartments.includes(name)) {
                this.apartments.push(name)
            }
        },
        removeApartment(name: string) {
            const index = this.apartments.indexOf(name);
            if (index >= 0) {
                this.apartments.splice(index, 1)
            }
        },

        addType(name: string) {
            if (!this.types.includes(name)) {
                this.types.push(name)
            }
        },
        removeType(name: string) {
            const index = this.types.indexOf(name);
            if (index >= 0) {
                this.types.splice(index, 1)
            }
        },


        toggleElementVisibility(idIfc: string) {
            if (idIfc) {
                const elem = this.elements[idIfc]
                if (elem) {
                    if (elem.statut == ElementStatut.visible) {
                        elem.statut = ElementStatut.hidden
                    } else {
                        elem.statut = ElementStatut.visible
                    }
                }
            }
        },
        addElement(element: ElementIfc) {
            if (element && element.idIfc) {
                if (this.elements[element.idIfc]) {
                    const temp = { ...this.elements[element.idIfc], ...element }
                    this.elements[element.idIfc] = temp
                } else {
                    this.elements[element.idIfc] = element
                }
            }
        },
        deleteElement(idIfc: string) {
            if (idIfc && this.elements[idIfc]) {
                delete this.elements[idIfc];
            }
        },

        async fetchFilteredElements(uidFichier: string = null) {
            const { fichierCao } = useFichiersCaoStore();
            const { getElementName } = useViewer();

            const reqData: FiltreElementCaoIfcParameter = {
                etageElement: this.floors,
                calqueElement: this.layers,
                batimentElement: this.buildings,
                appartementElement: this.apartments,
                typeElement: this.types
            }

            if (this.floors.length || this.layers.length || this.buildings.length || this.apartments.length || this.types.length) {
                const { status, data } = await bimService.postFilreElementsCao(fichierCao?.uid ?? uidFichier, reqData);

                if (!isValidStatus(status) || !checkData(data)) {
                    this.error = Error("La recherche des éléments filtrés a échoué.");
                    return false;
                }

                this.removeFilteredElements();

                // on merge
                let elem: ElementIfc;
                data.elements.forEach(async (e) => {
                    if (e) {
                        elem = this.elements[e]
                        if (elem) {
                            elem.statut = ElementStatut.hidden
                        } else {
                            this.elements[e] = {
                                idIfc: e,
                                name: await getElementName(e),
                                statut: ElementStatut.hidden
                            }
                        }
                    }
                })

            } else {
                this.removeFilteredElements();
            }

            return true;
        },

        removeFilteredElements() {
            const result = AssociativeUtils.filter(this.elements, (e: ElementIfc) => e.hideOnClick == true)
            if (result) {
                this.elements = AssociativeUtils.convertToAssociative(result, "idIfc");
            }
        },

        storeFilters(key: string) {
            localStorage.setItem(
                `viewer-${key}-filters`, JSON.stringify(this.$state)
            );
        },


        fetchFiltersFromLocalStorage(key: string) {
            const filters = localStorage.getItem(
                `viewer-${key}-filters`
            );
            if (filters) {
                this.$patch(JSON.parse(filters));
            }
        },

        clearFilter() {
            this.floors = [];
            this.layers = [];
            this.types = [];
            this.buildings = [];
            this.apartments = [];
            this.elements = {};
        }
    }
})
