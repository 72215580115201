import { UserAccess } from "@/stores";
import { RouteRecordRaw } from "vue-router";
import { PieType } from "@/pages/ao/views/evenements/subres/pie_types";

export const evenementsRoutesName = [
  "evenements",
  "ev" + PieType.c,
  "ev" + PieType.d,
  "ev" + PieType.q,
];

export const evenementsRoutes: Array<RouteRecordRaw> = [
  {
    name: "evenements",
    path: "evenements",
    component: () => import("@/pages/ao/views/evenements/EvenementsPage.vue"),
    redirect: {
      name: "ev" + PieType.c,
    },
    meta: {
      title: "Evenements",
      access: [UserAccess.evenements],
    },
    children: [
      {
        path: "ev" + PieType.c,
        name: "ev" + PieType.c,
        component: () =>
          import(
            "@/pages/ao/views/evenements/subviews/EvenementsListConsultations.vue"
          ),
        meta: {
          title: PieType.c,
          access: [UserAccess.evenements],
        },
      },
      {
        path: "ev" + PieType.d,
        name: "ev" + PieType.d,
        component: () =>
          import(
            "@/pages/ao/views/evenements/subviews/EvenementsListDecisions.vue"
          ),
        meta: {
          title: PieType.d,
          access: [UserAccess.evenements],
        },
      },
      {
        path: "ev" + PieType.q,
        name: "ev" + PieType.q,
        component: () =>
          import(
            "@/pages/ao/views/evenements/subviews/EvenementsListQuestions.vue"
          ),
        meta: {
          title: PieType.q,
          access: [UserAccess.evenements],
        },
      },
    ],
  },
];
