import { UserModule } from "@/pages/bureau/store/modules/user.module";
import { UserRole } from "@/pages/bureau/@models/user.model";
import {
  RouteLocationNormalizedLoaded,
  RouteRecordNormalized,
  useRoute,
  useRouter,
} from "vue-router";
import { LayoutEnum } from "./useLayout";
import { IconType } from "@/components/global";
import { Route } from "@/pages/bureau/router";
import { useI18n } from "vue-i18n";
import { baseRoutes } from "@/pages/bureau/router";

export enum NavGroup {
  home,
  perso,
  data,
  params,
}

export interface IMeta {
  layout?: {
    /**
     * Type de layout
     */
    type?: LayoutEnum;
    /**
     * Si vrai, alors on peut surcharger le layout
     */
    strict?: boolean;
    /**
     * Si affiche la navbar en mobile
     */
    showNavbarOnMobile?: boolean;
    canModal?: boolean;
  };
  roles?: UserRole[];
  navInfo?: NavInfo;
  pageInfo?: PageHeaderInfo;
  /**
   * Page caching
   */
  keepAlive?: boolean;
  /**
   * Pour les pages autorisées en modal
   */
  canModal?: boolean;
}

export type PageHeaderInfo = {
  /**
   * Affiche le composant si vrai et que le composant existe (voir le router)
   */
  show?: boolean;
  i18nTitle?: string;
};

export type NavInfo = {
  order?: number;
  navGroup?: NavGroup;
  subNavGroup?: NavGroup;
  i18nLabel?: string;
  icon?: IconType;
};

export type PathData = {
  homeRoute?: Route;
  backRoute?: Route;
  routes: Route[];
};

export default function useNavigation() {
  const router = useRouter();
  const route = useRoute();

  const { t } = useI18n();

  const getAuthorizedRoutesByGroup = (group: NavGroup) => {
    return router
      .getRoutes()
      .filter(
        (x) =>
          getMetaFromRoute(x)?.navInfo?.navGroup == group &&
          UserModule.checkRoles(x)
      )
      .sort(
        (x, y) =>
          (x.meta as IMeta).navInfo.order - (y.meta as IMeta).navInfo.order
      );
  };

  const getPageHeaderInfo = (): PageHeaderInfo => {
    const meta = getMeta();
    if (meta?.pageInfo) {
      return {
        show: meta?.pageInfo.show ?? false,
        i18nTitle: meta?.pageInfo.i18nTitle ?? "",
      };
    }
  };

  const getMeta = (): IMeta => {
    if (route.meta) {
      return route.meta;
    }
  };

  const getMetaFromRoute = (_route: RouteRecordNormalized): IMeta => {
    if (_route.meta) {
      return _route.meta;
    }
  };

  /**
   * Recupere la route la plus proche apres le login
   * @returns
   */
  const getRootRoute = () => {
    if (route) {
      return router
        .getRoutes()
        .find((x) => x.path == "/")
        .children.filter((x) => route.path.startsWith(x.path));
    }
  };

  /**
   * Recupere la route de la page principal
   * @returns
   */
  const getHomeRoute = () => {
    if (route) {
      return router
        .getRoutes()
        .find((x) => x.path == "/")
        .children.find((x) => x.name == "home");
    }
  };

  const getHomeTitleByCurrentRoute = (): string => {
    if (route) {
      const currentMeta = getMeta();
      if (currentMeta.navInfo && currentMeta.navInfo.navGroup) {
        switch (currentMeta.navInfo.navGroup) {
          case NavGroup.perso:
            return t("global.navBar.personal");
          case NavGroup.data:
            return t("global.navBar.data");

          case NavGroup.params:
            return t("global.navBar.settings");
        }
      }
    }
  };

  const getHomeQueryByCurrentRoute = (): any => {
    if (route) {
      const currentMeta = getMeta();
      if (currentMeta.navInfo && currentMeta.navInfo.navGroup) {
        switch (currentMeta.navInfo.navGroup) {
          case NavGroup.perso:
            return { home: "perso" };
          case NavGroup.data:
            return { home: "data" };

          case NavGroup.params:
            return { home: "setting" };
        }
      }
    }
  };

  /**
   * Recursive
   * Recupere les parents de la route entre "/" et la page target
   * @param routes
   * @returns
   */
  const getParentsRoute = (routes: Route[], target: Route): Route[] => {
    let parents = [];
    if (!Array.isArray(routes)) return parents;
    routes.forEach((r) => {
      if (r.children && r.children.find((_r) => _r.name === target.name)) {
        parents.push(r);
        const parentResult = getParentsRoute(routes, r);
        if (parentResult.length) {
          parents = parents.concat(parentResult);
        }
      }
    });

    return parents;
  };

  function findParentObject(targetName, parent = null, data = baseRoutes) {
    for (const item of data) {
      if (item.name === targetName) {
        return parent;
      }

      if (item.children) {
        const result = findParentObject(targetName, item, item.children);
        if (result !== null && result.name) {
          return result;
        }
      }
    }

    return null;
  }

  /**
   * Recupere toutes les routes entre la currente et la home
   * @returns
   */
  const getRoutePath = (): PathData => {
    const pathData: PathData = {
      routes: [],
      backRoute: null,
      homeRoute: getHomeRoute(),
    };

    let searchedRoute = route;

    pathData.routes.push({
      name: searchedRoute.name,
      path: searchedRoute.path,
      meta: searchedRoute.meta,
    } as any);

    while (findParentObject(searchedRoute.name) !== null) {
      pathData.routes.push(findParentObject(searchedRoute.name));
      searchedRoute = findParentObject(searchedRoute.name);
    }

    pathData.routes.push(pathData.homeRoute);

    pathData.routes.reverse();

    if (pathData.routes.length > 1) {
      pathData.backRoute = pathData.routes[pathData.routes.length - 2];
    } else {
      pathData.backRoute = pathData.homeRoute;
    }
    return pathData;
  };

  const getRouteTitle = (route) => {
    const meta = getMetaFromRoute(route);
    if (meta) {
      if (meta.pageInfo && meta.pageInfo.i18nTitle) {
        return t(meta.pageInfo.i18nTitle);
      }
      if (meta.navInfo && meta.navInfo.i18nLabel) {
        return t(meta.navInfo.i18nLabel);
      }
    }
  };

  const getBackRoute = () => {
    const _routes = getParentsRoute(getRootRoute(), route as any);
    if (_routes && _routes.length) {
      return _routes[_routes.length - 1];
    }
    return getHomeRoute();
  };

  return {
    getRoutePath,
    getAuthorizedRoutesByGroup,
    getPageHeaderInfo,
    getMeta,
    getMetaFromRoute,
    getRootRoute,
    getParentsRoute,
    getHomeRoute,
    getHomeTitleByCurrentRoute,
    getHomeQueryByCurrentRoute,
    getBackRoute,
    getRouteTitle,
  };
}
