import {
  IProjet,
  IProjetIntervenant,
  IProjetSession,
} from "@/pages/bureau/@models/projet.model";
import { extend } from "dayjs";
import { reactive, ref, watch } from "vue";
import { IContact } from "@/pages/bureau/@models/contacts.model";
import { IFiltresBIM } from "@/pages/bureau/@models/societe.model";
import { ITache } from "@/pages/bureau/@models/taches.model";

class DefaultClass {
  public projets: { [index: string]: IProjet } = reactive({});
  public intervenants: { [index: string]: IProjetIntervenant } = reactive({});
  public participants: { [index: string]: IProjetParticipant } = reactive({});
  public contacts: { [index: string]: IContact } = reactive({});
  public filtresBIM: IFiltresBIM[] = reactive([]);
  public taches: ITache[] = reactive([]);

  public projetsSession: IProjetSession[] = reactive([]);

  constructor() {
    //
  }

  public get arrayOfProjets() {
    return Object.values(this.projets).sort((a, b) => {
      return a.nom.localeCompare(b.nom);
    });
  }

  public get arrayOfProjetsUids() {
    const uids: string[] = [];
    Object.keys(this.projets).forEach((i) => {
      uids.push(this.projets[i].uid);
    });
    return reactive(uids);
  }

  public clearProjets() {
    this.projets = reactive({});
  }

  public clearIntervenants() {
    this.intervenants = reactive({});
  }

  public clearTaches() {
    this.taches = reactive([]);
  }

  public get arrayOfIntervenants() {
    return Object.values(this.intervenants).sort((a, b) => {
      return a.nom.localeCompare(b.nom);
    });
  }

  public get arrayOfParticipants() {
    return Object.values(this.participants).sort((a, b) => {
      return a.participant.ordre - b.participant.ordre;
    });
  }
}
const ProjetsTemp = new DefaultClass();

export default reactive(ProjetsTemp);


export interface IProjetParticipant {
  participant: {
    index: number;
    ordre: number; 
    chargeProjet: boolean;
    referent: number;
    user: number;
  },
  user:{
    nom: string;
    prenom: string;
    type:number;
    hasLogo: boolean;
    logo?: string;
  }
}

export interface IProjetParticipantDetail {
  participant: IProjetParticipant["participant"];
  user: {
    nom: IProjetParticipant["user"]["nom"];
    prenom: IProjetParticipant["user"]["prenom"];
    type: IProjetParticipant["user"]["type"];
    hasLogo: IProjetParticipant["user"]["hasLogo"];
    logo?: IProjetParticipant["user"]["logo"];
    adresse: string;
    cp: string;
    departement: string;
    ville: string;
    pays: string;
    region: string;
    email: string;
    indicatif: string;
    telephone: string;
    portable: string;
  };
  pointages?: {
    tempsTotal: number;
  };
  taches?: {
    total: number;
    finies: number;
    retards: number;
  };
}