import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';

import Layout from '../layout/index.vue';


const elementChildrenRoutes = [
  {
    path: "prestations",
    name: "prestations",
    component: () => import("@/views/viewer/subview/element/ElementPrestationsTab.vue"),
  },
  {
    path: "donnees",
    name: "donnees",
    component: () => import("@/views/viewer/subview/element/ElementDonneesTab.vue"),
  },
  {
    path: "liens",
    name: "liens",
    component: () => import("@/views/viewer/subview/element/ElementLiensTab.vue"),
  }
]

const searchChildrenRoutes = [
  {
    path: "ouvrages",
    name: "search-ouvrages",
    component: () => import("@/views/viewer/subview/recherche/RechercheOuvragesTab.vue"),
  },
  {
    path: "zones",
    name: "search-zones",
    component: () => import("@/views/viewer/subview/recherche/RechercheZonesTab.vue"),
  },
  {
    path: "docs",
    name: "search-docs",
    component: () => import("@/views/viewer/subview/recherche/RechercheDocsTab.vue"),
  }
]

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: "login",
    component: () => import("@/views/viewer/Login.vue")
  },
  {
    path: "",
    component: Layout,
    redirect: {
      name: "scene"
    },
    children: [
      {
        path: '/:idFichier/:idDoc?',
        name: "scene",
        redirect: {
          name: "maquette"
        },
        children: [
          {
            path: "maquette",
            name: "maquette",
            component: () => import("@/views/viewer/subview/Maquette.vue"),
          },
          {
            path: "elements/:idIfc?/:idElement?",
            name: "element",
            redirect: {
              name: "prestations"
            },
            component: () => import("@/views/viewer/subview/element/Element.vue"),
            children: elementChildrenRoutes
          },
          {
            path: "bordereaux",
            name: "bordereaux",
            component: () => import("@/views/viewer/subview/Bordereaux.vue")
          },
          {
            path: "ouvrages/:uidOuvrage",
            name: "ouvrage",
            redirect: {
              name: 'ouvrage-articles'
            },
            component: () => import("@/views/viewer/subview/ouvrage/Ouvrage.vue"),
            children: [{
              path: "articles",
              name: "ouvrage-articles",
              component: () => import("@/views/viewer/subview/ouvrage/OuvrageArticlesTab.vue"),
            },
            {
              path: "docs",
              name: "ouvrage-documents",
              component: () => import("@/views/viewer/subview/ouvrage/OuvrageDocsTab.vue"),
            }
            ]
          },
          {
            path: "favoris",
            name: "favoris",
            component: () => import("@/views/BlankPage.vue")
          },
          {
            path: "search",
            name: "search",
            component: () => import("@/views/viewer/subview/recherche/Recherche.vue"),
            redirect: {
              name: "search-ouvrages"
            },
            children: searchChildrenRoutes
          },

        ]
      },

    ],
  },


  {
    path: "/no-access",
    name: "no-access",
    component: () => import("@/views/viewer/NoAccess.vue")
  },

  {
    path: "/not-found",
    name: "not-found",
    component: () => import("@/views/viewer/NotFound.vue")
  },


  {
    path: "/no-file",
    name: "no-file",
    component: () => import("@/views/viewer/NoFile.vue")
  },


]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
