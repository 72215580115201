
import { defineComponent, onMounted, watch } from "vue";
import {useViewer, useViewerLoader} from "@/composables/viewer";
import { useRoute, useRouter } from "vue-router";
import {
  ElementStatut,
  useViewerFiltresStore,
  useViewerStore,
} from "@/stores/viewer";
import { useAuthStore } from "@/stores";
import { storeToRefs } from "pinia";
import { watchPausable } from "@vueuse/shared";

export default defineComponent({
  name: "viewer-container",
  /**
   * On vérifie le token
   * @param to
   * @param from
   * @param next
   */
  async beforeRouteEnter(to, from, next) {
    const authStore = useAuthStore();
    if (await authStore.verifAuthentification()) {
      next();
    } else {
      next("/login?redirect=" + to.fullPath);
    }
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const viewerContext = useViewer();

    const viewerStore = useViewerStore();
    const viewerFiltresStore = useViewerFiltresStore();

    const { initialise } = useViewerLoader(
      "viewerFrame",
      route.params.idFichier,
      route.params.idDoc
    );

    const { hideOnClick, xray, reverse } = storeToRefs(viewerStore);

    const addHiddenElement = async (ifc: string) => {
      if (viewerContext.isReady) {
        const exist = viewerFiltresStore.elements[ifc];
        if (exist) {
          exist.hideOnClick = true;
          viewerFiltresStore.toggleElementVisibility(ifc);
        } else {
          const name = await viewerContext.getElementName(ifc);
          viewerFiltresStore.addElement({
            idIfc: ifc,
            name: name,
            hideOnClick: true,
            statut: ElementStatut.hidden,
          });
        }
      }
    };

    //#region Hooks & Events

    onMounted(async () => {
      // On ajoute l'evenement une fois que tout est chargé
      await initialise();
      viewerContext.bimcradle.value.subscribe("click", handleViewerClick);
      filterUpdateWatch.resume();
      filterElementsWatch.resume();

      viewerContext.isReady.value = true;
    });

    /**
     * Navigue vers la page d'element ou cache l'element si 'hideOnClick' activé
     * @param event
     */
    const handleViewerClick = (event) => {
      if (event && event.ifcGuid) {
        if (hideOnClick.value) {
          addHiddenElement(event.ifcGuid);
        } else {
          router.push({
            name: ["prestations", "donnees", "liens"].includes(
              route.name as string
            )
              ? route.name
              : "element",
            params: {
              idIfc: event.ifcGuid,
            },
          });
        }
      }
    };

    watch(
      () => xray.value,
      (newValue) => {
        if (viewerContext.bimcradle.value) {
          if (newValue) {
            viewerContext.xrayAll();
          } else {
            viewerContext.bimcradle.value.clearXRay();
          }
        }
      }
    );

    watch(
      () => reverse.value,
      () => {
        viewerContext.manageView();
      }
    );

    const filterUpdateWatch = watchPausable(
      () => [
        ...viewerFiltresStore.floors,
        ...viewerFiltresStore.layers,
        ...viewerFiltresStore.apartments,
        ...viewerFiltresStore.buildings,
        ...viewerFiltresStore.types,
      ],
      async () => {
        viewerContext.isWorking.value = true;
        await viewerFiltresStore.fetchFilteredElements(
          route.params.idFichier as string
        );
        viewerContext.isWorking.value = false;
      }
    );
    filterUpdateWatch.pause();

    const filterElementsWatch = watchPausable(
      () => [viewerFiltresStore.elements, viewerFiltresStore.disable],
      () => {
        if (!viewerFiltresStore.disable) {
          viewerContext.manageView();
        }
      },
      {
        deep: true,
      }
    );
    filterElementsWatch.pause();

    viewerFiltresStore.$subscribe(async (mutation) => {
      if (!mutation.type.includes("patch")) {
        viewerFiltresStore.storeFilters(
          (route.params.idDoc || route.params.idFichier) as string
        );
      }
    });

    //#endregion
  },
});
