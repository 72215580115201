import { ILocalisationTaches } from "@/pages/bureau/@types/localisation";

const localisationTaches: ILocalisationTaches = {
  form: {
    title: "Tâches",
    addPersonal: "Ajouter une tâche personnelle",
    addCollabo: "Ajouter une tâche collaborateur",
  },
  details: {
    title: "Détails",
    projet: "Projet",
    phase: "Phase",
    contrat: "Contrat",
    nom: "Objet",
    affiliation: "Contexte",
    avancement: "Avancement",
    description: "Description",
    commentaire: "Commentaire",
    date_echeance: "Date d'échéance",
    date_fait: "Date de réalisation",
    date_validation: "Date de validation",
    noDescription: "Aucune description",
    bdc: "Base de connaissance",
    documentBdc: "Document de la base de connaissance",
    etat: "Etat",
    valideurs: "Validateurs",
    pourInfos: "Pour information",
    fairepar: "Faire par",
  },
  documents: {
    title: "Documents",
    add: "Ajouter un document",
    download: "Télécharger",
    delete: "Supprimer",
    noDocument: "Aucun document",
  },
  edit: {
    title: "Modifier",
    personal: "Tâche Personnelle",
    collabo: "Tache Collaborateur",
    type: "Type de tâche",
    affectation: "A faire par",
  },
  new: {
    title: "Nouveau",
  },
};

export default localisationTaches;
