/**
 * Indique si le code statut correspond à un success
 * @param status code HTTP de la reponse
 * @returns 
 */
export const isValidStatus = (status: number): boolean => status >= 200 && status <= 300

export const getErrorMessage = (request: XMLHttpRequest): string => {
  if (request && request.response) {
    const res = JSON.parse(request.response);
    if (res && res.error && res.error.message) {
      return res.error.message;
    }
  }
  return "";
};

/**
 * Pour vérifier les données renvoyées par l'API (surtout pour détecter les objets vide)
 * @param data 
 * @param hasOneValid si vrai, retourne vrai au premier objet valide
 * @returns 
 */
export const checkData = (data: any, hasOneValid = false, acceptEmptyArray = true): boolean => {
    let isOk = true;
    if (data) {
        const values = Object.values<any>(data);
        isOk = !!values.length
        if (isOk) {
            for (const value of values) {
                let _isOk = false;
                if (!value) {
                    _isOk = false;
                } else if (Array.isArray(value)) {
                    if (acceptEmptyArray) {
                        _isOk = true;
                    } else {
                        _isOk = !!value.length
                    }
                } else if (typeof value == "object") {
                    _isOk = Object.keys(value).length !== 0;
                } else {
                    _isOk = true;
                }
                if (hasOneValid && _isOk) { return true; }
                else { isOk = isOk && _isOk; }
            }
        }
    }
    return isOk;
}

export const isJSON = (target: any): boolean => {
    try {
        JSON.parse(target);
    } catch (e) {
        return false;
    }
    return true;
}

export enum HttpError {
    NotFound = 400,
    UnAuthorized = 401
}