import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["title", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.imgSource)
    ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        title: _ctx.name,
        src: _ctx.imgSource
      }, null, 8, _hoisted_1))
    : _createCommentVNode("", true)
}