import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vModelDynamic as _vModelDynamic, mergeProps as _mergeProps, withDirectives as _withDirectives, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["pattern", "id", "disabled", "type"]
const _hoisted_3 = { class: "inline-flex w-full text-xs items-center" }
const _hoisted_4 = { class: "flex-grow text-left text-danger" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_icon = _resolveComponent("b-icon")!
  const _component_base_input = _resolveComponent("base-input")!

  return (_openBlock(), _createBlock(_component_base_input, {
    variant: _ctx.variant,
    block: _ctx.block,
    disabled: _ctx.disabled,
    icon: _ctx.icon,
    outlined: _ctx.outlined,
    shadow: _ctx.shadow,
    "show-focus": _ctx.showFocus,
    onPrepend: _cache[1] || (_cache[1] = ($event: any) => (_ctx.inputRef.focus())),
    onAppend: _cache[2] || (_cache[2] = ($event: any) => (_ctx.inputRef.focus()))
  }, _createSlots({
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("input", _mergeProps({
        class: "w-full py-2 px-1 flex-grow bg-inherit border-none focus:ring-0",
        ref: "inputRef",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
        pattern: _ctx.pattern,
        id: _ctx.name,
        disabled: _ctx.disabled,
        type: _ctx.type
      }, _ctx.$attrs), null, 16, _hoisted_2), [
        [_vModelDynamic, _ctx.model]
      ])
    ]),
    _: 2
  }, [
    (_ctx.label)
      ? {
          name: "header",
          fn: _withCtx(() => [
            _createElementVNode("label", { for: _ctx.name }, _toDisplayString(_ctx.label), 9, _hoisted_1)
          ]),
          key: "0"
        }
      : undefined,
    (_ctx.icon)
      ? {
          name: "append",
          fn: _withCtx(() => [
            _createVNode(_component_b_icon, {
              class: "ml-2",
              name: _ctx.icon
            }, null, 8, ["name"])
          ]),
          key: "1"
        }
      : undefined,
    (_ctx.error)
      ? {
          name: "footer",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.error), 1)
            ])
          ]),
          key: "2"
        }
      : undefined
  ]), 1032, ["variant", "block", "disabled", "icon", "outlined", "shadow", "show-focus"]))
}