
import useLayout, { LayoutEnum } from "../composables/useLayout";
import { defineComponent } from "vue";

import EmptyLayout from "./empty/EmptyLayout.vue";
import DefaultLayout from "./default/DefaultLayout.vue";

export default defineComponent({
  name: "LLayout",
  components: {
    EmptyLayout,
    DefaultLayout,
  },
  setup() {
    const { currentLayout } = useLayout();

    return {
      currentLayout,
    };
  },
});
