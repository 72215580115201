
import { defineComponent, computed } from "vue";
import { useRouter } from "vue-router";
import { BIcon } from "@/components/global";

export default defineComponent({
  setup() {
    const router = useRouter();

    const mainRoutes = computed(() =>
      router
        .getRoutes()
        .filter((r) => r.meta["main"])
        .sort(
          (r1, r2) =>
            (r1.meta["ordre"] as number) - (r2.meta["ordre"] as number)
        )
    );

    return {
      mainRoutes,
    };
  },
  components: {
    BIcon,
  },
});
