
import { useAuthStore } from "@/stores";
import { defineComponent, ref } from "vue";
import { evenementsRoutes } from "../router/modules/evenements";
import {
  consultationRoutes,
  consultationRoutesName,
} from "../router/modules/consultation";
import {
  entreprisesRoutes,
  entreprisesRoutesName,
} from "../router/modules/espace-entreprise";
import { projetsRoutes, projetsRoutesName } from "../router/modules/projets";
import { AppMain, Navbar, Sidepanel } from "./components";
import { BLoader } from "@/components/global";
import router, { BASE_ROUTE } from "../router";

const isReady = ref<boolean>(false);

/**
 * Layout principal
 * l'utilisateur doit être loggé pour utilisé le layout car il y a des routes dynamiques
 */
export default defineComponent({
  name: "app-layout",
  components: {
    AppMain,
    Navbar,
    Sidepanel,
    BLoader,
  },
  props: {
    preview: {
      type: Boolean,
      default: () => false,
    },
  },
  beforeCreate: async () => {
    const authStore = useAuthStore();

    const baseRoute = router.options.routes.find((r) => r.name == BASE_ROUTE);

    let childRoutes = baseRoute.children;

    await authStore.fetchAccess();

    if (authStore.canAccessEvenements) {
      childRoutes = childRoutes.concat(evenementsRoutes);
    }

    if (authStore.canAccessProjets) {
      childRoutes = childRoutes.concat(projetsRoutes);
    }

    if (authStore.canAccessConsultation) {
      childRoutes = childRoutes.concat(consultationRoutes);
    }

    if (authStore.canAccessEspaceEntreprise) {
      childRoutes = childRoutes.concat(entreprisesRoutes);
    }

    baseRoute.children = childRoutes;

    // TODO: trouver une autre façon d'actualiser les sous-routes de la route principal
    // (la ligne du dessus ne suffit pas)
    router.removeRoute(BASE_ROUTE);
    router.addRoute(baseRoute);

    isReady.value = true;

    // Pour les routes dynamiques, comme elles sont ajoutées juste au dessus,
    // à la première ouverture le router ne connais pas certainnes routes et leurs params et redirige au mauvaise endroit
    // donc on stocke le url dans la query redirect et on remplace l'URL
    if (router.currentRoute.value?.query?.redirect) {
      router.replace(router.currentRoute.value.query.redirect as string);
      return;
    }

    /*     if (router.currentRoute.value?.name == BASE_ROUTE) {
      if (router.hasRoute(projetsRoutesName[0])) {
        router.replace({
          name: projetsRoutesName[0],
          params: router.currentRoute.value.params,
        });
        return;
      }

      if (router.hasRoute(consultationRoutesName[0])) {
        router.replace({
          name: consultationRoutesName[0],
          params: router.currentRoute.value.params,
        });
        return;
      }

      if (router.hasRoute(entreprisesRoutesName[0])) {
        router.replace({
          name: entreprisesRoutesName[0],
          params: router.currentRoute.value.params,
        });
        return;
      }

      router.replace({
        name: "home",
        params: router.currentRoute.value.params,
      });
    } */

  },
  setup() {
    return {
      isReady,
    };
  },
});
