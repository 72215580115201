
import { defineComponent } from "vue";
import { Cancel } from "@/components/global/icons";

export default defineComponent({
  name: "b-error",
  props: {
    message: {
      type: String,
    },
  },
  components: {
    Cancel,
  },
});
