import {
  IConsultation,
  IConsultationAO,
  IConsultationArchi,
  IConsultationChargeProjet,
  IConsultationFichierCao,
  IConsultationMoa,
  IConsultationParameters,
  IConsultationProjet,
  IConsultationSociete,
  StatutType,
} from "@/@models/consultations";
import { consultationsService } from "@/api/consultations";
import { checkData, isValidStatus } from "@/utils";
import { defineStore } from "pinia";
import { useToast } from "vue-toastification";

const toastification = useToast();

/**
 * Type authentification: Intervenant
 */
export const useConsultationStore = defineStore("consultation", {
  state: () => {
    return {
      consultation: null as IConsultation,
      error: null,

      // PARSED CONSULTATION
      uid: "",
      nom: "",
      dateDebut: "",
      dateFin: "",
      dateFinNego: "",
      statut: StatutType.aucun,
      procedure: "",

      tva: [] as number[],
      estVisite: false,
      viewerIfc: false,

      ao: null as IConsultationAO,
      projet: null as IConsultationProjet,
      moa: null as IConsultationMoa,
      architecte: null as IConsultationArchi,
      chargeProjet: null as IConsultationChargeProjet,
      societe: null as IConsultationSociete,
      fichierCao: null as IConsultationFichierCao,

      params: null as IConsultationParameters,

      confidentialite: 0,
    };
  },
  getters: {
    /**
     * Indique si l'entreprise a le droit de modifié ses saisies
     * @returns
     */
    canEdit(state): boolean {
      return (
        state.consultation?.statut == StatutType.enCours ||
        state.consultation?.statut == StatutType.nego
      );
    },

    parameters(state): IConsultationParameters {
      return state.consultation?.params;
    },
  },
  actions: {
    /**
     * Recuperes une consultation
     * @returns
     */
    async fetchConsultation(payload: string) {
      const { status, data } = await consultationsService.getConsultation(
        payload
      ); 
      if (!isValidStatus(status) || !checkData(data)) {
        this.consultation = null;
        toastification.error("La récupération de la consultation a échoué.");
        return false;
      }
      this.consultation = data.consultation;
      return true;
    },
    /**
     * Recuperes une consultation
     * @returns
     */
    async setVisite(estVisite = true) {
      if (this.consultation) {
        const { status } = await consultationsService.setConsultationVisite(
          this.consultation.uid,
          estVisite
        );
        if (!isValidStatus(status)) {
          this.consultation.estVisite = false;
          return false;
        }
        this.consultation.estVisite = true;
        return true;
      }
      return false;
    },

    clear() {
      this.uid = "";
      this.nom = "";
      this.dateDebut = "";
      this.dateFin = "";
      this.dateFinNego = "";
      this.statut = 0;
      this.procedure = "";
      this.confidentialite = 0;

      this.tva = [] as number[];
      this.estVisite = false;
      this.viewerIfc = false;

      this.ao = null as IConsultationAO;
      this.projet = null as IConsultationProjet;
      this.moa = null as IConsultationMoa;
      this.architecte = null as IConsultationArchi;
      this.chargeProjet = null as IConsultationChargeProjet;
      this.societe = null as IConsultationSociete;
      this.fichierCao = null as IConsultationFichierCao;

      this.params = null as IConsultationParameters;
    },
  },
});
