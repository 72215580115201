
import { computed, defineComponent } from "vue";

import AccountPopover from "@/pages/ao/components/AccountPopover.vue";
import { useAOStore } from "@/pages/ao/store/ao";
import { storeToRefs } from "pinia";

import { Consultation, Group, Notification } from "@/components/global/icons";
import BIconMenu from "@/components/global/icons/BIconMenu.vue";
import { useAuthStore, useSocietesStore } from "@/stores";
import { usePreviewStore } from "@/stores/preview";
import { useRoute, useRouter } from "vue-router";
import { consultationRoutesName } from "@/pages/ao/router/modules/consultation";
import {
  projetsRoutes,
  projetsRoutesName,
} from "@/pages/ao/router/modules/projets";
import { entreprisesRoutesName } from "@/pages/ao/router/modules/espace-entreprise";
import { evenementsRoutesName } from "@/pages/ao/router/modules/evenements";

export default defineComponent({
  name: "app-navbar",
  props: {
    preview: {
      type: Boolean,
      default: () => false,
    },
  },
  setup() {
    const societesStore = useSocietesStore();
    const previewStore = usePreviewStore();
    const authStore = useAuthStore();

    const route = useRoute();
    const router = useRouter();

    const storeAO = useAOStore();

    const { darkMode } = storeToRefs(storeAO);

    const { drawer } = storeToRefs(storeAO);
    const { logo, logoModeClair, logoModeSombre } = storeToRefs(societesStore);

    const usedLogo = computed(() => {
      if (logoModeClair?.value || logoModeSombre?.value) {
        return darkMode.value ? logoModeSombre.value : logoModeClair.value;
      } else {
        return logo.value;
      }
    });
    const {
      canAccessConsultation,
      canAccessEspaceEntreprise,
      canAccessProjets,
      canAccessEvenements,
    } = storeToRefs(authStore);

    const isEvenementsRoute = computed(() =>
      evenementsRoutesName.includes(route.name as string)
    );
    const isConsultationRoute = computed(() =>
      consultationRoutesName.includes(route.name as string)
    );
    const isProjetRoute = computed(() =>
      projetsRoutesName.includes(route.name as string)
    );
    const isEntrepriseRoute = computed(() =>
      entreprisesRoutesName.includes(route.name as string)
    );

    const { toggleDrawer } = storeAO;

    const currentModule = computed(() => previewStore.currentModule);
    const goTo = (routeName) => {
      router.push({
        name: routeName,
      });
    };

    return { 
      usedLogo,

      canAccessConsultation,
      canAccessEspaceEntreprise,
      canAccessProjets,
      canAccessEvenements,

      isEvenementsRoute,
      isConsultationRoute,
      isProjetRoute,
      isEntrepriseRoute,

      drawer,
      toggleDrawer,
      goTo,

      // Preview
      currentModule,
    };
  },
  components: {
    AccountPopover,
    BIconMenu,
    Consultation,
    Group,
    Notification,
  },
});
