import { vModelCheckbox as _vModelCheckbox, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "relative" }
const _hoisted_3 = ["id"]
const _hoisted_4 = { class: "ml-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseInput = _resolveComponent("BaseInput")!

  return (_openBlock(), _createBlock(_component_BaseInput, { "class-input": "border-transparent" }, {
    default: _withCtx(() => [
      _createElementVNode("label", {
        for: _ctx.uuid,
        class: "flex cursor-pointer items-center"
      }, [
        _createElementVNode("div", _hoisted_2, [
          _withDirectives(_createElementVNode("input", _mergeProps(_ctx.$attrs, {
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
            type: "checkbox",
            id: _ctx.uuid,
            class: "sr-only"
          }), null, 16, _hoisted_3), [
            [_vModelCheckbox, _ctx.model]
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["block h-6 w-11 rounded-full transition-all", _ctx.model ? 'bg-primary' : 'bg-dark']),
            style: _normalizeStyle(_ctx.forceColor ? 'background-color: ' + _ctx.forceColor : '')
          }, null, 6),
          _createElementVNode("div", {
            class: _normalizeClass(["absolute left-1.5 top-1 h-4 w-4 rounded-full bg-element transition-all dark:bg-light", { 'translate-x-full': _ctx.model }])
          }, null, 2)
        ]),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.label), 1)
      ], 8, _hoisted_1)
    ]),
    _: 1
  }))
}