import { IFichierCaoStorie } from '@/@models/fichier-cao';
import { defineStore } from 'pinia'
import { useFichiersCaoStore } from './viewer-fichiers-cao';

export const useMaquetteStore = defineStore('maquette', {
    state: () => {
        return {
            selectedFloor: null,

            /**
             * ID CAO de la zone sélecitonné
             */
            selectedZone: null,
            error: null
        }
    },
    getters: {
        getNiveauByIndex() {
            return (indexEtage): IFichierCaoStorie => this.niveaux.find(n => n.Index == indexEtage)
        },
        niveaux(state): IFichierCaoStorie[] {
            const fichierCaoStore = useFichiersCaoStore();
            if (fichierCaoStore.niveau && fichierCaoStore.niveau.Stories) {
                const stories = fichierCaoStore.niveau.Stories.filter((s: IFichierCaoStorie) => s.vtSVG);
                stories.forEach((n) => {
                    if (state.selectedFloor) {
                        n.isSelected = n.Index == state.selectedFloor
                    } else {
                        n.isSelected = false;
                    }
                });
                return stories.sort((x, y) => x.Level - y.Level)
            } else {
                return []
            }
        },
        getZoneByIndex() {
            return (id) => {
                for (const niveau of this.niveaux) {
                    const zone = niveau.vkPiece && niveau.vkPiece.find(p => p.vtID == id);
                    if (zone) {
                        return zone
                    }
                }
            }
        }
    },
    actions: {

    }
})
