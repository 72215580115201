import { useViewerSectionPlugin } from '@/composables/viewer';
import { defineStore } from 'pinia'

interface IStoreViewerSections {
    sections: {
        id: string,
        meta?: any,
        pos?: any,
        dir?: any,
        active?: boolean,
    }[]
}

/**
 * Pour le stockage des sections
 */
export const useViewerSectionsStore = defineStore('viewer-sections', {
    state: (): IStoreViewerSections => {
        return {
            sections: [],
        }
    },
    actions: {

        fetchSectionsFromLocalStorage(key: string) {
            this.sections = [];
            const sections = localStorage.getItem(`viewer-${key}-sections`)

            if (sections) {
                for (const section of JSON.parse(sections)) {
                    this.sections.push({
                        id: section.id,
                        meta: section.meta,
                        pos: section.pos,
                        dir: section.dir,
                        active: section.active
                    })
                }
            }
        },


        fetchSectionsFromPlugin() {
            this.sections = [];
            const { getSections } = useViewerSectionPlugin();

            for (const section of getSections()) {
                this.sections.push({
                    id: section.id,
                    meta: section.meta,
                    pos: section.pos,
                    dir: section.dir,
                    active: section.active
                })
            }
        },

        storeSections(key: string) {
            localStorage.setItem(`viewer-${key}-sections`, JSON.stringify(this.sections))
        }
    }
})
