
import {
  defineComponent,
  computed,
  onMounted,
  ref,
  watch,
  nextTick,
} from "vue";
import { Pane } from "splitpanes";
import { usePreviewStore } from "@/stores/preview";
import {
  SimulationDevice,
  MAX_SCALE,
  MIN_SCALE,
} from "@/stores/preview/preview";
import { BOverlay, BLoader } from "@/components/global";
import Panzoom, { PanzoomOptions } from "@panzoom/panzoom";

import { isDev } from "@/utils";

const SCENE_URL =
  isDev() ? "/preview-scene" : "./preview-scene";

export default defineComponent({
  name: "app-main-panel",
  props: {
    /**
     * Taille du Pane en pourcentage
     */
    size: Number,
  },
  setup() {
    const previewStore = usePreviewStore();

    const iframePreviewEl = ref<HTMLIFrameElement>(null);
    const targetEl = ref<HTMLElement>(null);

    let wait = false;

    const isDark = computed(() => previewStore.darkMode);
    const device = computed<SimulationDevice>(() => previewStore.device);
    const width = computed(() => previewStore.previewWidth);
    const heigth = computed(() => previewStore.previewHeight);
    const loading = computed(() => previewStore.loading);

    const styleSceneComputed = computed(
      () =>
        `width: ${width.value}px !important; height: ${heigth.value}px !important; z-index: -1 !important`
    );

    onMounted(() => {
      previewStore.panzoomInstance = Panzoom(document.getElementById("scene"), {
        canvas: true,
        maxScale: MAX_SCALE,
        minScale: MIN_SCALE,
        animate: true,
      } as PanzoomOptions);
      nextTick(() => {
        watch(
          () => device.value,
          () => previewStore.previewReset(),
          {
            immediate: true,
          }
        );
      });
    });

    const onScroll = (event: WheelEvent) => {
      event.preventDefault();
      if (!wait) {
        // On regarde si l'element est scrollable

        const e = window.event || event; // old IE support
        const delta = Math.max(
          -1,
          Math.min(1, e["wheelDelta"] || -e["detail"])
        );

        if (previewStore.panzoomInstance) {
          if (delta > 0) {
            previewStore.previewZoomIn();
          } else {
            previewStore.previewZoomOut();
          }
        }
        wait = true;
        setTimeout(() => (wait = false), 150);
      }
    };

    const onLoad = () => {
      if (iframePreviewEl.value) {
        targetEl.value =
          iframePreviewEl.value.contentDocument.getElementById("scene");
      }
    };

    return {
      isDark,
      targetEl,
      styleSceneComputed,
      iframePreviewEl,
      device,
      width,
      heigth,
      loading,

      onScroll,
      onLoad,

      SimulationDevice,

      SCENE_URL,
    };
  },
  components: {
    Pane,
    BOverlay,
    BLoader,
  },
});
