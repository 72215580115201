
import { createPopper } from "@/plugins/popper";
import {
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  PropType,
  ref,
  watch,
  computed,
} from "vue";
import { BInputSwitch, BBtn } from "@/components/global";
import { User } from "@/components/global/icons";
import { clickOutside, ClickOutsideParameter } from "@/directives";
import { isNumber } from "@/utils";

type WidthType = number | "auto" | "relative" | "full";

export default defineComponent({
  name: "b-dropdown",
  props: {
    placement: {
      type: String as PropType<
        | "top"
        | "bottom"
        | "right"
        | "left"
        | "auto"
        | "auto-start"
        | "auto-end"
        | "top-start"
        | "top-end"
        | "bottom-start"
        | "bottom-end"
        | "right-start"
        | "right-end"
        | "left-start"
        | "left-end"
      >,
      default: () => "bottom",
    },
    stopPropagation: {
      type: Boolean,
      default: false,
    },
    offset: {
      type: Array as PropType<number[]>,
    },
    triggerEl: {
      type: Object,
      required: false,
    },
    triggerId: String,
    closeOnClick: Boolean,
    arrow: Boolean,
    disabled: Boolean,
    width: {
      default: () => "auto",
    },
  },
  directives: {
    clickOutside,
  },
  emits: ["show", "hide"],
  setup(props, { emit }) {
    const popperInstance = ref();

    const open = ref(false);

    const widthStyle = ref<string>("");

    onMounted(() => {
      if (props.triggerId) {
        const el = document.getElementById(props.triggerId);
        if (el) {
          el.addEventListener("click", toggle);
        }
      }
    });

    onUnmounted(() => {
      removeEventListener("keydown", onKeyEscDown);
      if (props.triggerId) {
        const el = document.getElementById(props.triggerId);
        if (el) {
          el.removeEventListener("click", toggle);
        }
      }
    });

    const clickOutsideParams = computed<ClickOutsideParameter>(() => {
      return {
        props: {
          open: open.value,
          triggerId: props.triggerId,
          triggerEl: props.triggerEl,
        },
        handler: hide,
        events: ["click"],
        detectIFrame: true,
      };
    });

    const calcWidthStyle = () => {
      const width = props.width as WidthType;
      if (isNumber(width)) {
        return `width: ${width}px`;
      } else {
        switch (width) {
          case "auto":
            return "";

          case "full":
            return "width: 100%;";

          case "relative":
            return defaultTriggerEl.value
              ? `width: ${Math.round(defaultTriggerEl.value.clientWidth)}px;`
              : "";

          default:
            return "";
        }
      }
    };

    const defaultTriggerEl = ref();
    const targetEl = ref();
    const arrowEl = ref();

    const show = () => {
      if (!props.disabled) {
        open.value = true;
      }
    };

    const hide = () => {
      if (!props.disabled) {
        open.value = false;
      }
    };

    const toggle = () => {
      if (!props.disabled) {
        open.value = !open.value;
      }
    };

    const getTriggerEl = () => {
      if (props.triggerId) {
        return document.getElementById(props.triggerId);
      } else if (props.triggerEl) {
        return props.triggerEl;
      } else {
        return defaultTriggerEl.value;
      }
    };

    const getPopperPlacement = () => {
      if (popperInstance.value) {
        return popperInstance.value.state.placement;
      }
    };

    watch(
      () => open.value,
      (newValue) => {
        if (newValue) {
          widthStyle.value = calcWidthStyle();
          nextTick(() => {
            popperInstance.value = createPopper(
              getTriggerEl(),
              targetEl.value,
              {
                placement: props.placement,
                strategy: "fixed",
                modifiers: [
                  {
                    name: "offset",
                    enabled: true,
                    options: {
                      offset: (() => {
                        if (props.offset) {
                          return props.offset;
                        }
                        if (props.arrow) {
                          return [0, 8];
                        }
                        return [];
                      })(),
                    },
                  },
                  {
                    name: "arrow",
                    enabled: props.arrow,
                    options: {
                      element: arrowEl.value,
                    },
                  },
                ],
              }
            );

            emit("show");
          });
          addEventListener("keydown", onKeyEscDown);
        } else if (popperInstance.value) {
          popperInstance.value.destroy();
          emit("hide");
          removeEventListener("keydown", onKeyEscDown);
          //setTimeout(() => {

          //}, 120);
        }
      }
    );

    const onKeyEscDown = (event: KeyboardEvent) => {
      if (event && event.key == "Escape") {
        hide();
      }
    };

    /*     onClickOutside(defaultTriggerEl, () => hide(), {
    }); */

    return {
      open,
      clickOutsideParams,

      defaultTriggerEl,
      targetEl,
      arrowEl,

      widthStyle,

      getPopperPlacement,

      show,
      hide,
      toggle,
    };
  },
  components: {
    BInputSwitch,
    BBtn,
    User,
  },
});
