import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "prose flex flex-col flex-auto min-w-full min-h-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_loader = _resolveComponent("b-loader")!
  const _component_b_overlay = _resolveComponent("b-overlay")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default"),
    _createVNode(_component_b_overlay, {
      class: "cursor-wait select-none",
      show: _ctx.loading
    }, {
      default: _withCtx(() => [
        _createVNode(_component_b_loader, {
          message: _ctx.$t('global.loading.default')
        }, null, 8, ["message"])
      ]),
      _: 1
    }, 8, ["show"])
  ]))
}