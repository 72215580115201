import { IResponse } from "@/@types/api";
import { isValidStatus } from "@/utils/http";
import { useAuthStore } from "@/stores/auth";
import axios from "axios"; 
const axiosInstance = axios.create({
  baseURL:
    process.env.NODE_ENV == "production"
      ? `${window.location.href.split("/web/")[0]}/API/V3/`
      : process.env.VUE_APP_BIMOFFICE_API_URL,
  headers: {
    "x-api-key": process.env.VUE_APP_BOFFICE_API as string,
  },
});

// Config global des Headers
axiosInstance.interceptors.request.use(
  async (config) => {
    if (!config.headers) {
      config.headers.set("x-api-key", process.env.VUE_APP_BOFFICE_API);
      config.headers.set("Authorization", "");
      config.headers.set("IdentifiantMachine", "");
    }

    // Authentification
    const authStore = useAuthStore();
    if (authStore.token) {
      config.headers.set("Authorization", authStore.token);
      config.headers.set("IdentifiantMachine", "1");
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Config global des réponses
axiosInstance.interceptors.response.use(
  (response) => {
    const res = response.data as IResponse;
    // Gestion des requetes invalides
    if (!isValidStatus(response.status)) {
      return Promise.reject(new Error(res.message || "Error"));
    }
    // Gestion des requete valides
    else {
      return res as any;
    }
  },
  (error) => {
    // Si erreur d'authentifaction, on reset le token et on redirige vers la page Login
    if (error?.request?.status == 401) {
      //location.reload();
    }

    //return Promise.reject(error)
    return error;
  }
);

export default axiosInstance;
