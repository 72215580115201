import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_Pane = _resolveComponent("Pane")!

  return (_openBlock(), _createBlock(_component_Pane, {
    "aria-label": "Sidebar",
    size: _ctx.size,
    class: "scrollbar-thin flex flex-col overflow-y-auto bg-menu md:min-w-80 border-l border-gray-300"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_RouterView, { name: "options" })
    ]),
    _: 1
  }, 8, ["size"]))
}