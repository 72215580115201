export const PieTypeArray = [
  "questions",
  "decisions",
  "consultations",
  "annonces",
];

export const PieType: { [k: string]: string } = {
  q: PieTypeArray[0],
  d: PieTypeArray[1],
  c: PieTypeArray[2],
  a: PieTypeArray[3],
};

export function pieTitle(type: string) {
  switch (type) {
    case PieType.q:
      return "Réponses / Annonces";
    case PieType.d:
      return "Décisions";
    case PieType.c:
      return "Consultations";
    case PieType.a:
      return "Annonces";
    default:
      return "Unknown";
  }
}
