import { IProjetIntervenantContact } from "@/@models/projets";
import { projetsService } from "@/api";
import { checkData, isUUIDValid, isValidStatus } from "@/utils";
import { isRef, ref, watch } from "vue";
import { useToast } from "vue-toastification";

import type { ComputedRef, Ref, WatchStopHandle } from "vue";

interface UseProjetIntervenantsProps {
  uidProjet: string | Ref<string> | ComputedRef<string>;
  indexIntervenant: number | Ref<number> | ComputedRef<number>;

  /**
   * Si vrai, charge immediatement la projet et recharge à chaque changement de 'uidProjet'
   * Il faut recreer l'instance si changer apres
   */
  autoFetch?: boolean;
}

export function useProjetIntervenantContacts({
  uidProjet,
  indexIntervenant,
  autoFetch,
}: UseProjetIntervenantsProps) {
  const contacts = ref<IProjetIntervenantContact[]>([]);

  const loading = ref<boolean>(false);
  const isFetched = ref<boolean>(false);

  const fetchError = ref<Error>(null);

  let watchStop: WatchStopHandle = null;

  async function fetch() {
    const toastification = useToast();

    fetchError.value = null;

    const _uidProjet = isRef(uidProjet) ? uidProjet.value : uidProjet;
    const _indexIntervenant = isRef(indexIntervenant)
      ? indexIntervenant.value
      : indexIntervenant;

    if (!isUUIDValid(_uidProjet) || !_indexIntervenant) {
      contacts.value = [];
      isFetched.value = false;
      loading.value = false;
      console.error("Error: UUID Projet vide ou invalide !");
      return;
    }

    loading.value = true;

    try {
      const { status, data } =
        await projetsService.getProjetIntervenantContacts(
          _uidProjet,
          _indexIntervenant
        );

      if (!isValidStatus(status) || !checkData(data)) {
        contacts.value = null;
        toastification.error(
          "La récupération des contacts de l'intervenant du projet a échouée."
        );
      } else {
        contacts.value = data.contacts;
        isFetched.value = true;
      }
    } catch (err) {
      contacts.value = [];
      fetchError.value = err;
    } finally {
      loading.value = false;
    }
  }

  watch(
    () => autoFetch,
    (value) => {
      if (value) {
        if (typeof watchStop == "function") {
          watchStop();
        }
        watchStop = watch(
          [
            isRef(uidProjet) ? uidProjet : () => uidProjet,
            isRef(indexIntervenant) ? indexIntervenant : () => indexIntervenant,
          ],
          fetch,
          {
            immediate: true,
          }
        );
      } else {
        if (typeof watchStop == "function") {
          watchStop();
        }
      }
    },
    {
      immediate: true,
    }
  );

  const reset = () => {
    contacts.value = [];
    isFetched.value = false;
    loading.value = false;
    fetchError.value = null;
  }

  return {
    contacts,

    loading,

    isFetched,
    fetchError,

    fetch,
    reset
  };
}
