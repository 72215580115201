import { ILocalisationExpense } from "@/pages/bureau/@types/localisation";

const localisationExpense: ILocalisationExpense = {
  error: {
    nom: "Le libellé du frais est vide ou invalide !",
    indexCategorie: "Le type de frais est vide ou invalide !",
    montant: "Le montant est vide ou invalide !",
    nbKm: "Le montant est vide ou invalide !",
    noFrais: "Vous devez saisir au moins un frais.",
  },
  expenses: {
    title: "Frais",
    expenses: "Dépenses",
    myExpenses: "Mes dépenses",
    total: "Non attribuées",
    expensesReport: "Notes de frais",
    myExpensesReport: "Mes notes de frais",
    totalUnpay: "Non payées",
  },
  expenseDetail: {
    title: "Détail",
    name: "Libellé",
    date: "Date",
    affectation: "Affectation",
    category: "Catégorie",
    type: "Type de frais",
    typeProject: "Dépense de projet",
    typeUse: "Dépense de fonctionnement",
    expense: "Montant",
    pay: "Paiement",
    distance: "Distance",
    openReport: "Ouvrir la note de frais",
    integratedPay: "Intégré à la note de frais n°{index} (payée le {date})",
    integratedNotPay: "Intégré à la note de frais n°{index} (non payée)",
    notIntegrated: "Pas intégré",
  },
  expenseEdit: {
    title: "Modifier une dépense",
  },
  expenseForm: {
    name: "Libellé",
    statut: "Statut",
    date: "Date",
    project: "Projet",
    projectPhase: "Phase",
    category: "Catégories",
    commentaire: "Commentaire",
    type: "Type de frais",
    typeProject: "Dépense projet",
    typeNotBillable: "Non facturable",
    typeAffectation: "Type",
    typeTravel: "Déplacement",
    typeCost: "Montant",
    expense: "Montant",
    ht: "Montant de la dépense",
    tva: "Dont TVA",
    nbHour: "Nombre d'heures",
    typeHeure: "Type d'heure",
    distance: "Distance",
    document: "Document",
    dossier: "Dossier",
    documentInput: "Ajouter un document",
    documentInputButton: "Prendre une photo",
    errorMontanTotal:
      "Le montant total ne peut pas etre inférieur au montant TVA",
  },
  expenseList: {
    title: "Mes dépenses",
    periode: "Période",
    from: "Frais depuis le",
    search: "Nom du frais...",
    expenseCount: "{count} dépense(s) trouvée(s)",
    addExpenseButton: "Nouvelle dépense...",
    noExpense: "Aucun frais...",
  },
  expenseNew: {
    title: "Nouveau frais",
    add: "Ajouter la dépense",
  },
  statistique: {
    title: "Statistiques",
  },
  expenseReport: {
    title: "Note de frais",
  },
  expenseReportList: {
    title: "Notes de frais",
    search: "Rechercher",
    toPay: "A payer",
    notPay: "Non payée",
    payOn: "Payée le {date}",
    reportCount: "{count} note(s) de frais trouvée(s)",
  },
  expenseReportDetail: {
    title: "Détail",
    commentaire: "Commentaire",
    date: "Dates",
    dateCreated: "Créée le {date}",
    datePeriod: "Sur la période du {dateStart} jusqu'au {dateEnd}",
    datePay: "Payée le {date}",
    frais: "Détail du frais",
    label: "Note de frais",
    statut: "Statut",
    total: "Montant de la note (payée le {date})",
    totalNotPay: "Montant de la note (non payée)",
    totalDistance: "Distance total",
    totalMontant: "Montant total",
    noFrais: "Aucun frais",
  },
  expenseReportEdit: {
    title: "Modifier une note de frais",
    fraisList: "Liste des frais",
    selectionFrais: "Sélection de frais",
  },
  expenseReportForm: {
    dateTitle: "Dates",
    dateReport: "Date de la note",
    dateStart: "Début période",
    dateEnd: "Fin période",
    datePay: "Payée le",
    commentaire: "Commentaire",
    listFrais: "Dépenses",
    addFrais: "Ajouter un/des frais",
    updateFrais: "Modifier le/les frais",
  },
  expenseBottomSheet: {
    title: "Que voulez-vous faire ?",
    edit: "",
    open: "",
    remove: "",
    show: "",
  },
};

export default localisationExpense;
