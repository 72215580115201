import { FichierCaoPrefs, IFichierCao, IFichierCaoStorie, ILigneCao, ILotsCao } from '@/@models/fichier-cao';
import { bimService } from '@/api';
import { checkData, HttpError, isValidStatus } from '@/utils/http';
import { defineStore } from 'pinia'

export const useFichiersCaoStore = defineStore('fichiers-cao', {
    state: () => {
        return {
            uidMaquette: null as string,

            fichierCao: null as IFichierCao,
            fichierXkt: null,
            fichierJson: null,
            error: null,

            zones: [] as ILigneCao[],
            lots: [] as ILotsCao[],
            calques: [] as string[],
        }
    },
    getters: {
        nomFicher: (state) => state.fichierCao ? state.fichierCao.nom : "",
        niveau: (state) => state.fichierCao && state.fichierCao.niveau,
        stories: (state): IFichierCaoStorie[] => state.fichierCao && state.fichierCao.niveau && state.fichierCao.niveau.Stories,
        getNiveauIndexByPieceCao() {
            return (idCao): number => {
                let index = null;
                if (this.stories) {
                    this.stories.forEach((e) => {
                        if (e.vkPiece) {
                            e.vkPiece.forEach((p) => {
                                if (p.vtID == idCao) {

                                    //TEMPORAIRE
                                    index = e.Index
                                    //return e.Index;
                                }
                            });
                        }

                    });
                }
                return index;
            }
        },

        projet: (state) => state.fichierCao && state.fichierCao.projet,
        nomProjet: (state) => (state.fichierCao && state.fichierCao.projet) ? state.fichierCao.projet.nom : "",
        codeProjet: (state) => (state.fichierCao && state.fichierCao.projet) ? state.fichierCao.projet.code : "",

        consultation: (state) => state.fichierCao && state.fichierCao.consultation,
        nomConsultation: (state) => (state.fichierCao && state.fichierCao.consultation) ? state.fichierCao.consultation.nom : "",

        maquettes: (state) => state.fichierCao && state.fichierCao.maquettes,
        maquetteConsultation: (state) => state.fichierCao && state.fichierCao.maquettes.find(m => m.uidDoc == state.fichierCao.consultation?.uidDocProj),

        preferences: (state) => state.fichierCao && state.fichierCao.preferences,
        getPreferenceByName: (state) => {
            return (prefName: FichierCaoPrefs) => state.fichierCao && state.fichierCao.preferences && state.fichierCao.preferences.find(p => p.nom == prefName)
        },

        fichierXktUrl: (state) => state.fichierXkt && URL.createObjectURL(state.fichierXkt),
        fichierJsonUrl: (state) => state.fichierJson && URL.createObjectURL(state.fichierJson),

        getJson: async (state) => {
            if (state.fichierJson && state.fichierJson instanceof Blob) {
                const data = await state.fichierJson.text();
                if (data) {
                    return JSON.parse(data)
                }
            }
        },

        getZoneIfcByIndexCao: (state) => {
            return (idCao) => state.zones.find(x => x.idCao == idCao);
        },

        getExcludeZoneIfc: (state): string[] => {
            const ifc = [];
            // Preference d'exclusion
            const prefExclude = state.fichierCao.preferences.find(p => p.nom == FichierCaoPrefs.filtreExclure)

            // La valeur est une chaine composé de 3 segment
            // premier segment: nom du parametre
            // deuxieme segment: type de filtre (1: contient, 2: commence par, 3: est, 4: est différent, 5: ne contient pas)
            // troisieme segment: valeur text
            if (prefExclude) {
                const filterValues = prefExclude.valeur.split(";");
                if (filterValues.length == 3 && filterValues[1] && filterValues[2]) {
                    const value = filterValues[2].toLowerCase();
                    switch (filterValues[1]) {
                        case "1": // contient
                            for (const zone of state.zones) {
                                const property = zone.value.vkParam.find(p => p.propriete == filterValues[0]);
                                if (typeof property?.objet?.valeur === 'string' && property.objet.valeur.toLowerCase().includes(value)) {
                                    ifc.push(zone.idIfc)
                                }
                            }
                            break;
                        case "2": // commence par
                            for (const zone of state.zones) {
                                const property = zone.value.vkParam.find(p => p.propriete == filterValues[0]);
                                if (typeof property?.objet?.valeur === 'string' && property.objet.valeur.toLowerCase().startsWith(value)) {
                                    ifc.push(zone.idIfc)
                                }
                            }
                            break;
                        case "3": // est egale
                            for (const zone of state.zones) {
                                const property = zone.value.vkParam.find(p => p.propriete == filterValues[0]);
                                if (typeof property?.objet?.valeur === 'string' && property.objet.valeur.toLowerCase() == value) {
                                    ifc.push(zone.idIfc)
                                }
                            }
                            break;
                        case "4": // est différent
                            for (const zone of state.zones) {
                                const property = zone.value.vkParam.find(p => p.propriete == filterValues[0]);
                                if (typeof property?.objet?.valeur === 'string' && property.objet.valeur.toLowerCase() != value) {
                                    ifc.push(zone.idIfc)
                                }
                            }
                            break;
                        case "5": // ne contient pas
                            for (const zone of state.zones) {
                                const property = zone.value.vkParam.find(p => p.propriete == filterValues[0]);
                                if (typeof property?.objet?.valeur === 'string' && !property.objet.valeur.toLowerCase().includes(value)) {
                                    ifc.push(zone.idIfc)
                                }
                            }
                            break;
                    }
                }
            }
            return ifc;
        }
    },
    actions: {
        /**
         * Recuperes le fichier CAO
         * @returns
         */
        async fetchFichierCao(uidFichier): Promise<true | HttpError> {
            this.error = null;
            const { status, data } = await bimService.getFichierCao(uidFichier);
            if (!isValidStatus(status) || !data || !data.fichierCao) {
                this.clear();
                this.error = Error("La récupération du fichier CAO a échoué.");
                return status;
            }
            this.fichierCao = data.fichierCao;
            return true;
        },
        /**
        * Recuperes un fichier Xkt
        * @returns
        */
        async fetchFichierXkt(uidFichier?, uidDoc?): Promise<boolean> {
            this.error = null;
            let data = null;

            const _maquette = uidDoc ?
                this.maquettes.find(m => m.uidDoc == uidDoc) :
                this.maquetteConsultation ? this.maquetteConsultation : this.maquettes[0]

            const _uidFichier = uidFichier ?
                uidFichier : this.fichierCao.uid

            if (_maquette) {
                this.uidMaquette = _maquette.uid
                data = await bimService.downloadXkt(_uidFichier, _maquette.uid);
            } else {
                return false;
            }
            if (!data || !(data instanceof Blob)) {
                this.fichierXkt = null;
                this.error = Error("La récupération du fichier XKT a échoué.");
                return false
            }
            this.fichierXkt = data;
            return true;
        },
        /**
        * Recuperes un fichier JSON
        * @returns
        */
        async fetchFichierJson(uidFichier?, uidDoc?): Promise<boolean> {
            this.error = null;
            let data = null;

            const _maquette = uidDoc ?
                this.maquettes.find(m => m.uidDoc == uidDoc) :
                this.maquetteConsultation ? this.maquetteConsultation : this.maquettes[0]

            const _uidFichier = uidFichier ?
                uidFichier : this.fichierCao.uid
            if (_maquette) {
                this.uidMaquette = _maquette.uid
                data = await bimService.downloadJson(_uidFichier, _maquette.uid);
            }
            if (!data) {
                this.fichierJson = null;
                this.error = Error("La récupération du fichier jSON a échoué.");
                return false;
            }
            this.fichierJson = data;
            return true;
        },

        async fetchCalques(uidFichier?) {
            const _uidFichier = uidFichier ?
                uidFichier : this.fichierCao.uid


            const { status, data } = await bimService.getCalques(_uidFichier);
            if (!isValidStatus(status) || !checkData(data)) {
                this.calques = []
                this.error = Error("La récupération des calques a échoué.");
                return status;
            }
            this.calques = data.calques.sort((a, b) => a.localeCompare(b));
            return true;
        },

        async fetchZonesIfc(uidFichier?) {
            const _uidFichier = uidFichier ?
                uidFichier : this.fichierCao.uid


            const { status, data } = await bimService.getAllZones(_uidFichier);
            if (!isValidStatus(status) || !checkData(data)) {
                this.zones = [];
                this.error = Error("La récupération des zones a échoué.");
                return status;
            }
            this.zones = data.zones;
            return true;
        },
        /**
        * Recuperes le fichier CAO
        * @returns
        */
        async fetchLots(uidFichier) {
            this.error = null;
            const { status, data } = await bimService.getLots(uidFichier);
            if (!isValidStatus(status) || !checkData(data)) {
                this.lots = [];
                this.error = Error("La récupération des lots a échoué.");
                return false;
            }
            this.lots = data.lots;
            return true;
        },
        clear() {
            this.error = null;
            this.fichierXkt = null;
            this.fichierJson = null;
            this.fichierCao = null;

            this.zones = [];
        }
    }
})
