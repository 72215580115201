import { renderSlot as _renderSlot, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.isMounted)
    ? (_openBlock(), _createBlock(_Teleport, {
        key: 0,
        to: _ctx.to,
        disabled: _ctx.disabled
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 8, ["to", "disabled"]))
    : _createCommentVNode("", true)
}