
import {
  computed,
  defineComponent,
  PropType,
  ref,
  WritableComputedRef,
} from "vue";
import BaseInput from "./BaseInput.vue";
import BIconCancel from "../icons/BIconCancel.vue";
import { generateUUID } from "@/utils";
import { IconType, ShadowType, VariantType } from "../index";

export default defineComponent({
  name: "b-input-select",
  components: {
    BaseInput,
    BIconCancel,
  },
  props: {
    modelValue: [Object, String, Number, Boolean],
    source: {
      type: Array as PropType<any[]>,
      default: () => [],
    },

    /**
     * Indique quel champ de la source utilisé pour la valeur
     * Si vide, alors on bind l'objet directement
     */
    itemValueKey: String,
    forceLabelColor: String,
    /**
     * Indique quel champ de la source utilisé pour le label
     * Si vide, alors on bind l'objet directement
     */
    itemTextKey: String,
    label: String,

    variant: {
      type: String as PropType<VariantType>,
      default: () => "primary",
    },
    type: {
      type: String as PropType<
        "text" | "email" | "search" | "tel" | "url" | "password"
      >,
      default: () => "text",
    },
    shadow: {
      type: String as PropType<ShadowType>,
      default: () => "none",
    },
    outlined: {
      type: Boolean,
      default: () => true,
    },
    showFocus: {
      type: Boolean,
      default: () => true,
    },
    icon: String as PropType<IconType>,
    disabled: Boolean,
    clearable: Boolean,
    block: Boolean,
  },
  emits: ["update:modelValue", "change"],
  setup(props, { attrs, emit }) {
    const inputRef = ref();
    const uuid = generateUUID();
    const name = computed<string>(() => (attrs.name as string) ?? uuid);

    const model: WritableComputedRef<any> = computed({
      get() {
        return props.modelValue;
      },
      set(newValue): void {
        emit("update:modelValue", newValue);
      },
    });

    const clearValue = () => {
      model.value = null;
    };

    return {
      model,
      inputRef,
      name,

      clearValue,
    };
  },
});
