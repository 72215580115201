
import { defineComponent, ref } from "vue";
import {
  useFichiersCaoStore,
  useViewerFiltresStore,
  useViewerStore,
} from "@/stores/viewer";
import { MenuClose, InfoCircle } from "@/components/global/icons";
import { useViewer } from "@/composables/viewer";
import { BInputSwitch, BBtn, BDropdown, BTooltip } from "@/components/global";
import { storeToRefs } from "pinia";
import ModalViewerConfirm from "@/components/modal/ModalViewerConfirm.vue";

export default defineComponent({
  setup() {
    const confirmModalEl = ref(null);

    const { isReady, getBcf, getBcfImage, loadBcf } = useViewer();

    const filtersStore = useViewerFiltresStore();
    const viewerStore = useViewerStore();

    const { clearFilter } = filtersStore;

    const { dark } = storeToRefs(viewerStore);

    const onClearFilterClick = () => {
      if (isReady.value) {
        clearFilter();
      }
    };

    const onReloadClick = async () => {
      if (
        (await confirmModalEl.value?.open(
          "Êtes-vous sur de vouloir recharger le Viewer IFC ?",
          ["accept", "close"]
        )) == "accept"
      ) {
        window.location.reload();
      }
    };

    const getBCF = async () => {
      let addNavigation = false;

      if (
        (await confirmModalEl.value?.open(
          "Voulez-vous ajouter vos données de navigation au BCF ?",
          ["accept", "denied"]
        )) == "accept"
      ) {
        addNavigation = true;
      }

      const bcf = getBcf(true, addNavigation);
      if (bcf) {
        const fileLink = document.createElement("a");
        fileLink.href = `data:text/plain;charset=utf-8,${encodeURIComponent(
          JSON.stringify(bcf)
        )}`;
        fileLink.setAttribute(
          "download",
          bcf.bimoffice?.name
            ? `${bcf.bimoffice.name}.json`
            : "bcfViewpoint.json"
        );
        fileLink.click();
        fileLink.remove();
      }
    };

    const getImage = () => {
      const image = getBcfImage();
      if (image) {
        const fileLink = document.createElement("a");
        const { nomProjet, nomFicher } = useFichiersCaoStore();
        fileLink.href = image;
        fileLink.setAttribute("download", `image_${nomFicher}_${nomProjet}`);
        fileLink.click();
        fileLink.remove();
      }
    };

    const loadBcfFile = async () => {
      const fileInput = document.createElement("input");
      fileInput.multiple = false;
      fileInput.type = "file";
      fileInput.accept = "application/JSON";
      fileInput.oninput = async () => {
        try {
          if (fileInput.files[0].size > 0) {
            const jsonData = JSON.parse(await fileInput.files[0].text());
            if (jsonData?.bimoffice?.navigation) {
              let useNavigation = false;
              if (
                (await confirmModalEl.value?.open(
                  "Le fichier BCF contient des informations de navigation, voulez-vous ouvrir la page associée ?",
                  ["accept", "denied"]
                )) == "accept"
              ) {
                useNavigation = true;
              }
              await loadBcf(jsonData, useNavigation);
            } else {
              await loadBcf(jsonData, false);
            }
          }
        } catch {
          console.error("error BCF");
        } finally {
          fileInput.remove();
        }
      };
      fileInput.click();
    };

    return {
      dark,
      confirmModalEl,

      loadBcfFile,
      getBCF,
      getImage,

      onClearFilterClick,
      onReloadClick,
    };
  },
  components: {
    BBtn,
    BDropdown,
    BInputSwitch,
    BTooltip,

    ModalViewerConfirm,

    //Icons
    MenuClose,
    InfoCircle,
  },
});
