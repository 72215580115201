import {
  IConsultationBordereauDetail,
  IConsultationBordereauPrixConsult,
  IConsultationLot,
  IConsultationLotOffre,
  StatutType,
} from "@/@models/consultations";
import { consultationsService } from "@/api/consultations";
import { IconType } from "@/components/global";
import { isUUIDValid, isValidStatus } from "@/utils";
import dayjs from "dayjs";
import { defineStore } from "pinia";
import { useToast } from "vue-toastification";
import { useAuthStore } from "../../../stores/auth";
import { useConsultationStore } from "./consultation";
import { filterPrixConsult } from "./consultation-bordereaux";

const toastification = useToast();

/**
 * Type authentification: Intervenant
 */
export const useConsultationLotsStore = defineStore("consultation-lots", {
  state: () => {
    return {
      lots: [] as IConsultationLot[],
      lot: null as IConsultationLot,
      decisions,
      statuts,
    };
  },
  getters: {
    offres(state): IConsultationLotOffre[] {
      if (state.lot) {
        return state.lot.offres;
      }
      return [];
    },
    getDerniereOffre() {
      return (lot: IConsultationLot) => {
        if (lot && lot.offres && lot.offres.length > 0) {
          return lot.offres.sort((x, y) => y.ordre - x.ordre)[0];
        }
        return null;
      };
    },
    getDerniereOffreTransmise() {
      return (lot: IConsultationLot) => {
        return lot && lot.offres && getDerniereOffreTransmise(lot.offres);
      };
    },
    getStatut() {
      return (lot: IConsultationLot): Statut =>
        getStatut(lot.statut, lot.estRetenu);
    },
  },
  actions: {
    /**
     * Recupere un lot d'une consultation par son uid
     * @returns
     */
    async fetchLot(payload: string, uid_lot: string) {
      const { status, data } = await consultationsService.getConsultationLot(
        payload,
        uid_lot
      );
      if (!isValidStatus(status) || !data || !data.lot) {
        this.lots = [];
        toastification.error("La récupération du lot a échoué.");
        return false;
      }
      this.lot = data.lot;
      return true;
    },
    /**
     * Recupere les lots d'une consultation
     * @returns
     */
    async fetchLots(payload: string) {
      const { status, data } = await consultationsService.getConsultationLots(
        payload
      );
      if (!isValidStatus(status) || !data || !data.lots) {
        this.lots = [];
        toastification.error("La récupération des lots a échoué.");
        return false;
      }
      this.lots = data.lots;
      return true;
    },
    /**
     * Applique une décision pour un lot
     * @param payload
     * @param uidLot
     * @param decision
     * @returns
     */
    async changeLotDecision(
      payload: string,
      uidLot: string,
      decision: number,
      raisonDecision = "",
      raisonDecisionAutre = ""
    ) {
      if (
        raisonDecision.length > 0 &&
        !raisonDecision.split(";").map(Number).includes(4)
      ) {
        raisonDecisionAutre = "";
      }
      if (decision != 3) {
        raisonDecisionAutre = "";
        raisonDecision = "";
      }
      const { status } = await consultationsService.putLotDecision(
        payload,
        uidLot,
        decision,
        raisonDecision,
        raisonDecisionAutre
      );

      if (!isValidStatus(status)) {
        toastification.error("La modification de la décision a échoué.");
        return false;
      }
      const lot = this.lots.find((l) => l.uid == uidLot);
      if (lot) {
        lot.decision = decision;
        lot.raisonDecision = raisonDecision;
        lot.raisonDecisionAutre = raisonDecisionAutre;
      }
      toastification.success("Décision enregistrée !");
      return true;
    },
    /**
     * Applique une décision pour tous les lots
     * @param payload
     * @param decision
     * @returns
     */
    async changeLotsDecision(
      payload: string,
      decision: number,
      raisonDecision = "",
      raisonDecisionAutre = ""
    ) {
      if (
        raisonDecision.length > 0 &&
        !raisonDecision.split(";").map(Number).includes(4)
      ) {
        raisonDecisionAutre = "";
      }
      if (decision != 3) {
        raisonDecisionAutre = "";
        raisonDecision = "";
      }
      for (const lot of this.lots) {
        const { status } = await consultationsService.putLotDecision(
          payload,
          lot.uid,
          decision,
          raisonDecision,
          raisonDecisionAutre
        );
        if (!isValidStatus(status)) {
          toastification.error("La modification de la décision a échoué.");
          return false;
        }
        lot.decision = decision;
        lot.raisonDecision = raisonDecision;
        lot.raisonDecisionAutre = raisonDecisionAutre;
      }
      toastification.success("Décision enregistrée !");
      return true;
    },
    /**
     * Mets à jour les totaux de l'offre par bordereau
     */
    updateOffre(
      uid_offre: string,
      bordereauUpdated: IConsultationBordereauDetail
    ) {
      if (this.lot) {
        const offre: IConsultationLotOffre = this.lot.offres.find(
          (o) => o.uid == uid_offre
        );
        if (offre && bordereauUpdated) {
          const tempBor = offre.bordereaux.find(
            (x) => x.uid == bordereauUpdated.uid
          );
          const isOption = tempBor ? tempBor.estOption : false;
          const tva = Array.from(useConsultationStore().consultation?.tva);
          const pereVariants = [
            ...new Set(
              filterPrixConsult(bordereauUpdated.details, (p) =>
                isUUIDValid(p.articleRef)
              ).map((x) => x.articleRef)
            ),
          ];

          offre.totalHt = 0;
          offre.optionHt = 0;
          offre.complementHt = 0;

          offre.totalTtc = 0;
          offre.optionTtc = 0;
          offre.complementTtc = 0;

          // Recursive
          calcTotauxOffre(
            bordereauUpdated.details,
            offre,
            isOption,
            pereVariants,
            tva
          );

          /**
           * On ajoute les montants des autres bordereaux
           * Pour calculer, comme on a les montants globaux (ceux de l'offre) et que
           * l'on veut mettre à jour les bordereaux, on décompose l'offre
           * ex:  complément d'un bordereau = complément de l'offre - (somme des compléments des autres bordereaux)
           */
          if (offre.bordereaux) {
            let tempTotal = 0;
            let tempOption = 0;
            let tempComplement = 0;
            offre.bordereaux.forEach((bor) => {
              if (bor.uid != bordereauUpdated.uid) {
                const bor2 = offre.bordereaux.find((x) => x.uid == bor.uid);
                if (bor2) {
                  offre.complementHt += bor2.complementHt;

                  bor.complementHt = bor2.complementHt;
                  bor.valeurHt = bor2.valeurHt;

                  if (!bor.estOption) {
                    offre.totalHt += bor2.valeurHt;
                    tempTotal += bor2.valeurHt;
                  } else {
                    offre.optionHt += bor2.valeurHt;
                    tempOption += bor2.valeurHt;
                  }
                  //on ne regarde pas si c'est une option pour un complement
                  tempComplement += bor2.complementHt;
                }
              } else {
                // On actualise le bordereau en cours
                if (bor.estOption) {
                  bor.valeurHt = offre.optionHt - tempOption;
                } else {
                  bor.valeurHt = offre.totalHt - tempTotal;
                }
                bor.complementHt = offre.complementHt - tempComplement;
              }
            });
          }
        }
      }
    },

    /**
     * Transmet l'offre et envoi un mail de confirmation
     * @param uid_offre
     */
    async submitOffer(
      uid_consultation: string,
      uid_lot: string,
      uid_offre: string
    ) {
      const { status } = await consultationsService.submitOffre(
        uid_consultation,
        uid_lot,
        uid_offre
      );
      if (!isValidStatus(status)) {
        toastification.error("La transmission de l'offre a échoué.");
        return false;
      }
      const offre: IConsultationLotOffre = this.offres.find(
        (o) => o.uid == uid_offre
      );
      if (offre) {
        offre.dateTransmission = dayjs().format("YYYYMMDDHHMMSS");
      }
      toastification.success("Transmission réussie !");
      return true;
    },

    /**
     *   Envoi un mail de confirmation
     * Utilisé uniquement sur l'offre deja transmisse pour re envoyé l'accusé
     * @param uid_offre
     */
    async sendAccuseReception(
      uid_consultation: string,
      uid_lot: string,
      uid_offre: string
    ) {
      const authStore = useAuthStore();
      const { status } = await consultationsService.submitOffre(
        uid_consultation,
        uid_lot,
        uid_offre
      );
      if (!isValidStatus(status)) {
        toastification.error("L'envoi de l'email a échoué.");
        return false;
      }
      toastification.success(
        `Accusé de réception envoyé par email à ${authStore.user?.email}.`
      );
      return true;
    },

    /**
     * Enregistre les information privés de l'entreprise
     * @param uid_offre
     */
    async submitOfferInfoPrive(
      uid_consultation: string,
      uid_lot: string,
      uid_offre: string,
      payload: any
    ) {
      const { status } = await consultationsService.updateOffreInfoEntreprise(
        uid_consultation,
        uid_lot,
        uid_offre,
        payload
      );
      if (!isValidStatus(status)) {
        toastification.error(
          "L'enregistrement des informations à privés a échoué."
        );
        return false;
      }
      const offre: IConsultationLotOffre = this.offres.find(
        (o) => o.uid == uid_offre
      );
      if (offre) {
        offre.infoEntreprise = payload;
      }
      toastification.success(
        "Enregistrement des informations privées à réussie !"
      );
      return true;
    },
  },
});

const calcTotauxOffre = (
  prixConsult: IConsultationBordereauPrixConsult[],
  offre: IConsultationLotOffre,
  isOption = false,
  uuidAIgnorer: string[] = [],
  tva: number[] = []
) => {
  if (prixConsult && prixConsult.length) {
    prixConsult.forEach((ouv) => {
      if (!uuidAIgnorer.includes(ouv.uid) && ouv.estPrisEnCompte) {
        const tauxTva = tva[ouv.tauxTva - 1];

        // Si forfait, alors on recupere juste le total et on ne regarde pas ses sous-ouvrages
        if (ouv.estForfait && !ouv.estArticle) {
          if (isOption) {
            offre.optionHt += ouv.prixTotal;
            if (tauxTva !== undefined) {
              offre.optionTtc += ouv.prixTotal * (1 + tauxTva / 100);
            }
          } else {
            offre.totalHt += ouv.prixTotal;
            if (tauxTva !== undefined) {
              offre.totalTtc += ouv.prixTotal * (1 + tauxTva / 100);
            }
          }
        } else {
          if (!ouv.estElementForfait && !ouv.isDeleted) {
            const total = ouv.prixUnit * ouv.quantite;
            if (ouv.estComplement) {
              offre.complementHt += total;
              if (tauxTva !== undefined) {
                offre.complementTtc += total * (1 + tauxTva / 100);
              }
            } else {
              if (isOption) {
                offre.optionHt += total;
                if (tauxTva !== undefined) {
                  offre.optionTtc += total * (1 + tauxTva / 100);
                }
              } else {
                offre.totalHt += total;
                if (tauxTva !== undefined) {
                  offre.totalTtc += total * (1 + tauxTva / 100);
                }
              }
            }
          }
        }
      }

      calcTotauxOffre(ouv.ouvrages, offre, isOption, uuidAIgnorer, tva);
    });
  }
};

export const decisions: Decision[] = [
  {
    index: 0,
    label: "Sélectionnez votre réponse",
    description: "",
    color: "#fff",
    borderClass: "dark:border-dark",
    backgroundClass: "bg-white dark:bg-dark",
    textClass: "dark:text-white",
  },
  {
    index: 1,
    label: "Je m'engage à remettre une offre",
    description:
      "J’informe le maître d’ouvrage de ma volonté de transmettre une offre dans les délais mentionnés.Je ne pourrais pas clôturer ma réponse sur ce projet sans avoir transmis une offre financière ainsi que les éléments de ma candidature.",
    color: "#5ebc7f",
    borderClass: "border-[color:#5ebc7f]",
    backgroundClass: "bg-[color:#f3faf0] dark:bg-inherit",
    textClass: "text-[color:#5ebc7f]",
  },
  {
    index: 2,
    label: "Je suis intéressé",
    description:
      "J'ai l'intention de proposer une offre pour ce lot mais je ne suis pas sûr de pouvoir le faire dans les délais impartis.",
    color: "#f75f00",
    borderClass: "border-[color:#f75f00]",
    backgroundClass: "bg-[color:#fff6eb] dark:bg-inherit",
    textClass: "text-[color:#f75f00]",
  },
  {
    index: 3,
    label: "Je ne suis pas intéressé",
    description: "Je ne répondrais pas sur ce lot.",
    color: "#63687a",
    borderClass: "border-[color:#63687a]",
    backgroundClass: "bg-[color:#ebebeb] dark:bg-inherit",
    textClass: "text-[color:#63687a]",
  },
];

export const statuts: Statut[] = [
  {
    index: 0,
    label: "En cours",
    description: "Le lot n’est pas encore attribué.",
    guide:
      "La collecte des offres est en cours, vous pouvez transmettre vos offres jusque'à la date limite de remise.",
    icon: "hourglass",
    backgroundClass: "bg-[color:#ebebeb] dark:bg-opacity-80",
    textClass: "text-[color:#63687a]",
  },
  // Statut alternatif
  {
    index: 10,
    label: "Négociation",
    description: "Le lot n’est pas encore attribué.",
    guide:
      "La négociation est en cours : le maître d’oeuvre contacte directement les entreprises pour affiner les offres avant de prendre sa décision",
    icon: "negociation",
    backgroundClass: "bg-[color:#ebebeb] dark:bg-opacity-80",
    textClass: "text-[color:#63687a]",
  },
  {
    index: 1,
    label: "Remporté",
    description:
      "Félicitations : ce lot vous a été attribué, veillez à avoir finalisé votre dossier de candidature.",
    guide:
      "Félicitations : ce lot vous a été attribué, veillez à avoir finalisé votre dossier de candidature.",
    icon: "done",
    backgroundClass: "bg-[color:#f3faf0] dark:bg-[color:#5ebc7f]",
    textClass: "text-[color:#5ebc7f] dark:text-white",
  },
  {
    index: 2,
    label: "Perdu",
    description:
      "Désolé ce lot ne vous a pas été attribué. nous vous remercions de votre participation et ne manquerons pas de vous consulter pour des projets ultérieurs",
    guide:
      "Désolé ce lot ne vous a pas été attribué. nous vous remercions de votre participation et ne manquerons pas de vous consulter pour des projets ultérieurs",
    icon: "close",
    backgroundClass: "bg-[color:#FFE3E3] dark:bg-[color:#FF5555]",
    textClass: "text-[color:#FF5555] dark:text-white",
  },
  {
    index: 3,
    label: "Infructueux",
    description:
      "Le maître d’ouvrage a décidé de relancer une nouvelle consultation sur ce lot après des aménagements techniques d’ici quelques jours. Merci d’indiquer si vous souhaitez participer à cette nouvelle consultation",
    guide:
      "Le maître d’ouvrage a décidé de relancer une nouvelle consultation sur ce lot après des aménagements techniques d’ici quelques jours. Merci d’indiquer si vous souhaitez participer à cette nouvelle consultation",
    icon: "cancel",
    backgroundClass: "bg-[color:#fff6eb] dark:bg-[color:#f75f00]",
    textClass: "text-[color:#f75f00] dark:text-white",
  },
];

type Decision = {
  index: number;
  label: string;
  description: string;
  color: string;
  borderClass: string;
  backgroundClass: string;
  textClass: string;
};

type Statut = {
  index: number;
  label: string;
  description: string;
  guide: string;
  icon: IconType;
  backgroundClass: string;
  textClass: string;
};

export function getStatut(
  statutLot: number,
  estRetenu: boolean,
  statutConsultation: StatutType = StatutType.enCours
): Statut {
  /*
  statut[0]=en cours     index=0
  statut[1]=nego         index=10
  statut[2]=remporté     index=1
  statut[3]=perdu        index=2
  statut[4]=infructueux  index=3
  */
  switch (statutLot) {
    case 0: {
      if (statutConsultation == StatutType.nego) {
        return statuts[1]; //negociation
      } else {
        return statuts[0]; //en cours
      }
    }

    case 1:
      if (estRetenu) {
        return statuts[2]; //remporté par l'entreprise
      } else {
        return statuts[3]; //perdu / remporté par une autre entreprise
      }
    case 2:
      return statuts[4]; //infructueux / annulé ou relancé
  }
  return statuts[0]; //en cours par defaut
}

export function getDerniereOffreTransmise(
  offres: IConsultationLotOffre[]
): IConsultationLotOffre {
  if (offres && offres.length > 0) {
    return offres.sort((x, y) =>
      y.dateTransmission.localeCompare(x.dateTransmission)
    )[0];
  }
  return null;
}
