
import { defineComponent } from "vue";
import { Pane } from "splitpanes";
import HeaderbarViewerToolbar from "@/components/headerbar/HeaderbarViewerToolbar.vue";
import Viewer from "@/views/viewer/Viewer.vue";

export default defineComponent({
  name: "app-main-panel",
  props: {
    /**
     * Taille du Pane en pourcentage
     */
    size: Number,
  },
  components: {
    Pane,
    HeaderbarViewerToolbar,
    Viewer,
  },
});
