import { CONTEXT_DATA, IPageContext } from "@/@models/preview";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import { defineStore } from "pinia";
import { PanzoomObject } from "@panzoom/panzoom";
import { useRoute } from "vue-router";

const STORAGE_KEY = "bimoffice_preview_login_ao_prefs";

const METHODE_4D_GET_CONTEXT = "ao_previsWeb_GetContext";
const METHODE_4D_SAVE_CONTEXT = "ao_previsWeb_saveContext";

const STORED_PROPERIES = ["darkMode", "device"];

export enum SimulationDevice {
  desktop,
  laptop,
  mobile,
}

enum Layout {
  default = "default",
}

export enum PreviewPage {
  login = "login",
}

export const MAX_SCALE = 2;
export const MIN_SCALE = 0.1;

/**
 * Contient les prefs global pour la partie appel d'offre
 */
export const usePreviewStore = defineStore("preview-ao-login", {
  state: () => {
    let data = null;
    const stored = localStorage.getItem(STORAGE_KEY);

    if (stored) {
      data = JSON.parse(stored);
    }
    // Si aucune prefs enregistrée, on récupere le média pour le darkmode
    else if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      data = {
        darkMode: true,
      };
    }

    const breakpoints = useBreakpoints(breakpointsTailwind);

    return {
      darkMode: data?.darkMode ?? false,

      sidePanelSize: breakpoints.isSmaller("md") ? 0 : 30,

      device: data?.device ?? (SimulationDevice.desktop as SimulationDevice),

      loading: false,

      layout: "default" as Layout,
      preview: PreviewPage.login as PreviewPage,

      slogan: "",
      image: null as File,
      logo: null as File, 
      color: "#FFFFFF",
      instruction: "",

      transitionSignal: true,

      panzoomInstance: null as PanzoomObject,

      // Layout
      showLayout: true,
      showMobileNav: false,
      showNav: false,
      headerTitle: "",
      currentPage: "information" as
        | "information"
        | "dce"
        | "questions"
        | "lots"
        | "candidature",
      currentModule: "consultation" as
        | "consultation"
        | "entreprise"
        | "evenements",
    };
  },
  getters: {
    imageUrl(state) {
      return state.image ? window.URL.createObjectURL(state.image) : "";
    },
    logoUrl(state) {
      return state.logo ? window.URL.createObjectURL(state.logo) : "";
    }, 
    mainPanelSize(state) {
      return 100 - state.sidePanelSize;
    },

    previewWidth(state) {
      switch (state.device) {
        case SimulationDevice.desktop:
          return 1920;
        case SimulationDevice.laptop:
          return 1280;
        case SimulationDevice.mobile:
          return 380;
      }
    },

    previewHeight(state) {
      switch (state.device) {
        case SimulationDevice.desktop:
          return 1080;
        case SimulationDevice.laptop:
          return 768;
        case SimulationDevice.mobile:
          return 650;
      }
    },

    isSingle() {
      const route = useRoute();
      if (route.query["single"]) {
        return true;
      }
      return false;
    },
  },
  actions: {
    async getPreviewImg(
      format: "blob" | "svg" = "blob"
    ): Promise<Blob | string> {
      const previewEl = document.getElementById("preview") as HTMLIFrameElement;
      const renderOption = {
        quality: 1,
        height: this.previewHeight,
        width: this.previewWidth,
      };
      const htmltoimage = await import("html-to-image");
      return new Promise((res, rej) => {
        if (format == "blob") {
          htmltoimage
            .toBlob(previewEl.contentDocument.body, renderOption)
            .then((value) => {
              res(value);
            })
            .catch((err) => {
              console.error(err);
              rej(err);
            });
        } else if (format == "svg") {
          htmltoimage
            .toSvg(previewEl, renderOption)
            .then((value) => {
              res(value);
            })
            .catch((err) => {
              console.error(err);
              rej(err);
            });
        }
      });
    },
    saveStateInLocalStorage() {
      const storedState = {};
      STORED_PROPERIES.forEach((k) => (storedState[k] = this.$state[k]));
      localStorage.setItem(STORAGE_KEY, JSON.stringify(storedState));
    },

    /**
     * Ferme la fenetre 4D et donc l'application
     */
    async closePreview() {
      await window["$4d"].cdf_Generalt();
    },

    previewZoomIn() {
      if (this.panzoomInstance) {
        this.panzoomInstance.zoomIn();
      }
    },
    previewZoomOut() {
      if (this.panzoomInstance) {
        this.panzoomInstance.zoomOut();
      }
    },
    previewReset() {
      if (this.panzoomInstance) {
        this.panzoomInstance.zoom(this.calcZoom());
        this.panzoomInstance.pan("0", "0");
      }
    },

    calcZoom() {
      let zoom = 1;

      const mainPaneEl = document.getElementById("mainPane");
      if (mainPaneEl) {
        const paneWidth = mainPaneEl.offsetWidth - 150;
        const paneHeigth = mainPaneEl.offsetHeight - 200;
        const width = this.previewWidth;
        const height = this.previewHeight;

        if (paneWidth > width) {
          while (
            paneWidth > width * zoom &&
            paneHeigth > height * zoom &&
            zoom <= MAX_SCALE
          ) {
            zoom += 0.05;
          }
        } else {
          while (
            (paneWidth < width * zoom || paneHeigth < height * zoom) &&
            zoom >= MIN_SCALE
          ) {
            zoom -= 0.05;
          }
        }
      }
      return zoom;
    },

    toggleSidepanel() {
      const breakpoints = useBreakpoints(breakpointsTailwind);
      if (breakpoints.isSmaller("md")) {
        if (this.sidePanelSize > 0) {
          this.sidePanelSize = 0;
        } else {
          this.sidePanelSize = 100;
        }
      } else {
        if (this.sidePanelSize > 30) {
          this.sidePanelSize = 30;
        } else {
          this.sidePanelSize = 70;
        }
      }
    },

    async fetchContext(
      params: Partial<Record<CONTEXT_DATA, any | null>>
    ): Promise<IPageContext> {
      return new Promise((res) => {
        if (window["$4d"]) {
          window["$4d"][METHODE_4D_GET_CONTEXT](params, (data) => res(data));
        } else {
          res(null);
        }
      });
    },

    async saveContext(data: Partial<IPageContext>): Promise<boolean> {
      return new Promise((res) => {
        if (window["$4d"]) {
          window["$4d"][METHODE_4D_SAVE_CONTEXT](data, (success) =>
            res(success)
          );
        } else {
          res(false);
        }
      });
    },
  },
});
