
import { createPopper } from "@/plugins/popper";
import { defineComponent, nextTick, PropType, ref, watch } from "vue";
import { BInputSwitch, BBtn } from "@/components/global";
import { User } from "@/components/global/icons";

export default defineComponent({
  name: "b-tooltip",
  inheritAttrs: false,
  props: {
    placement: {
      type: String as PropType<
        | "top"
        | "bottom"
        | "right"
        | "left"
        | "auto"
        | "auto-start"
        | "auto-end"
        | "top-start"
        | "top-end"
        | "bottom-start"
        | "bottom-end"
        | "right-start"
        | "right-end"
        | "left-start"
        | "left-end"
      >,
      default: () => "auto",
    },
    offset: {
      type: Array as PropType<number[]>,
    },
    triggerEl: {
      type: Object,
      required: false,
    },
    timeout: {
      type: Number,
      default: () => 0,
    },
    disabled: Boolean,
  },
  emits: ["show", "hide"],
  setup(props, { emit }) {
    let popperInstance = null;

    const open = ref(false);

    const defaultTriggerEl = ref();
    const targetEl = ref();
    const arrowEl = ref();

    const show = () => {
      if (!props.disabled) {
        open.value = true;
      }
    };

    const hide = () => {
      if (!props.disabled) {
        setTimeout(() => (open.value = false), props.timeout);
      }
    };

    const toggle = () => {
      if (!props.disabled) {
        open.value = !open.value;
      }
    };

    const getTriggerEl = () => {
      if (props.triggerEl) {
        return props.triggerEl;
      } else {
        return defaultTriggerEl.value;
      }
    };

    watch(
      () => open.value,
      (newValue) => {
        if (newValue) {
          nextTick(() => {
            popperInstance = createPopper(getTriggerEl(), targetEl.value, {
              placement: props.placement,
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, 8],
                  },
                },
                {
                  name: "arrow",
                },
              ],
            });
            emit("show");
          });
        } else if (popperInstance) {
          setTimeout(() => {
            popperInstance.destroy();
            emit("hide");
          }, 0);
        }
      }
    );

    return {
      open,

      defaultTriggerEl,
      targetEl,
      arrowEl,

      show,
      hide,
      toggle,
    };
  },
  components: {
    BInputSwitch,
    BBtn,
    User,
  },
});
