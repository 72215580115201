import { reactive, ref, watch } from "vue";
import {
  ICategoryFilters,
  IGroupFilter,
  IGroupImage,
  IGroupParticipant,
  IGroupTitles,
  IGroupUnread,
  IMessage,
  IModuleUsersInterne,
} from "@/pages/bureau/@models/fil.model";
import { IPagination } from "@/pages/bureau/@types/api";
import { IProjetSimple } from "@/pages/bureau/@models/projet.model";

class DefaultClass {
  public categories: IGroupTitles[] = reactive([]);
  public groupImages: IGroupImage = reactive({});
  public groupUnread: IGroupUnread = reactive({});

  public listener = ref<boolean>(false);
  public categoriesIsLoaded = ref<boolean>(false);

  public groupMessage: { [uidGroup: string]: IMessage[] } = reactive({});
  public groupParticipants: { [uidGroup: string]: IGroupParticipant[] } =
    reactive({});
  public groupMessagePagination: IPagination | any = reactive({});
  public groupFilter: { [uidGroup: string]: IGroupFilter } = reactive({});

  public moduleProjets: IProjetSimple[] = reactive([]);
  public moduleUsersInterne: IModuleUsersInterne[] = reactive([]);

  public filters: ICategoryFilters = reactive({ indexProjet: 0 });

  constructor() {
    //
  }

  /**
   *  permet de comparer les groupes non lus actuels avec les groupes non lus récupérés
   */
  public groupUnreadIsDifferent(
    groupArray: {
      uidGroup: string;
      nbMessNonLu: number;
    }[]
  ) {
    const groupUnreadObject = Object.keys(this.groupUnread)
      .sort()
      .map((key) => {
        return {
          uidGroup: key,
          nbMessNonLu: this.groupUnread[key],
        };
      });
    const groupArraySorted = groupArray.sort((a, b) => {
      return a.uidGroup.localeCompare(b.uidGroup);
    });
    return (
      JSON.stringify(groupUnreadObject) != JSON.stringify(groupArraySorted)
    );
  }
}
const FraisTemp = new DefaultClass();

export default reactive(FraisTemp);

export enum EFilType {
  PROJET = "PROJET",
  INTERNE = "INTERNE",
  INTERVENANT = "INTERVENANT",
  INTERVENANT_PROJET = "INTERVENANT_PROJET",
  TACHE = "TACHE",
}