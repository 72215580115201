import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import {
  usePreviewConsultationStore,
  usePreviewLoginStore,
  usePreviewStore,
} from "@/stores/preview";
import { IconType } from "@/components/global";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: {
      name: "login",
    },
  },
  {
    name: "login",
    path: "/login",
    components: {
      view: () => import("@/views/preview/login/LoginView.vue"),
      options: () => import("@/views/preview/login/LoginOptions.vue"),
    },
    meta: {
      ordre: 1,
      main: true,
      label: "Accueil",
      pageName: "Écran de connexion",
      icon: "preview",
    },
    beforeEnter: async () => {
      const previewStore = usePreviewStore();
      const loginStore = usePreviewLoginStore();
      previewStore.loading = true;
      await loginStore.initialize();
      previewStore.loading = false;
    },
  },
  {
    name: "consultation",
    path: "/consultation",
    components: {
      view: () =>
        import("@/views/preview/consultation-info/ConsultationInfoView.vue"),
      options: () =>
        import("@/views/preview/consultation-info/ConsultationInfoOptions.vue"),
    },
    meta: {
      ordre: 2,
      main: true,
      label: "Informations",
      pageName: "Informations de la consultation",
      icon: "information" as IconType,
    },
    beforeEnter: async () => {
      const previewStore = usePreviewStore();
      const loginStore = usePreviewLoginStore();
      const consultationStore = usePreviewConsultationStore();
      
      previewStore.loading = true;
      await Promise.all([
        loginStore.initialize(),
        consultationStore.initialize(),
      ]);
      previewStore.currentModule = "consultation";
      previewStore.currentPage = "information";
      previewStore.showNav = true;
      previewStore.loading = false;
    },
  },
  {
    name: "dce",
    path: "/dce",
    components: {
      view: () =>
        import("@/views/preview/consultation-dce/ConsultationDceView.vue"),
      options: () =>
        import("@/views/preview/consultation-dce/ConsultationDceOptions.vue"),
    },
    meta: {
      ordre: 3,
      main: true,
      label: "DCE",
      pageName: "DCE de la consultation",
      icon: "dce_bis" as IconType,
    },
    beforeEnter: async () => {
      const previewStore = usePreviewStore();
      const consultationStore = usePreviewConsultationStore();
      previewStore.loading = true;
      await consultationStore.initialize();
      previewStore.currentModule = "consultation";
      previewStore.currentPage = "dce";
      previewStore.showNav = true;
      previewStore.loading = false;
    },
  },
  {
    name: "questions",
    path: "/questions",
    components: {
      view: () =>
        import(
          "@/views/preview/consultation-questions/ConsultationQuestionsView.vue"
        ),
      options: () =>
        import(
          "@/views/preview/consultation-questions/ConsultationQuestionsOptions.vue"
        ),
    },
    meta: {
      ordre: 4,
      main: true,
      label: "Q / R",
      pageName: "Questions / Réponses de la consultation",
      icon: "question" as IconType,
    },
    beforeEnter: async () => {
      const previewStore = usePreviewStore();
      const consultationStore = usePreviewConsultationStore();
      previewStore.loading = true;
      await consultationStore.initialize();
      previewStore.currentModule = "consultation";
      previewStore.currentPage = "questions";
      previewStore.showNav = true;
      previewStore.loading = false;
    },
  },
];
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
