import {
  IConsultationBordereauDetail,
  IConsultationBordereauPrixConsult,
} from "@/@models/consultations";
import { consultationsService } from "@/api/consultations";
import { clone, generateUUID, isValidStatus, nextLetter } from "@/utils";
import { defineStore } from "pinia";
import { useToast } from "vue-toastification";
import { useConsultationStore } from "./consultation";

const toastification = useToast();

type AffichageFilter = "all" | "withValue" | "withoutValue";

export const useConsultationBordereaux = defineStore(
  "consultation-bordereaux",
  {
    state: () => {
      return {
        uidOffre: "",

        bordereau: null as IConsultationBordereauDetail,

        loading: false,

        //Affichage
        // Global

        /**
         * @deprecated On n'utilise plus se parametre, la quantite est toujours visible et est parametrable dans la consultation
         */
        quantiteEntreprise: true,
        totalArticle: true,
        generalite: true,
        animations: true,
        totalTtc: false,

        // Tableau
        showTotal: true,
        showOuvrage: true,
        showArticle: true,
        showComplement: true,
        showVariant: true,
        showForfait: true,

        // Filtres
        filterMontant: "all" as AffichageFilter,
        filterQuantite: "all" as AffichageFilter,
        filterPrisEnCompte: "all" as AffichageFilter,
      };
    },
    getters: {
      canAddComplement() {
        const consultationStore = useConsultationStore();
        if (consultationStore.consultation?.params) {
          return consultationStore.consultation.params.sp_ligneComplement;
        }
        return false;
      },
      canAddVariant() {
        const consultationStore = useConsultationStore();
        if (consultationStore.consultation?.params) {
          return consultationStore.consultation.params.sp_ajtVariant;
        }
        return false;
      },
      canAddForfait() {
        const consultationStore = useConsultationStore();
        if (consultationStore.consultation?.params) {
          return consultationStore.consultation.params.sp_montantForfaitaire;
        }
        return false;
      },
      getPrixConsultByUid(state) {
        return (uid: string) =>
          state.bordereau &&
          state.bordereau.details &&
          getPrixConsultById(state.bordereau.details, uid);
      },
      getPrixConsult(state) {
        return (key: keyof IConsultationBordereauPrixConsult, value: any) =>
          state.bordereau &&
          state.bordereau.details &&
          findAllPrixConsultByKey(state.bordereau.details, key, value);
      },

      /**
       * Indique si la ligne possède une variante
       * @param state
       */
      hasVariant(state) {
        return (prixConsult: IConsultationBordereauPrixConsult) => {
          if (state.bordereau && state.bordereau.details) {
            return (
              findPrixConsult(
                state.bordereau.details,
                (prix) => prix.articleRef == prixConsult.uid && !prix.isDeleted
              ) != null
            );
          }
          return false;
        };
      },
      getVariants(state) {
        return (prixConsult: IConsultationBordereauPrixConsult) => {
          if (state.bordereau && state.bordereau.details) {
            return findAllPrixConsultByKey(
              state.bordereau.details,
              "articleRef",
              prixConsult.uid
            ).filter((o) => !o.isDeleted);
          }
          return [];
        };
      },
      flatDetail(state): IConsultationBordereauPrixConsult[] {
        if (state.bordereau && state.bordereau.details) {
          return getFlatPrixConsult(state.bordereau.details);
        }
        return [];
      },
      getPrixConsultType() {
        return (prixConsult: IConsultationBordereauPrixConsult) =>
          getPrixConsultType(prixConsult);
      },
      hasChilds() {
        return (uid: string) => {
          const result = this.getPrixConsultByUid(uid);
          if (result) {
            return result.ouvrages && result.ouvrages.length > 0;
          }
          return false;
        };
      },
      getComplementCode(state) {
        return (prixConsult: IConsultationBordereauPrixConsult) => {
          if (state.bordereau && state.bordereau.details) {
            if (prixConsult) {
              let prix = null;
              if (prixConsult.estArticle) {
                prix = getPrixConsultById(
                  state.bordereau.details,
                  prixConsult.parentUid
                );
              } else {
                prix = getPrixConsultById(
                  state.bordereau.details,
                  prixConsult.uid
                );
              }

              if (prix) {
                let currentCode = nextLetter("");
                while (
                  prix.ouvrages.find(
                    (x) => x.code == prixConsult.code + currentCode
                  )
                ) {
                  currentCode = nextLetter(currentCode);
                }
                return prixConsult.code + currentCode;
              }
            }
          }
          return "";
        };
      },

      /**
       * Recupere le taux TVA au format 1.{Taux TVA}
       * @returns   1.{Taux TVA} ou 1 si tva non trouvé
       */
      getPrixConsultTvaValue() {
        const consultationStore = useConsultationStore();
        return (prixConsult: IConsultationBordereauPrixConsult) => {
          if (prixConsult.tauxTva != null) {
            if (
              consultationStore.consultation &&
              consultationStore.consultation.tva
            ) {
              // On fait moins -1 car prixConsult.tauxTva = 0 correspont à pas de TVA, il y a donc un +1 sur tout les index de TVA
              const tva =
                consultationStore.consultation.tva[prixConsult.tauxTva - 1];
              if (tva) {
                return 1 + tva / 100;
              }
            }
          }
          return 1;
        };
      },
      /**
       * Vrai si au moins une ligne à était modifié / créée / suprimmé
       */
      hasChanges(state) {
        if (state.bordereau) {
          return (
            findPrixConsult(
              state.bordereau.details,
              (prix) => prix.isEdited || prix.isAddedRow || prix.isDeleted
            ) != null
          );
        }
        return false;
      },

      isHidden(state) {
        return (prix: IConsultationBordereauPrixConsult) => {
          const type = getPrixConsultType(prix);

          if (type == "article" && !state.showArticle) {
            return true;
          }
          if (type == "complement" && !state.showComplement) {
            return true;
          }
          if (
            (type == "ouvrage" || type == "generalite") &&
            !state.showOuvrage
          ) {
            return true;
          }
          if (type == "variant" && !state.showVariant) {
            return true;
          }
          if (
            !state.showForfait &&
            (prix.estForfait || prix.estElementForfait)
          ) {
            return true;
          }

          if (state.filterMontant == "withValue") {
            if (type != "generalite") {
              if (prix.estPrisEnCompte && !prix.isDeleted) {
                if (type == "ouvrage" && prix.estForfait && !prix.prixTotal) {
                  return true;
                } else if (
                  !prix.prixUnit &&
                  !prix.estElementForfait &&
                  !this.hasVariant(prix)
                ) {
                  return true;
                }
              }
            }
          }

          if (state.filterMontant == "withoutValue") {
            if (type != "generalite") {
              if (prix.estPrisEnCompte && !prix.isDeleted) {
                if (type == "ouvrage" && prix.estForfait && prix.prixTotal) {
                  return true;
                } else if (
                  prix.prixUnit &&
                  !prix.estElementForfait &&
                  !this.hasVariant(prix)
                ) {
                  return true;
                }
              }
            }
          }

          if (state.filterQuantite == "withValue") {
            if (type != "generalite") {
              if (
                !prix.quantite &&
                !prix.estElementForfait &&
                prix.estPrisEnCompte &&
                !prix.isDeleted &&
                !prix.estForfait &&
                !prix.estElementForfait &&
                !this.hasVariant(prix)
              ) {
                return true;
              }
            }
          }

          if (state.filterQuantite == "withoutValue") {
            if (type != "generalite") {
              if (
                prix.quantite &&
                prix.estPrisEnCompte &&
                !prix.isDeleted &&
                !prix.estForfait &&
                !prix.estElementForfait &&
                !this.hasVariant(prix)
              ) {
                return true;
              }
            }
          }

          if (state.filterPrisEnCompte == "withValue") {
            if (!prix.estPrisEnCompte && !prix.isDeleted) {
              return true;
            }
          }

          if (state.filterPrisEnCompte == "withoutValue") {
            if (prix.estPrisEnCompte && !prix.isDeleted) {
              return true;
            }
          }

          return false;
        };
      },
    },
    actions: {
      /**
       * Recupere les bordereaux d'une offre
       * @returns
       */
      /*         async fetchBordereaux(uid_lot: string, uid_offre: string) {
                    this.loading = true;
                    const { status, data } = await consultationsService.getConsultationBordereaux(uid_lot, uid_offre);
                    if (!isValidStatus(status) || !data || !data.bordereaux) {
                        this.bordereaux = [];
                        toastification.error("La récupération des bordereaux a échoué.")
                        return false;
                    }
                    this.bordereaux = data.bordereaux
                    this.loading = false;
                    return true;
                }, */
      /**
       * Recupere un bordereau complet
       * @returns
       */
      async fetchBordereau(
        uid_lot: string,
        uid_offre: string,
        uid_bordereau: string
      ) {
        this.loading = true;
        const { status, data } =
          await consultationsService.getConsultationBordereau(
            uid_lot,
            uid_offre,
            uid_bordereau
          );
        if (!isValidStatus(status) || !data || !data.bordereau) {
          this.bordereau = null;
          this.uidOffre = "";
          toastification.error("La récupération du bordereau a échoué.");
          return false;
        }
        this.uidOffre = uid_offre;
        this.bordereau = data.bordereau;
        this.loading = false;

        this.sortBordereauPrix();

        return true;
      },
      updatePrixConsultPrixUnitaire(uid_prixconsult: string, newValue: number) {
        if (this.bordereau && this.bordereau.details) {
          const prix = getPrixConsultById(
            this.bordereau.details,
            uid_prixconsult
          );
          if (prix) {
            prix.prixUnit = newValue;
            prix.prixTotal = newValue * prix.quantite;
            prix.isEdited = true;
          }
        }
      },
      updatePrixConsultQuantite(uid_prixconsult: string, newValue: number) {
        if (this.bordereau && this.bordereau.details) {
          const prix = getPrixConsultById(
            this.bordereau.details,
            uid_prixconsult
          );
          if (prix) {
            prix.quantite = newValue;
            prix.prixTotal = newValue * prix.prixUnit;
            prix.isEdited = true;
          }
        }
      },
      /**
       * Methode pour mettre à jour le montant d'un FORFAIT
       * @param uid_prixconsult
       * @param newValue
       */
      updatePrixConsultPrixTotal(uid_prixconsult: string, newValue: number) {
        if (this.bordereau && this.bordereau.details) {
          const prix = getPrixConsultById(
            this.bordereau.details,
            uid_prixconsult
          );
          if (prix) {
            prix.quantite = 0;
            prix.prixUnit = 0;
            prix.prixTotal = newValue;
            prix.isEdited = true;
          }
        }
      },
      toggleExpandPrixConsult(uid_prixconsult: string) {
        if (this.bordereau && this.bordereau.details) {
          const prix = getPrixConsultById(
            this.bordereau.details,
            uid_prixconsult
          );
          if (prix) {
            if (prix.isExpanded) {
              prix.isExpanded = false;
            } else {
              prix.isExpanded = true;
            }
          }
        }
      },
      expendAllOuvrages() {
        if (this.bordereau && this.bordereau.details) {
          applyAllPrixConsult(this.bordereau.details, "isExpanded", true);
        }
      },
      collapseAllOuvrages() {
        if (this.bordereau && this.bordereau.details) {
          applyAllPrixConsult(this.bordereau.details, "isExpanded", false);
        }
      },
      /**
       * Ajoute un complement dans le bordereau courant
       * @param complemement
       */
      addComplement(complement: IConsultationBordereauPrixConsult) {
        if (complement) {
          // UUID temporaire
          complement.uid = generateUUID();
          complement.estArticle = true;
          complement.estComplement = true;
          complement.estGeneralite = false;

          // Pour identifié les nouvelles lignes
          complement.isAddedRow = true;

          // on veut l'ajouter a son parent et mettre a jour l'ordre
          const parent = getPrixConsultById(
            this.bordereau.details,
            complement.parentUid
          );
          if (parent) {
            parent.ouvrages
              .filter((child) => child.ordre >= complement.ordre)
              .forEach((child) => {
                child.ordre++;
                child.isEdited = true;
              });

            // On ajoute le complement
            parent.ouvrages.push(complement);
            this.sortBordereauPrix();
          }
        }
      },
      editComplement(newValue: IConsultationBordereauPrixConsult) {
        if (newValue) {
          const complement = getPrixConsultById(
            this.bordereau.details,
            newValue.uid
          );

          for (const [key, value] of Object.entries(newValue))
            complement[key] = value;

          complement.estArticle = true;
          complement.estComplement = true;
          complement.estGeneralite = false;

          complement.isEdited = true;
        }
      },
      deleteComplement(uid_prix: string) {
        if (uid_prix) {
          const complement = getPrixConsultById(
            this.bordereau.details,
            uid_prix
          );
          if (complement) {
            if (complement.isAddedRow) {
              const parent = getPrixConsultById(
                this.bordereau.details,
                complement.parentUid
              );
              if (parent) {
                parent.ouvrages = parent.ouvrages.filter(
                  (child) => child.uid != uid_prix
                );
              }
            } else {
              complement.isDeleted = true;
            }
          }
        }
      },
      /**
       * Ajoute une variante dans le bordereau courant
       * @param variant
       */
      addVariant(variant: IConsultationBordereauPrixConsult) {
        if (variant) {
          // UUID temporaire
          variant.uid = generateUUID();
          variant.estArticle = true;
          variant.estComplement = false;
          variant.estVariante = true;
          variant.estGeneralite = false;
          variant.ouvrage = null;

          // Pour identifié les nouvelles lignes
          variant.isAddedRow = true;

          // on veut l'ajouter a son parent et mettre a jour l'ordre
          const parent = getPrixConsultById(
            this.bordereau.details,
            variant.parentUid
          );
          if (parent) {
            parent.ouvrages
              .filter((child) => child.ordre >= variant.ordre)
              .forEach((child) => {
                child.ordre++;
                child.isEdited = true;
              });

            // On ajoute le complement
            parent.ouvrages.push(variant);
            this.sortBordereauPrix();
          }
        }
      },
      editVariant(newValue: IConsultationBordereauPrixConsult) {
        if (newValue) {
          const variant = getPrixConsultById(
            this.bordereau.details,
            newValue.uid
          );

          for (const [key, value] of Object.entries(newValue))
            variant[key] = value;

          variant.estArticle = true;
          variant.estComplement = false;
          variant.estVariante = true;
          variant.estGeneralite = false;

          variant.isEdited = true;
        }
      },
      deleteVariant(uid_prix: string) {
        if (uid_prix) {
          const variant = getPrixConsultById(this.bordereau.details, uid_prix);
          if (variant) {
            if (variant.isAddedRow) {
              const parent = getPrixConsultById(
                this.bordereau.details,
                variant.parentUid
              );
              if (parent) {
                parent.ouvrages = parent.ouvrages.filter(
                  (child) => child.uid != uid_prix
                );
              }
            } else {
              variant.isDeleted = true;
            }
          }
        }
      },
      /**
       * Ajoute un forfait dans le bordereau courant
       * @param variant
       */
      addForfait(_forfait: IConsultationBordereauPrixConsult) {
        const prix = getPrixConsultById(this.bordereau.details, _forfait.uid);
        if (prix) {
          prix.estForfait = true;
          prix.estPrisEnCompte = true;
          prix.prixUnit = clone(_forfait.prixTotal);
          prix.prixTotal = clone(_forfait.prixTotal);
          prix.tauxTva = _forfait.tauxTva;
          prix.isEdited = true;
          findAllPrixConsultByKey(
            this.bordereau.details,
            "parentUid",
            _forfait.uid
          ).forEach((x) => {
            x.estForfait = false;
            x.estElementForfait = true;
            x.isEdited = true;
          });
        }
      },
      editForfait(newValue: IConsultationBordereauPrixConsult) {
        if (newValue) {
          const forfait = getPrixConsultById(
            this.bordereau.details,
            newValue.uid
          );

          for (const [key, value] of Object.entries(newValue))
            forfait[key] = value;

          forfait.estArticle = false;
          forfait.estForfait = true;
          forfait.estPrisEnCompte = true;
          forfait.estComplement = false;
          forfait.estVariante = false;

          forfait.isEdited = true;

          forfait.prixUnit = clone(forfait.prixTotal);

          // On verifie que tout les enfants ont leur statut à jour
          findAllPrixConsultByKey(
            this.bordereau.details,
            "parentUid",
            forfait.uid
          ).forEach((x) => {
            if (!x.estElementForfait) {
              x.estElementForfait = true;
              x.isEdited = true;
            }
          });
        }
      },
      deleteForfait(uid_prix: string) {
        if (uid_prix) {
          const prix = getPrixConsultById(this.bordereau.details, uid_prix);
          if (prix) {
            prix.estForfait = false;
            prix.estPrisEnCompte = false;
            prix.isEdited = true;
          }
          findAllPrixConsultByKey(
            this.bordereau.details,
            "parentUid",
            uid_prix
          ).forEach((x) => {
            x.estForfait = false;
            x.estElementForfait = false;
            x.isEdited = true;
          });
        }
      },
      /**
       * Pour mettre a jour l'odre des prix consult
       */
      sortBordereauPrix() {
        sortPrixConsult(this.bordereau.details);
      },

      /**
       * Enregistre les modifications du bordereau, penser à re récuperer le bordereau, pour avoir les bonnes UUID pour les lignes ajoutées
       */
      async saveChanges(
        uid_lot: string,
        uid_offre: string,
        uid_bordereau: string
      ): Promise<boolean> {
        const allRows: IConsultationBordereauPrixConsult[] = this.flatDetail;

        const rowsEdited: IConsultationBordereauPrixConsult[] = [];
        const rowsAdded: IConsultationBordereauPrixConsult[] = [];
        const rowsDeleted: IConsultationBordereauPrixConsult[] = [];

        // On recupere les prixConsult à modifié / crééer / supprimer, l'ordre est important pour éviter une modification d'une ligne ajouté par exemple, car une fois ajouté elle change d'UUID
        allRows.forEach((prix) => {
          if (prix.isDeleted) {
            rowsDeleted.push(prix);
          } else if (prix.isAddedRow) {
            rowsAdded.push(prix);
          } else if (prix.isEdited) {
            rowsEdited.push(prix);
          }
        });

        // On commence par les suppressions
        if (rowsDeleted.length > 0) {
          // On verifie le type de la ligne
          rowsDeleted
            .filter((x) => x.estComplement || x.estVariante)
            .forEach(async (p) => {
              const { status } = await consultationsService.deleteBordereauPrix(
                uid_lot,
                uid_offre,
                uid_bordereau,
                p.uid
              );
              if (!isValidStatus(status)) {
                toastification.error("La suppression de la ligne a échouée.");
                return false;
              }
            });
        }

        // Ensuite les ajouts
        if (rowsAdded.length > 0) {
          const { status } = await consultationsService.postBordereauPrix(
            uid_lot,
            uid_offre,
            uid_bordereau,
            rowsAdded
          );
          if (!isValidStatus(status)) {
            toastification.error("L'ajout des lignes a échouées.");
            return false;
          }
        }

        // Enfin les modifications
        if (rowsEdited.length > 0) {
          const { status } = await consultationsService.putBordereauPrix(
            uid_lot,
            uid_offre,
            uid_bordereau,
            rowsEdited
          );
          if (!isValidStatus(status)) {
            toastification.error("La modifications des lignes a échouées.");
            return false;
          }
        }

        allRows.forEach((p) => {
          p.isAddedRow = false;
          p.isEdited = false;
          p.isDeleted = false;
        });

        toastification.success("Enregistrement réussi !");

        return true;
      },
    },
  }
);

/**
 * Retourne le détails du bordereau en 'array plate'
 * @param bordereau
 * @param addTotalOuvrage ? ajoute une ligne total apres les articles d'un ouvrage
 * @returns
 */
export function getFlatPrixConsult(
  arr: IConsultationBordereauPrixConsult[]
): IConsultationBordereauPrixConsult[] {
  let ouvrages: IConsultationBordereauPrixConsult[] = [];
  if (!Array.isArray(arr)) return ouvrages;

  arr.forEach((x) => {
    const ouvrage = { ...x } as IConsultationBordereauPrixConsult;
    ouvrages.push(ouvrage);
    if (x.ouvrages) {
      const childResults = getFlatPrixConsult(
        x.ouvrages.sort((a, b) => a.ordre - b.ordre)
      );
      if (childResults.length) {
        ouvrages = ouvrages.concat(childResults);
      }
    }
    // Pour ajouter une ligne avec le total des articles
    /*         if (addTotalOuvrage) {
                    const total = getTotalOuvrage(x);
                    if (total) {
                        ouvrages.push(total)
                    }
                } */
    delete ouvrage.ouvrages; // On supprime pour ne pas avoir des données en doubles
  });
  return ouvrages;
}

export function getPrixConsultType(
  prixConsult: IConsultationBordereauPrixConsult
): "ouvrage" | "generalite" | "article" | "complement" | "variant" {
  if (prixConsult) {
    if (prixConsult.estGeneralite) {
      return "generalite";
    } else if (prixConsult.estComplement) {
      return "complement";
    } else if (prixConsult.estVariante) {
      return "variant";
    } else if (prixConsult.estArticle) {
      return "article";
    }
    return "ouvrage";
  }
}

/**
 * Retoune l'array de résultat, attention au type
 * @param arr source
 * @param key champs sur lequel chercher
 * @param value valeur à chercher
 * @returns array
 */
export function getPrixConsultById(
  arr: IConsultationBordereauPrixConsult[],
  uid: string
): IConsultationBordereauPrixConsult {
  let matche = null;

  arr.forEach((x) => {
    if (x.uid == uid) {
      matche = x;
    }
    if (x.ouvrages) {
      const result = getPrixConsultById(x.ouvrages, uid);
      if (result) {
        matche = result;
      }
    }
  });

  return matche;
}

/**
 * Retoune l'array de résultat, attention au type
 * @param arr source
 * @param key champs sur lequel chercher
 * @param value valeur à chercher
 * @returns array
 */
export function findAllPrixConsultByKey(
  arr: IConsultationBordereauPrixConsult[],
  key: keyof IConsultationBordereauPrixConsult,
  value: any
): IConsultationBordereauPrixConsult[] {
  let matches = [];
  if (!Array.isArray(arr)) return matches;

  arr.forEach((x) => {
    if (x[key] == value) {
      matches.push(x);
    }
    if (x.ouvrages) {
      const childResults = findAllPrixConsultByKey(x.ouvrages, key, value);
      if (childResults.length) {
        matches = matches.concat(childResults);
      }
    }
  });

  return matches;
}

/**
 * Retoune l'array de résultat, attention au type
 * @param arr source
 * @returns array
 */
export function filterPrixConsult(
  arr: IConsultationBordereauPrixConsult[],
  predicate: (prix: IConsultationBordereauPrixConsult) => boolean
): IConsultationBordereauPrixConsult[] {
  let matches = [];
  if (!Array.isArray(arr)) return matches;
  if (predicate == undefined) return matches;

  arr.forEach((x) => {
    if (predicate(x)) {
      matches.push(x);
    }
    if (x.ouvrages) {
      const childResults = filterPrixConsult(x.ouvrages, predicate);
      if (childResults.length) {
        matches = matches.concat(childResults);
      }
    }
  });

  return matches;
}

/**
 * Retoune l'array de résultat, attention au type
 * @param arr source
 * @returns array
 */
export function findPrixConsult(
  arr: IConsultationBordereauPrixConsult[],
  predicate: (prix: IConsultationBordereauPrixConsult) => boolean
): IConsultationBordereauPrixConsult {
  let matche = null;
  if (!Array.isArray(arr)) return matche;
  if (predicate == undefined) return matche;

  arr.forEach((x) => {
    if (predicate(x)) {
      matche = x;
    }
    if (x.ouvrages) {
      const childResult = filterPrixConsult(x.ouvrages, predicate);
      if (childResult.length) {
        matche = childResult;
      }
    }
  });

  return matche;
}

/**
 * Permet d'appliquer une valeur à l'ensemble des prix consult et leur enfants
 * @param arr source
 * @param key champs sur lequel chercher
 * @param value valeur à chercher
 * @returns array
 */
export function applyAllPrixConsult(
  arr: IConsultationBordereauPrixConsult[],
  key: keyof IConsultationBordereauPrixConsult,
  newValue: any
): void {
  arr.forEach((x: any) => {
    x[key] = newValue;
    if (x.ouvrages) {
      applyAllPrixConsult(x.ouvrages, key, newValue);
    }
  });
}

export function sortPrixConsult(arr: IConsultationBordereauPrixConsult[]) {
  if (!Array.isArray(arr) || arr.length == 0) return;
  arr.forEach((x) => {
    if (x.ouvrages) {
      x.ouvrages = x.ouvrages.sort((a, b) => a.ordre - b.ordre);
      sortPrixConsult(x.ouvrages);
    }
  });
}
