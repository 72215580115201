import { IFichierCaoPreference, ILigneCao, ILigneCaoLien, ILigneCaoQuantite, IOuvrageCao } from '@/@models/fichier-cao'
import { bimService } from '@/api/bim';
import { checkData, isValidStatus } from '@/utils';
import { defineStore } from 'pinia'

export const useElementsCaoStore = defineStore('elements-cao', {
    state: () => {
        return {
            element: null as ILigneCao,
            ouvrages: [] as IOuvrageCao[],
            quantites: [] as ILigneCaoQuantite[],
            liens: [] as ILigneCaoLien[]

        }
    },
    getters: {
        groupedOuvragesByLot(state) {
            if (state.ouvrages) {
                return groupOuvragesByLot(state.ouvrages)
            }
            return [];
        },
        groupedQuantitesByTheme(state) {
            if (state.ouvrages) {
                return groupQuantitesByTheme(state.quantites)
            }
            return [];
        },
        liensWithIfc(state) {
            return state.liens.filter(l => l.idIfc)
        },
        liensWithoutIfc(state) {
            return state.liens.filter(l => !l.idIfc)
        }
    },
    actions: {
        async fetchElementByUid(uidFichier, uidElement) {
            const { status, data } = await bimService.getLigneCaoByUid(uidFichier, uidElement);
            if (!isValidStatus(status) || !checkData(data)) {
                this.element = null;
                this.error = Error("La récupération de l'element a échoué.");
                return false;
            }
            this.element = data.element;
            return true;
        },
        async fetchElementByIfc(uidFichier, ifcElement) {
            const { status, data } = await bimService.getLigneCaoByIfc(uidFichier, ifcElement);
            if (!isValidStatus(status) || !checkData(data)) {
                this.element = null;
                this.error = Error("La récupération de l'element a échoué.");
                return false;
            }
            this.element = data.element;
            return true;
        },
        async fetchElementByCao(uidFichier, caoElement) {
            const { status, data } = await bimService.getLigneCaoByCao(uidFichier, caoElement);
            if (!isValidStatus(status) || !checkData(data)) {
                this.element = null;
                this.error = Error("La récupération de l'element a échoué.");
                return false;
            }
            this.element = data.element;
            return true;
        },

        async fetchOuvragesByElementUid(uidFichier, uidElement) {
            const { status, data } = await bimService.getOuvragesLigneCaoByUid(uidFichier, uidElement);
            if (!isValidStatus(status) || !checkData(data)) {
                this.ouvrages = [];
                this.error = Error("La récupération des ouvrages de l'element a échoué.");
                return false;
            }
            this.ouvrages = data.ouvrages;
            return true;
        },
        async fetchOuvragesByElementIfc(uidFichier, ifcElement) {
            const { status, data } = await bimService.getOuvragesLigneCaoByIfc(uidFichier, ifcElement);
            if (!isValidStatus(status) || !checkData(data)) {
                this.ouvrages = [];
                this.error = Error("La récupération des ouvrages de l'element a échoué.");
                return false;
            }
            this.ouvrages = data.ouvrages;
            return true;
        },
        async fetchOuvragesByElementCao(uidFichier, caoElement) {
            const { status, data } = await bimService.getOuvragesLigneCaoByCao(uidFichier, caoElement);
            if (!isValidStatus(status) || !checkData(data)) {
                this.ouvrages = [];
                this.error = Error("La récupération des ouvrages de l'element a échoué.");
                return false;
            }
            this.ouvrages = data.ouvrages;
            return true;
        },

        async fetchQuantitesByElementUid(uidFichier, uidElement) {
            const { status, data } = await bimService.getQuantitesLigneCaoByUid(uidFichier, uidElement);
            if (!isValidStatus(status) || !checkData(data)) {
                this.quantites = [];
                this.error = Error("La récupération des quantités de l'element a échoué.");
                return false;
            }
            this.quantites = data.quantites;
            return true;
        },
        async fetchQuantitesByElementIfc(uidFichier, ifcElement) {
            const { status, data } = await bimService.getQuantitesLigneCaoByIfc(uidFichier, ifcElement);
            if (!isValidStatus(status) || !checkData(data)) {
                this.quantites = [];
                this.error = Error("La récupération des quantités de l'element a échoué.");
                return false;
            }
            this.quantites = data.quantites;
            return true;
        },
        async fetchQuantitesByElementCao(uidFichier, caoElement) {
            const { status, data } = await bimService.getQuantitesLigneCaoByCao(uidFichier, caoElement);
            if (!isValidStatus(status) || !checkData(data)) {
                this.quantites = [];
                this.error = Error("La récupération des quantités de l'element a échoué.");
                return false;
            }
            this.quantites = data.quantites;
            return true;
        },

        async fetchLiensByElementUid(uidFichier, uidElement) {
            const { status, data } = await bimService.getLiensLigneCaoByUid(uidFichier, uidElement);
            if (!isValidStatus(status) || !checkData(data)) {
                this.liens = [];
                this.error = Error("La récupération des liens de l'element a échoué.");
                return false;
            }
            this.liens = data.liens;
            return true;
        },
        async fetchLiensByElementIfc(uidFichier, ifcElement) {
            const { status, data } = await bimService.getLiensLigneCaoByIfc(uidFichier, ifcElement);
            if (!isValidStatus(status) || !checkData(data)) {
                this.liens = [];
                this.error = Error("La récupération des liens de l'element a échoué.");
                return false;
            }
            this.liens = data.liens;
            return true;
        },
        async fetchLiensByElementCao(uidFichier, caoElement) {
            const { status, data } = await bimService.getLiensLigneCaoByCao(uidFichier, caoElement);
            if (!isValidStatus(status) || !checkData(data)) {
                this.liens = [];
                this.error = Error("La récupération des liens de l'element a échoué.");
                return false;
            }
            this.liens = data.liens;
            return true;
        },
    }
})


export function groupOuvragesByLot(ouvrages: IOuvrageCao[]): {
    uidLot: string,
    codeLot: string,
    nomLot: string,
    ouvrages: IOuvrageCao[],
}[] {
    let result = [];

    // On genere une array avec les articles en les groupant par lot
    const grouped = ouvrages.reduce((rv, x) => {
        (rv[x.uidLot] = rv[x.uidLot] || []).push(x);
        return rv;
    }, {});

    // On map les groupes
    const groupedLotOuvrages = [];
    for (const [key, value] of Object.entries(grouped)) {
        groupedLotOuvrages.push({
            uidLot: key,
            codeLot: value[0].codeLot,
            nomLot: value[0].nomLot,
            ouvrages: value,
        });
    }

    // On supprime les articles en doubles
    for (const ouvragesLot of groupedLotOuvrages) {
        let ouvrages = [];
        ouvragesLot.ouvrages.forEach((x) => {
            if (!ouvrages.find((y) => y.uid == x.uid)) {
                ouvrages.push(x);
            }
        });

        // On profite pour ranger
        ouvrages = ouvrages.sort((a, b) =>
            a.code?.localeCompare(b.code)
        );

        result.push({
            uidLot: ouvragesLot.uidLot,
            codeLot: ouvragesLot.codeLot,
            nomLot: ouvragesLot.nomLot,
            ouvrages: ouvrages,
        });
    }


    // On range
    result = result.sort((a, b) => a.codeLot?.localeCompare(b.codeLot))

    return result
}

export function groupQuantitesByTheme(quantites: ILigneCaoQuantite[]): {
    theme: string,
    quantites: ILigneCaoQuantite[]
}[] {
    let result = [];

    // On genere une array avec les articles en les groupant par lot
    const grouped = quantites.reduce((rv, x) => {
        (rv[x.theme] = rv[x.theme] || []).push(x);
        return rv;
    }, {});

    for (const [theme, values] of Object.entries(grouped)) {
        if (theme) {
            result.push({
                theme,
                quantites: (values as ILigneCaoQuantite[]).sort((x, y) => x.nom.localeCompare(y.nom))
            })
        }
    }

    result = result.sort((x, y) => x.quantites[0].themeOrdre - y.quantites[0].themeOrdre)

    return result;
}

export function extractData(element: ILigneCao, preferences?): Partial<LigneCaoData> {
    const data: Partial<LigneCaoData> = {};
    if (element && element.value) {
        element.value.vkParam.forEach((param) => {
            switch (param.propriete) {
                case "Nom":
                    data.nom = param.objet.valeur;
                    break;
                case "Level":
                    data.etage = param.objet.valeur;
                    break;
                case "Layer":
                    data.calque = param.objet.valeur;
                    break;
                case "Appartement<PS&Zone>":
                    data.appartement = param.objet.valeur;
                    break;
                case "Batiment<PS&Généralités>":
                    data.batiment = param.objet.valeur;
                    break;
                case "ID":
                    data.identifiant = param.objet.valeur;
                    break;
                case "TypeName":
                    data.type = param.objet.valeur;
                    break;
                case "Fonction structurelle":
                    data.fonction = param.objet.valeur;
                    break;
                case "Reference":
                    data.reference = param.objet.valeur;
                    break;
                case "Height":
                    data.hauteur = param.objet.valeur;
                    break;
                case "ROOM_HEIGHT":
                    data.hauteur = param.objet.valeur;
                    break;
                case "Length":
                    data.longueur = param.objet.valeur;
                    break;
                case "ROOM_WALLS_PRM":
                    data.epaisseur = param.objet.valeur;
                    break;
                case "Gross Volume":
                    data.volume_brute = param.objet.valeur;
                    break;
                case "Volume":
                    data.volume = param.objet.valeur;
                    break;
                case "ROOM_WALLS_SURF":
                case "Surface1":
                case "Surface":
                    data.surface = param.objet.valeur;
                    break;
                case "ROOM_PERIM":
                    data.perimetre = param.objet.valeur;
                    break;
                case "État de rénovation":
                    data.etatRenovation = param.objet.valeur;
                    break;
                case "ROOM_AREA":
                    data.zonePiece = param.objet.valeur;
                    break;
                case "ROOM_DOORS_WID":
                    data.largeurPorte = param.objet.valeur;
                    break;
                case "Classification ARCHICAD 2.0":
                    data.classArchi = param.objet.valeur;
                    break;
                case "Thickness":
                    data.epaisseur = param.objet.valeur;
                    break;
                case "OverallHeight":
                    data.hauteurMoyenne = param.objet.valeur;
                    break;
                case "gs_frame_mat":
                    data.cadreFenetre = param.objet.valeur;
                    break;
                case "gs_glass_mat":
                    data.vitreFenetre = param.objet.valeur;
                    break;
                case "Owner":
                    data.ownerCaoId = param.objet.valeur;
                    break;
                case "From Zone":
                    data.fromZoneCaoId = param.objet.valeur;
                    break;
                case "To Zone":
                    data.toZoneCaoId = param.objet.valeur;
                    break;
                case "A":
                    data.a = param.objet.valeur;
                    break;
                case "B":
                    data.b = param.objet.valeur;
                    break;
                case "Hole Height":
                    data.trouHauteur = param.objet.valeur;
                    break;
                case "Hole Width":
                    data.trouLargeur = param.objet.valeur;
                    break;
                case "Holes Surface":
                    data.trouSurface = param.objet.valeur;
                    break;
                case "Holes Edge Surface":
                    data.trouBordSurface = param.objet.valeur;
                    break;
                case "Holes Number":
                    data.nbTrous = param.objet.valeur;
                    break;
                case "Holes Volume":
                    data.trouVolume = param.objet.valeur;
                    break;
                case "Right Length":
                    data.longueurDroite = param.objet.valeur;
                    break;
                case "Left Length":
                    data.longueurGauche = param.objet.valeur;
                    break;
                case "Bottom Surface":
                    data.surfaceDessous = param.objet.valeur;
                    break;
                case "Top Surface":
                    data.surfaceDessus = param.objet.valeur;
                    break;
                case "Edge Surface Left":
                    data.surfaceBordGauche = param.objet.valeur;
                    break;
                case "Edge Surface Right":
                    data.surfaceBordDroit = param.objet.valeur;
                    break;
            }
        });
    }
    // Nouveau: on lit certain parametre via les preferences de l'étude
    Object.assign(data, extractDataByPref(element, preferences))
    return data
}

export function extractDataByPref(element: ILigneCao, preferences: IFichierCaoPreference[]) {
    const data = {
        batiment: "",
        appartement: "",
        niveau: "",
        piece: ""
    }
    let param;
    if (element && preferences) {
        preferences.forEach(pref => {
            param = null;
            switch (pref.nom) {
                case "etu_FiltreBatiment":
                    param = element.value.vkParam.find(x => x.propriete == pref.valeur)
                    if (param) data.batiment = param.objet.valeur
                    break;
                case "etu_FiltreEntite":
                    param = element.value.vkParam.find(x => x.propriete == pref.valeur)
                    if (param) data.appartement = param.objet.valeur
                    break;
                case "etu_FiltreNiveau":
                    param = element.value.vkParam.find(x => x.propriete == pref.valeur)
                    if (param) data.niveau = param.objet.valeur
                    break;
                case "etu_FiltrePiece":
                    param = element.value.vkParam.find(x => x.propriete == pref.valeur)
                    if (param) data.piece = param.objet.valeur
                    break;
            }
        })
    }
    return data;
}

export type LigneCaoData = {
    nom: string;
    batiment: string;
    etage: string;
    appartement: string;
    etat: any;
    calque: any;
    identifiant: any;
    type: any;
    fonction: any;
    reference: any;
    hauteur: any;
    longueur: any;
    nombre: any;
    epaisseur: any;
    volume_brute: any;
    volume: any;
    surface: any;
    perimetre: any;
    classArchi: any;
    largeurPorte: any;
    zonePiece: any;
    etatRenovation: any;
    hauteurMoyenne: any;

    cadreFenetre: any;
    vitreFenetre: any;
    ownerCaoId: any;
    fromZoneCaoId: any;
    toZoneCaoId: any;

    a: any;
    b: any;

    longueurGauche: any;
    longueurDroite: any;
    surfaceDessous: any;
    surfaceDessus: any;
    surfaceBordGauche: any;
    surfaceBordDroit: any;
    surfaceBord: any;

    nbTrous: any;
    trouHauteur: any;
    trouLargeur: any;
    trouSurface: any;
    trouBordSurface: any;
    trouVolume: any;
}