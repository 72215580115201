
import { createPopper } from "@/plugins/popper";
import { useAuthStore } from "@/stores";
import { useAOStore } from "@/pages/ao/store";
import { storeToRefs } from "pinia";
import { defineComponent, nextTick, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { BInputSwitch, BBtn, BDropdown } from "@/components/global";
import { User } from "@/components/global/icons";

export default defineComponent({
  name: "account-popover",
  setup() {
    let popperInstance = null;

    const show = ref(false);

    const userTriggerEl = ref();
    const userTargetEl = ref();

    const aoStore = useAOStore();
    const authStore = useAuthStore();

    const { darkMode } = storeToRefs(aoStore);
    const { user } = storeToRefs(authStore);

    const router = useRouter();

    const logout = () => {
      authStore.logout();
      router.push({ name: "login" });
    };

    watch(
      () => show.value,
      (newValue) => {
        if (newValue) {
          nextTick(() => {
            popperInstance = createPopper(
              userTriggerEl.value,
              userTargetEl.value,
              {
                placement: "bottom",
              }
            );
          });
        } else if (popperInstance) {
          setTimeout(() => popperInstance.destroy(), 250);
        }
      }
    );

    return {
      show,

      userTriggerEl,
      userTargetEl,

      user,
      darkMode,

      logout,
    };
  },
  components: {
    BInputSwitch,
    BBtn,
    User,
    BDropdown,
  },
});
