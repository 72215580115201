import { createApp } from 'vue'

import App from './App.vue'
import router from './router';

import { createPinia } from 'pinia';
import { useAuthStore } from '@/stores/auth';

import '@/assets/index.css';
import 'splitpanes/dist/splitpanes.css'

const pinia = createPinia();


const app = createApp(App)
  .use(pinia)
  .use(router)

  app.config.globalProperties['']

router.isReady().then(() => {
  app.mount('#app');
});


// GARD
router.beforeEach(async (to, from, next) => {
  if (to.name == "login") {
    next()
  } else {
    const authStore = useAuthStore();
    if (authStore.token && await authStore.verifAuthentification()) {
      next();
    } else {
      if (await authStore.authUserLocal()) {
        next();
      } else {
        next({
          name: 'login',
          query: {
            redirect: to.fullPath
          }
        })
      }

    }
  }
})