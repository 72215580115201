import { getOwnerDocument } from '@/utils'
import { watchEffect, ComputedRef } from 'vue'

type AcceptNode = (
    node: HTMLElement
) =>
    | typeof NodeFilter.FILTER_ACCEPT
    | typeof NodeFilter.FILTER_SKIP
    | typeof NodeFilter.FILTER_REJECT


type Parameters = {
    container: ComputedRef<HTMLElement | null>
    accept: AcceptNode
    walk(node: HTMLElement): void
    enabled?: ComputedRef<boolean>
}


export function useTreeWalker({
    container,
    accept,
    walk,
    enabled,
}: Parameters) {
    watchEffect(() => {
        const root = container.value
        if (!root) return
        if (enabled !== undefined && !enabled.value) return
        const ownerDocument = getOwnerDocument(container)
        if (!ownerDocument) return

        const acceptNode = Object.assign((node: HTMLElement) => accept(node), { acceptNode: accept })
        const walker = ownerDocument.createTreeWalker(
            root,
            NodeFilter.SHOW_ELEMENT,
            acceptNode
        )

        while (walker.nextNode()) walk(walker.currentNode as HTMLElement)
    })
}