import { createApp } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "./router";
import dayjs from "dayjs";
import Toast, { PluginOptions } from "vue-toastification";

import "vue-toastification/dist/index.css";
import "@/assets/index.css";

import "dayjs/locale/fr";

import registerRules from "@/rules";

import "./guard";
import { setAppName } from "@/utils";
import { Collapse } from "vue-collapsed";

const toastOption: PluginOptions = {
  toastClassName: ["bimoffice", "group"],
};

const pinia = createPinia();

const app = createApp(App).use(pinia).use(router).use(Toast, toastOption);

setAppName(app, "ao");

app.component("CustomCollapse", Collapse);

dayjs.locale("fr");

registerRules();
router.isReady().then(() => {
  app.mount("#app");
});
