import { ILocalisationAccess } from "@/pages/bureau/@types/localisation";

const localisationAuth: ILocalisationAccess = {
  main: {
    errorAccess: "Accès refusé",
    errorAccessMessage: `Vous n'avez pas les droits nécessaires pour accéder à cette page.
    
      Veuillez réactualiser la page si vous pensez qu'il s'agit d'une erreur.
      Si le problème persiste, veuillez contacter votre administrateur.`,
  },
};

export default localisationAuth;
