import { ILocalisationAuth } from "@/pages/bureau/@types/localisation";

const localisationAuth: ILocalisationAuth = {
  login: {
    welcom: "Ça BIM dans votre mobile",
    loading: "Chargement...",

    societe: "Societé",
    email: "Identifiant",
    password: "Mot de passe",
    rememberMe: "Se souvenir de moi",
    submit: "Connexion",
  },
};

export default localisationAuth;
