export interface IFichierCao {
    uid: string;
    uidEtude: string;

    nom: string;

    maquettes: IFichierCaoMaquette[];

    classifications?: any;
    filtres?: any;
    niveau?: any;

    projet?: IFichierCaoProjet;
    consultation?: IFichierCaoConsultation;
    preferences?: IFichierCaoPreference[];
}

export interface ILigneCao {
    uid: string;
    uidCategorie: string;

    idCao: string;
    idIfc: string;
    nomParametre: string;

    value: {
        vkParam: {
            propriete: string;
            objet: any;
        }[]
    };

    /**
     * Pour les localisations (uniquement dans le cas des ouvrages)
     */
    valeur?: number;
    /**
  * Pour les localisations (uniquement dans le cas des ouvrages)
  */
     compositions?: string[]

}

export interface ILigneCaoQuantite {
    uid: string;
    nom: string;
    nomCao: string;
    unite: string;
    valeur: string;
    couleur: string;
    theme: string;
    themeOrdre: number
}

export interface ILigneCaoLien {
    uid: string;
    idIfc: string;
    idCao: string;
    nom: string;
    type: string;
}

export interface IOuvrageCao {
    uid: string;
    uidPere: string;

    uidBordereau: string;
    nomBordereau: string;
    codeBordereau: string;

    uidLot: string;
    codeLot: string;
    nomLot: string;

    code: string;
    codeCao: string;

    estArticle: boolean;
    estGeneralite: boolean;

    quantite: number;
    unite: string;
    designation: string;
    descriptif: string;
    annotation: string;
    ratio: number;
    quantiteLocal: number;

    ouvrages?: IOuvrageCao[]

}

export interface IFichierCaoMaquette {
    uid: string;
    uidDoc: string;
    uidFichier: string;
    indexXkt: number;
    indexJson: number;
}

export interface IFichierCaoProjet {
    uid: string;
    index: number;
    nom: string;
    code: string;
}

export interface IFichierCaoConsultation {
    uid: string;
    nom: string;
    uidDocProj: string;
}

export interface IFichierCaoNiveau {
    Stories?: Partial<IFichierCaoStorie>[];
}

export interface IFichierCaoStorie {
    DispOnSections: string;
    FloorId: number;
    Index: number;
    Level: number;
    Name: string;
    NotMine: string;

    vkAppart: any[];
    vkBatiment: any[];
    vkPiece: any[];
    vkPath: any[];

    vtSVG?: string;
}

export interface ILotsCao {
    uid: string;
    nom: string;
    code: string;

    bordereaux: IBordereauCao[]
}

export interface IBordereauCao {
    uid: string;
    code: string;
    nom: string;

    ouvrages: IOuvrageCao[];
}

export interface IFichierCaoPreference {
    nom: FichierCaoPrefs;
    valeur: string;
}

export enum FichierCaoPrefs {
    resetAnalyse = "cao_ResetAnalyse",
    tousElemsAnaluse = "cao_TousElemsAnalyse",
    decouvrirAnalyse = "cao_DecouvrirAnalyse",
    planArchicadAnalyse = "cao_PlanArchicadAnalyse",
    nonCollectionAnalyse = "cao_NonCollecteValeurAnalyse",

    filtreExclure = "etu_FiltreExclure",
    filtreCategorie = "etu_FiltreCategorie",
    filtreBatiment = "etu_FiltreBatiment",
    filtreNiveau = "etu_FiltreNiveau",
    filtreEntite = "etu_FiltreEntite",
    filtrePiece = "etu_FiltrePiece",
    filtreLocalisation = "etu_FiltreLocalisation"
}