import { App, getCurrentInstance } from "vue";

export const APP_NAME_PROPERTY = "appName";
export type AppName = "ao" | "preview";

export const setAppName = (app: App, appName: AppName): void => {
  if (app) {
    app.provide(APP_NAME_PROPERTY, appName);
    app.config.globalProperties[APP_NAME_PROPERTY] = appName;
  }
};

export const getAppName = (app: App): AppName => {
  if (app) {
    return app.config.globalProperties[APP_NAME_PROPERTY];
  }
};

export const currentAppName = (): AppName => {
  const c = getCurrentInstance();
  if (c) {
    return c.appContext?.config?.globalProperties[APP_NAME_PROPERTY];
  }
};

export const isCurrentAppPreview = (): boolean => {
  return currentAppName() == "preview";
};

export const isAppPreview = (app: App): boolean => {
  return app?.config?.globalProperties[APP_NAME_PROPERTY] == "preview";
};
