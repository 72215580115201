import {
  VuexModule,
  Module,
  Action,
  Mutation,
  getModule,
} from "vuex-module-decorators";
import store from "@/pages/bureau/store";
import { societesService } from "@/pages/bureau/api/societes.api";
import { getUrlParams, isPreviewInUrl, isValidStatus } from "@/utils";
import {
  ISociete,
  ISocietesState,
  IUser,
} from "@/pages/bureau/@models/societe.model";
import { UserModule } from "./user.module";
import { colorIsLight, darkenIfTooLight, hex2rgb } from "@/utils/colors";
import prefsTemp, { IPrefsSociete } from "@/pages/bureau/store-temp/prefs.temp";
import {
  fetchPolices,
  getPolicesArrayInBalise,
} from "@/pages/bureau/utils/polices";
import router from "@/pages/ao/router";



@Module({ dynamic: true, store, name: "societes", namespaced: true })
class Societes extends VuexModule implements ISocietesState {
  public societes: ISociete[] = [];
  public users: { [index: string]: IUser } = {};

  @Mutation
  private SET_SOCIETES(societes: ISociete[]) {
    this.societes = societes;
  }

  @Mutation
  private CLEAR_SOCIETES() {
    this.societes = [];
  }

  @Mutation
  private SET_USERS(users: { [index: string]: IUser }) {
    this.users = users;
  }

  @Mutation
  private MERGE_USERS(users: IUser[]) {
    users.map((u) =>
      this.users[u.index]
        ? Object.assign(this.users[u.index], u)
        : (this.users[u.index] = u)
    );
  }

  @Mutation
  private CLEAR_USERS() {
    this.users = {};
  }

  @Mutation
  private SET_SOCIETE_PREFS(prefs: IPrefsSociete) {
    try {
      prefsTemp.societe = prefs;
      document.documentElement.style.setProperty(
        "--color-primary",
        hex2rgb(prefs.couleurPrincipale).join(" ")
      );
      document.documentElement.style.setProperty(
        "--color-primary-light",
        hex2rgb(prefs.couleurSecondaire).join(" ")
      );
      if (colorIsLight(hex2rgb(prefs.couleurPrincipale))) {
        document.documentElement.style.setProperty(
          "--color-primary-auto",
          "99 104 122"
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  @Action({ rawError: true })
  public async getSocietePrefs() {
    let societeIndex = UserModule?.societe?.index;
    while (!societeIndex) {
      await new Promise((resolve) => setTimeout(resolve, 300));
      societeIndex = UserModule?.societe?.index;
    }

    if (isPreviewInUrl()) {
      try {
        const _societeIndex = parseInt(
          getUrlParams().find((p) => p[0] === "societe")?.[1]
        );
        const societe = SocietesModule.societes.find(
          (s) => s.index === _societeIndex
        );
        if (societe) {
          societeIndex = societe.index;
        } else {
          throw Error("Societe non trouvée");
        }
      } catch (error) {
        //
      }
    }
    const { status, data } = await societesService.getSocietePrefs(
      societeIndex
    );
    fetchPolices(getPolicesArrayInBalise(data.prefs.nomSociete || ""));
    if (!isValidStatus(status)) {
      throw Error("La récupération des préférences de la société a échoué.");
    }
    this.SET_SOCIETE_PREFS(data?.prefs);
  }

  /**
   * Recupère les societes depuis l'API
   */
  @Action({ rawError: true })
  public async fetchSocietes() {
    const { status, data } = await societesService.getSocietes();
    if (!isValidStatus(status)) {
      this.CLEAR_SOCIETES;
      throw Error("La récupération des sociétés a échoué.");
    }
    if (!data || !(data.societes && data.societes.length)) {
      this.CLEAR_SOCIETES;
      throw Error("Aucunes sociétés trouvées.");
    }
    this.SET_SOCIETES(data.societes);
  }

  /**
   * Recupère les societes depuis l'API
   */
  @Action({ rawError: true })
  public async fetchUsers() {
    const { status, data } = await societesService.getUsers();
    if (!isValidStatus(status)) {
      throw Error("La récupération des sociétés a échoué.");
    }

    this.MERGE_USERS(data.users);
    return data.users;
  }

  /**
   * Retourne les societes dans l'ordre des index
   */
  get orderedSocietes() {
    if (this.societes) return this.societes.sort((x, y) => x.index - y.index);
    return [];
  }

  @Action({ rawError: true })
  public async saveFile(file: File) {
    const formData = new FormData();
    formData.append("file", file);

    const { status, data } = await societesService.saveFile(formData);
    if (!isValidStatus(status)) {
      return null;
    } else {
      return data;
    }
  }
}

export const SocietesModule = getModule(Societes);
