
import { defineComponent, ref, computed } from "vue";
import { useViewerStore } from "@/stores/viewer";
import {
  FitAll,
  FitSelection,
  Xray,
  Hide,
  HideBis,
  Inverser,
  Stair,
  Layer,
  Setting,
  ChevronUp,
} from "@/components/global/icons";
import BIconFilter from "@/components/global/icons/BIconFilter.vue";
import BIconSection from "@/components/global/icons/BIconSection.vue";
import { hasElementById } from "@/utils";
import DropdownViewerHidden from "@/components/dropdowns/DropdownViewerHidden.vue";
import DropdownViewerEtagesFiltre from "@/components/dropdowns/DropdownViewerEtagesFiltre.vue";
import DropdownViewerCalquesFiltre from "@/components/dropdowns/DropdownViewerCalquesFiltre.vue";
import DropdownViewerTypesFiltre from "@/components/dropdowns/DropdownViewerTypesFiltre.vue";
import DropdownViewerParametres from "@/components/dropdowns/DropdownViewerParametres.vue";
import DropdownViewerSections from "@/components/dropdowns/DropdownViewerSections.vue";
import { useViewer } from "@/composables/viewer";
import { ripple } from "@/directives";

export default defineComponent({
  directives: {
    ripple,
  },
  setup() {
    const toolbarEl = ref<HTMLElement>(null);

    const viewerContext = useViewer();

    const viewerStore = useViewerStore();
    const isExpended = computed(() => !viewerStore.collapseToolbar);
    const xray = computed(() => viewerStore.xray);
    const reverse = computed(() => viewerStore.reverse);
    const hideOnClick = computed(() => viewerStore.hideOnClick);

    const { toggleToolbar, toggleXray, toggleHideOnClick, toggleReverse } =
      viewerStore;

    const onWheel = (event) => {
      if (
        !hasElementById("dropdownFloors", event.target) &&
        !hasElementById("dropdownLayers", event.target) &&
        !hasElementById("dropdownTypes", event.target) &&
        !hasElementById("dropdownHidden", event.target) &&
        !hasElementById("dropdownParameters", event.target) &&
        !hasElementById("dropdownSections", event.target)
      ) {
        event.preventDefault();
        toolbarEl.value.scrollLeft += event.deltaY;
      }
    };
    return {
      isReady: viewerContext.isReady,

      onFitAllClick: viewerContext.fitAll,
      onFitSelectionClick: viewerContext.fitSelection,

      toolbarEl,

      isExpended,
      reverse,
      xray,
      hideOnClick,

      toggleXray,
      toggleToolbar,
      toggleHideOnClick,
      toggleReverse,

      onWheel,
    };
  },
  components: {
    DropdownViewerEtagesFiltre,
    DropdownViewerHidden,
    DropdownViewerCalquesFiltre,
    DropdownViewerTypesFiltre,
    DropdownViewerParametres,
    DropdownViewerSections,

    FitAll,
    FitSelection,
    Xray,
    Hide,
    HideBis,
    BIconFilter,
    Inverser,
    Stair,
    Layer,
    Setting,
    BIconSection,
    ChevronUp,
  },
});
