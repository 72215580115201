
import { IFichierDce } from '@/@models/fichier'
import { consultationsService } from '@/api/consultations';
import { checkData, isValidStatus } from '@/utils';
import { defineStore } from 'pinia'
import { useToast } from 'vue-toastification';

const toastification = useToast();

export const useFichiersDceStore = defineStore('fichiers-dce', {
    state: () => {
        return {
            fichiers: [] as IFichierDce[],
            error: null
        }
    },
    actions: {
        /**
        * Recupere la documentation DCE d'une consultation
        * @returns
        */
         async fetchDce(payload: string) {
            const { status, data } = await consultationsService.getDocumentsDCE(payload);
            if (!isValidStatus(status) || !checkData(data)) {
                this.fichiers = [];
                toastification.error("La récupération de la documentation DCE a échoué.")
                return false;
            }
            this.fichiers = data.documents
            return true;
        },
    }
})