import { ILocalisationFil } from "@/pages/bureau/@types/localisation";

const localisationFil: ILocalisationFil = {
  categories: {
    title: "Fil de discussion",
  },
  conversation: {
    title: "Conversation",
  },
  form: {
    addInterne: "Créer groupe interne",
    addProjet: "Créer groupe projet",
  },
};

export default localisationFil;
