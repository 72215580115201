import { ILocalisationPointages } from "@/pages/bureau/@types/localisation";

const localisationPointages: ILocalisationPointages = {
  list: {
    title: "Pointages",
  },
  detail: {
    title: "Détail",
    date: "Date",
    hours: "Nombre d'heures",
    affectation: "Affectation",
    comment: "Commentaire",
    noComment: "Aucun commentaire",
  },
  stats: {
    title: "Statistiques",
  },
  form: {
    new: "Nouveau pointage",
    edit: "Modification du pointage",
    date: "Date",
    hours: "Nombre d'heures",
    affectation: "Affectation",
    comment: "Commentaire",
    noComment: "Aucun commentaire",
  },
};

export default localisationPointages;
