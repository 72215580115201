
import { defineComponent, PropType } from "vue";
import BaseIcon from "./BaseIcon.vue"
import { IconType, SizeType } from "../index";


const name: IconType = "group";

export default defineComponent({
    name: "b-icon-" + name,
    components: {
        BaseIcon
    },
    props: {
        size: {
            type: String as PropType<SizeType>,
            default: () => "md",
        },
    },
    setup() {
        return {
            name
        }
    }
});
