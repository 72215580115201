import {
  popperGenerator,
  defaultModifiers,
} from '@popperjs/core/lib/popper-lite';
import flip from '@popperjs/core/lib/modifiers/flip';
import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow';
import offset from '@popperjs/core/lib/modifiers/offset';
import arrow from '@popperjs/core/lib/modifiers/arrow';

export const createPopper = popperGenerator({
  defaultModifiers: [...defaultModifiers, flip, preventOverflow, offset, arrow],
});

export const getTriggerEvents = (trigger: "hover" | "click" = "hover") => {
  switch (trigger) {
    case 'hover':
      return {
        showEvents: ['mouseenter', 'focus'],
        hideEvents: ['mouseleave', 'blur']
      }
    case 'click':
      return {
        showEvents: ['click', 'focus'],
        hideEvents: ['focusout', 'blur']
      }
    default:
      return {
        showEvents: ['mouseenter', 'focus'],
        hideEvents: ['mouseleave', 'blur']
      }
  }
}