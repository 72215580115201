import {
  IIntervenant,
  IIntervenantContact,
  IIntervenantDocument,
  IIntervenantHistoOffre,
} from "@/@models/intervenants";
import { consultationsService } from "@/api/consultations";
import { checkData, isValidStatus } from "@/utils";
import { defineStore } from "pinia";
import { useToast } from "vue-toastification";

const toastification = useToast();

/**
 * Type authentification: Intervenant
 * Pour l'espace entrerprise de l'Appel d'offre
 */
export const useEntrepriseStore = defineStore("entreprise", {
  state: () => {
    return {
      intervenant: null as IIntervenant,
      contacts: [] as IIntervenantContact[],
      documents: [] as IIntervenantDocument[],
      offres: [] as IIntervenantHistoOffre[],
    };
  },
  getters: {
    sortedDocuments(state) {
      return state.documents.sort((a, b) => b?.date.localeCompare(a?.date));
    },
    sortedOffres(state) {
      return state.offres.sort((x, y) => {
        return y?.dateTransmis?.localeCompare(x?.dateTransmis);
      });
    },
  },
  actions: {
    /**
     * Recupere l'intervenant lié au token
     * @returns
     */
    async fetchIntervenant() {
      const { status, data } = await consultationsService.getIntervenant();
      if (!isValidStatus(status) || !data || !data.intervenant) {
        this.intervenant = null;
        toastification.error("La récupération de l'intervenant a échoué.");
        return false;
      }
      this.intervenant = data.intervenant;
      return true;
    },

    /**
     * Recupere les contacts de l'intervenant lié au token
     * @returns
     */
    async fetchContacts() {
      const { status, data } = await consultationsService.getContacts();
      if (!isValidStatus(status) || !checkData(data)) {
        this.contacts = [];
        toastification.error("La récupération des contacts a échoué.");
        return false;
      }
      this.contacts = data.contacts;
      return true;
    },

    /**
     * Recupere les documents de l'intervenant
     * @returns
     */
    async fetchDocuments() {
      const { status, data } = await consultationsService.getDocuments();
      if (!isValidStatus(status) || !checkData(data)) {
        this.documents = [];
        toastification.error("La récupération des documents a échoué.");
        return false;
      }
      this.documents = data.documents;
      return true;
    },

    /**
     * Met à jour les informations de l'intervenant
     * @param payload
     * @returns
     */
    async putIntervenant(payload) {
      const { status, data } = await consultationsService.putIntervenant(
        payload
      );
      if (!isValidStatus(status) || !checkData(data)) {
        toastification.error("La modification de l'intervenant a échoué.");
        return false;
      }
      for (const key in this.intervenant)
        this.intervenant[key] = payload[key]
          ? payload[key]
          : this.intervenant[key];
      toastification.success("Modification réussi !");
      return true;
    },

    /**
     * Met à jour les informations d'un contact
     * @param payload
     * @returns
     */
    async postContact(index_contact, payload) {
      const { status, data } = await consultationsService.postContact(
        index_contact,
        payload
      );
      if (!isValidStatus(status) || !checkData(data)) {
        toastification.error("La modification du contact a échoué.");
        return false;
      }
      const contact = this.contacts.find((x) => (x.index = index_contact));
      if (contact) {
        for (const key in contact)
          contact[key] = payload[key] ? payload[key] : contact[key];
      }
      toastification.success("Modification réussi !");
      return true;
    },

    /**
     * Ajout un document
     * @param payload
     * @returns
     */
    async postDocument(fichier, fichierInfo) {
      const { status } = await consultationsService.postDocumentInter(
        fichierInfo,
        fichier
      );
      if (!isValidStatus(status)) {
        toastification.error("L'ajout du document a échoué");
        return false;
      }
      toastification.success("Document ajouté !");
      return true;
    },

    /**
     * Ajout un document
     * @param payload
     * @returns
     */
    async putDocument(uidDoc, docData, newFile?) {
      const { status } = await consultationsService.putDocumentInter(
        uidDoc,
        docData,
        newFile
      );
      if (!isValidStatus(status)) {
        toastification.error("La modification du document a échoué");
        return false;
      }
      toastification.success("Document modifié !");
      return true;
    },

    /**
     * Supprime un document
     * @param payload
     * @returns
     */
    async deleteDocument(uidDoc) {
      const { status } = await consultationsService.deleteDocumentInter(uidDoc);
      if (!isValidStatus(status)) {
        toastification.error("La suppression du document a échoué");
        return false;
      }
      const index = this.documents.findIndex((d) => d.uid == uidDoc);
      if (index > -1) {
        this.documents.splice(index, 1);
        toastification.success("Document supprimé !");
      }
      return true;
    },

    async fetchOffres() {
      const { status, data } =
        await consultationsService.getIntervenantHistoriqueOffres();
      if (!isValidStatus(status) || !checkData(data)) {
        toastification.error(
          "La récupération de l'historique des offres a échoué"
        );
        return false;
      }
      this.offres = data.offres;
      return true;
    },
  },
});
