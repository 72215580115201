import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex h-screen w-screen flex-auto justify-center bg-background text-base md:p-4 mobile:max-h-none mobile:min-h-320" }
const _hoisted_2 = { class: "relative flex flex-auto flex-col items-stretch overflow-x-auto overflow-y-hidden bg-menu antialiased shadow-2xl dark:shadow-none sm:max-w-320 sm:rounded-xl mobile:my-0 mobile:sm:m-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Sidepanel = _resolveComponent("Sidepanel")!
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_Router_view = _resolveComponent("Router-view")!
  const _component_AppMain = _resolveComponent("AppMain")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isReady)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_Sidepanel, {
            class: "absolute",
            preview: _ctx.preview
          }, null, 8, ["preview"]),
          _createElementVNode("main", _hoisted_2, [
            _createVNode(_component_Navbar, {
              class: "w-full",
              preview: _ctx.preview
            }, null, 8, ["preview"]),
            _createVNode(_component_AppMain, null, {
              default: _withCtx(() => [
                (_ctx.preview)
                  ? _renderSlot(_ctx.$slots, "default", { key: 0 })
                  : (_openBlock(), _createBlock(_component_Router_view, { key: 1 }))
              ]),
              _: 3
            })
          ])
        ], 64))
      : _createCommentVNode("", true)
  ]))
}