import { useFichiersCaoStore, useViewerFiltresStore, useViewerSectionsStore } from "@/stores/viewer";
import { nextTick, reactive, ref, readonly } from "vue";
import { useViewer, useViewerSectionPlugin } from "@/composables/viewer";

/**
 * Indique le nombre total d'étape au chargment du viewer
 */
const maxSteps = ref<number>(0);

const primarySteps = ref<Partial<StepInfo>[]>([]);
const secondarySteps = ref<Partial<StepInfo>[]>([]);

const hasError = ref<boolean>(false);

/**
 * Pour charger le viewer IFC et récupérer la maquettes et ses filtres
 */
export function useViewerLoader(idFrame: string, idFichier, idDoc) {

    let index = 0;

    const isFinish = ref(false);

    const viewerContext = useViewer();
    const viewerSectionContext = useViewerSectionPlugin();

    const viewerFiltresStore = useViewerFiltresStore();
    const viewerSectionsStore = useViewerSectionsStore();
    const fichierCaoStore = useFichiersCaoStore();


    const configFrameStep = reactive<StepInfo>({
        index: index++,
        name: "Préparation du Viewer IFC",
        errorMessage: "Le viewer n'a pas pu être initialisé.",
        state: "waiting",
        action: async () => {
            configFrameStep.state = "pending"
            let viewerFrame = document.getElementById(idFrame);
            if (!viewerFrame) {
                await nextTick();
                viewerFrame = document.getElementById(idFrame);
            }

            if (viewerFrame) {
                await viewerContext.initialise(viewerFrame as HTMLIFrameElement);

                configFrameStep.state = "done";
                return true;
            }

            configFrameStep.state = "echec";
            return false;
        }
    })

    const loadFichierCaoStep = reactive<StepInfo>({
        index: index++,
        name: "Récupération du context de CAO",
        errorMessage: "Le context de CAO n'a pas pu être recupéré.",
        state: "waiting",
        action: async () => {
            loadFichierCaoStep.state = "pending"
            if (await fichierCaoStore.fetchFichierCao(idFichier) == true) {
                loadFichierCaoStep.state = "done";
                return true;
            }

            loadFichierCaoStep.state = "echec";
            return false;
        }
    })

    const loadFichierXktStep = reactive<StepInfo>({
        index: index++,
        name: "Récupération du fichier XKT",
        errorMessage: "Le fichier XKT n'a pas pu être recupéré.",
        state: "waiting",
        action: async () => {
            loadFichierXktStep.state = "pending"
            if (await fichierCaoStore.fetchFichierXkt(
                idFichier,
                idDoc
            )) {
                loadFichierXktStep.state = "done";
                return true;
            }

            loadFichierXktStep.state = "echec";
            return false;
        }
    })

    const loadFichierJsonStep = reactive<StepInfo>({
        index: index++,
        name: "Récupération du fichier JSON",
        errorMessage: "Le fichier JSON n'a pas pu être recupéré.",
        state: "waiting",
        action: async () => {
            loadFichierJsonStep.state = "pending"
            if (await fichierCaoStore.fetchFichierJson(
                idFichier,
                idDoc
            )) {
                loadFichierJsonStep.state = "done";
                return true;
            }

            loadFichierJsonStep.state = "echec";
            return false;
        }
    })

    const loadMaquetteStep = reactive<StepInfo>({
        index: index++,
        name: "Chargement de la maquette",
        errorMessage: "Echec du chargement de la maquette.",
        state: "waiting",
        action: async () => {
            loadMaquetteStep.state = "pending"
            if (await viewerContext.loadMaquette(
                fichierCaoStore.fichierXktUrl,
                fichierCaoStore.fichierJsonUrl
            )) {
                loadMaquetteStep.state = "done";
                return true;
            }

            loadMaquetteStep.state = "echec";
            return false;
        }
    })

    const loadLotsStep = reactive<StepInfo>({
        index: index++,
        name: "Récupération des lots et ouvrages",
        errorMessage: "Echec du chargement des lots.",
        state: "waiting",
        action: async () => {
            loadLotsStep.state = "pending"
            if (await fichierCaoStore.fetchLots(idFichier)) {
                loadLotsStep.state = "done";
                return true;
            }

            loadLotsStep.state = "echec";
            return false;
        }
    })

    const loadZonesStep = reactive<StepInfo>({
        index: index++,
        name: "Récupération des zones",
        errorMessage: "Echec du chargement des zones.",
        state: "waiting",
        action: async () => {
            loadZonesStep.state = "pending"
            if (await fichierCaoStore.fetchZonesIfc(idFichier)) {
                loadZonesStep.state = "done";
                return true;
            }

            loadZonesStep.state = "echec";
            return false;
        }
    })

    const loadCalquesStep = reactive<StepInfo>({
        index: index++,
        name: "Récupération des calques",
        errorMessage: "Echec du chargement des calques.",
        state: "waiting",
        action: async () => {
            loadCalquesStep.state = "pending"
            if (await fichierCaoStore.fetchCalques(idFichier)) {
                loadCalquesStep.state = "done";
                return true;
            }

            loadCalquesStep.state = "echec";
            return false;
        }
    })

    const loadFilterStep = reactive<StepInfo>({
        index: index++,
        name: "Récupération des filtres",
        errorMessage: "Echec de l'application des filtres.",
        state: "waiting",
        action: async () => {
            loadFilterStep.state = "pending"
            try {
                viewerFiltresStore.fetchFiltersFromLocalStorage(idDoc || idFichier);
            } catch {
                loadFilterStep.state = "echec"
                return false;
            }

            loadFilterStep.state = "done"
            return true;
        }
    })

    const loadSectionsStep = reactive<StepInfo>({
        index: index++,
        name: "Récupération des sections",
        errorMessage: "Echec de la récupération des sections.",
        state: "waiting",
        action: async () => {
            loadSectionsStep.state = "pending"
            try {
                viewerSectionsStore.fetchSectionsFromLocalStorage(idDoc || idFichier);
            } catch {
                loadSectionsStep.state = "echec"
                return false;
            }

            loadSectionsStep.state = "done"
            return true;
        }
    })

    // Attention l'ordre des étapes est important
    primarySteps.value = [
        loadFichierCaoStep,
        loadFichierXktStep,
        loadFichierJsonStep,
        loadMaquetteStep
    ];

    secondarySteps.value = [
        loadLotsStep,
        loadZonesStep,
        loadCalquesStep,
        loadFilterStep,
        loadSectionsStep
    ];

    maxSteps.value = primarySteps.value.length + secondarySteps.value.length

    const executePrimarySteps = async () => {
        for (const step of primarySteps.value) {
            await step.action();
        }
        viewerContext.isReady.value = true;
    }

    const executeSecondarySteps = async () => {
        await Promise.all(secondarySteps.value.map(s => s.action()))
    }

    const initialise = async () => {
        isFinish.value = false;
        hasError.value = false;

        const primaryStepsPromise = executePrimarySteps()
        const secondaryStepsPromise = executeSecondarySteps()

        await configFrameStep.action();
        await Promise.all([primaryStepsPromise, secondaryStepsPromise])

        // On prépare la premiere vu
        viewerContext.manageView();
        viewerSectionsStore.sections.forEach(s => viewerSectionContext.addSection(s))

        hasError.value = primarySteps.value.some(s => s.state == 'echec') || secondarySteps.value.some(s => s.state == 'echec')
        isFinish.value = true;

        if (!hasError.value) {
            viewerContext.isLoaded.value = true;
        }

        return !hasError.value
    }

    return {
        initialise,

        isFinish,
        hasError
    }
}

/**
 * Pour récuperer des informations sur le chargement du Viewer
 */
export function useViewerLoaderInfo() {


    return {
        hasError: readonly(hasError),
        maxSteps: readonly(maxSteps),
        primarySteps: readonly(primarySteps),
        secondarySteps: readonly(secondarySteps),
    }
}


type StepInfo = {
    index: number;
    name?: string;
    message?: string;
    state?: "waiting" | "pending" | "done" | "echec";
    errorMessage?: string;

    action: () => (Promise<boolean> | boolean)

    // Pour le téléchargement
    isDownload?: boolean;
    downloaded?: string;
    downloadSize?: string;
    downloadSpeed?: string;
    timeLeft?: string;
}