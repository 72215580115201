import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "mainContent",
  class: "flex flex-1 flex-col overflow-auto sm:border-l-8 sm:border-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _renderSlot(_ctx.$slots, "default")
  ]))
}