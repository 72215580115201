import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "value", "disabled", "type", "placeholder"]
const _hoisted_3 = { class: "inline-flex w-full items-center text-xs" }
const _hoisted_4 = { class: "flex-grow text-left text-danger" }
const _hoisted_5 = { class: "flex-grow text-right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_icon = _resolveComponent("b-icon")!
  const _component_base_input = _resolveComponent("base-input")!

  return (_openBlock(), _createBlock(_component_base_input, {
    variant: _ctx.variant,
    block: _ctx.block,
    disabled: _ctx.disabled,
    icon: _ctx.icon,
    outlined: _ctx.outlined,
    shadow: _ctx.shadow,
    "show-focus": _ctx.showFocus,
    onPrepend: _cache[1] || (_cache[1] = ($event: any) => (_ctx.inputRef.focus())),
    onAppend: _cache[2] || (_cache[2] = ($event: any) => (_ctx.inputRef.focus()))
  }, _createSlots({
    default: _withCtx(() => [
      _createElementVNode("textarea", _mergeProps(_ctx.$attrs, {
        class: "w-full flex-grow border-none bg-inherit py-2 px-1 focus:outline-none focus:ring-0",
        ref: "inputRef",
        id: _ctx.name,
        value: _ctx.modelValue,
        onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateValue && _ctx.updateValue(...args))),
        disabled: _ctx.disabled,
        type: _ctx.type,
        placeholder: (_ctx.$attrs.placeholder as string)
      }), null, 16, _hoisted_2)
    ]),
    _: 2
  }, [
    (_ctx.label)
      ? {
          name: "header",
          fn: _withCtx(() => [
            _createElementVNode("label", { for: _ctx.name }, _toDisplayString(_ctx.label), 9, _hoisted_1)
          ]),
          key: "0"
        }
      : undefined,
    (_ctx.icon)
      ? {
          name: "append",
          fn: _withCtx(() => [
            _createVNode(_component_b_icon, {
              class: "ml-2",
              name: _ctx.icon
            }, null, 8, ["name"])
          ]),
          key: "1"
        }
      : undefined,
    (_ctx.counter || _ctx.error)
      ? {
          name: "footer",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.error), 1),
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.counterText), 1)
            ])
          ]),
          key: "2"
        }
      : undefined
  ]), 1032, ["variant", "block", "disabled", "icon", "outlined", "shadow", "show-focus"]))
}