import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Teleport as _Teleport, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2309971c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "scene",
  class: "relative flex h-full w-full flex-col items-center justify-center"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "m-auto rounded-xl bg-element p-10 shadow-2xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BLoader = _resolveComponent("BLoader")!
  const _component_BOverlay = _resolveComponent("BOverlay")!
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_Pane = _resolveComponent("Pane")!

  return (_openBlock(), _createBlock(_component_Pane, {
    id: "mainPane",
    size: _ctx.size,
    onWheel: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onScroll($event))),
    class: "flex h-full flex-col overflow-auto antialiased"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(["transition-all before:transition-all after:transition-all", [
          {
            desktop: _ctx.device == _ctx.SimulationDevice.desktop,
          },
          {
            laptop: _ctx.device == _ctx.SimulationDevice.laptop,
          },
          {
            mobile: _ctx.device == _ctx.SimulationDevice.mobile,
          },
        ]])
        }, [
          _createElementVNode("iframe", {
            id: "preview",
            ref: "iframePreviewEl",
            class: "bg-white shadow-2xl drop-shadow-2xl transition-all",
            onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onLoad && _ctx.onLoad(...args))),
            src: _ctx.SCENE_URL,
            style: _normalizeStyle(_ctx.styleSceneComputed)
          }, null, 44, _hoisted_2)
        ], 2)
      ]),
      _createVNode(_component_BOverlay, { show: _ctx.loading }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_BLoader, { message: "Chargement..." })
          ])
        ]),
        _: 1
      }, 8, ["show"]),
      (_ctx.targetEl)
        ? (_openBlock(), _createBlock(_Teleport, {
            key: 0,
            to: _ctx.targetEl
          }, [
            _createVNode(_component_RouterView, { name: "view" }, {
              default: _withCtx(({ Component }) => [
                (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
                  class: _normalizeClass(["h-full w-full text-base", { dark: _ctx.isDark }])
                }, null, 8, ["class"]))
              ]),
              _: 1
            })
          ], 8, ["to"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["size"]))
}