import { render } from '@/utils/render'
import { defineComponent, PropType } from 'vue'

// CREDIT HEADLESS.UI
// https://github.com/tailwindlabs/headlessui/

export enum Features {
    // The default, no features.
    None = 1 << 0,

    // Whether the element should be focusable or not.
    Focusable = 1 << 1,

    // Whether it should be completely hidden, even to assistive technologies.
    Hidden = 1 << 2,
}

export const Hidden = defineComponent({
    name: 'bHidden',
    props: {
        as: { type: [Object, String], default: 'div' },
        features: { type: Number as PropType<Features>, default: Features.None },
    },
    setup(props, { slots, attrs }) {
        return () => {
            const { features, ...theirProps } = props
            const ourProps = {
                'aria-hidden': (features & Features.Focusable) === Features.Focusable ? true : undefined,
                style: {
                    position: 'fixed',
                    top: 1,
                    left: 1,
                    width: 1,
                    height: 0,
                    padding: 0,
                    margin: -1,
                    overflow: 'hidden',
                    clip: 'rect(0, 0, 0, 0)',
                    whiteSpace: 'nowrap',
                    borderWidth: '0',
                    ...((features & Features.Hidden) === Features.Hidden &&
                        !((features & Features.Focusable) === Features.Focusable) && { display: 'none' }),
                },
            }

            return render({
                ourProps,
                theirProps,
                slot: {},
                attrs,
                slots,
                name: 'Hidden',
            })
        }
    },
})