import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex w-full min-w-100 flex-grow md:min-w-180" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderbarViewerToolbar = _resolveComponent("HeaderbarViewerToolbar")!
  const _component_Viewer = _resolveComponent("Viewer")!
  const _component_Pane = _resolveComponent("Pane")!

  return (_openBlock(), _createBlock(_component_Pane, {
    size: _ctx.size,
    class: "flex flex-col bg-background sm:border-l-8 sm:border-primary"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_HeaderbarViewerToolbar),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_Viewer)
      ])
    ]),
    _: 1
  }, 8, ["size"]))
}