import { projetsService } from "@/api";
import {
  useProjet,
  useProjetLots,
  useProjetIntervenants,
  useProjetAdjudication,
} from "@/pages/ao/composables";
import { toBase64 } from "@/utils";
import { defineStore } from "pinia";
import { computed } from "vue";
import { useRoute } from "vue-router";

/**
 * Pour les pages projet
 */
export const useProjetStore = defineStore("projet", () => {
  const route = useRoute();

  const uidProjetMeta = computed(() => route.params.uid_projet as string);

  const {
    fetch: fetchProjet,
    fetchError: fetchErrorProjet,
    isFetched: isFetchedProjet,
    loading: loadingProjet,
    reset: resetProjet,

    projet,
    projetCode,
    projetNom,
  } = useProjet({
    uidProjet: uidProjetMeta,
  });

  const {
    fetch: fetchIntervenants,
    fetchError: fetchErrorIntervenants,
    isFetched: isFetchedIntervenants,
    loading: loadingIntervenants,
    reset: resetIntervenants,

    intervenants,
  } = useProjetIntervenants({
    uidProjet: uidProjetMeta,
  });

  const {
    fetch: fetchLots,
    fetchError: fetchErrorLots,
    isFetched: isFetchedLots,
    loading: loadingLots,
    reset: resetLots,

    lots,

    findLotByUid,
    getLotCodeNom,
  } = useProjetLots({
    uidProjet: uidProjetMeta,
  });

  const {
    fetch: fetchLotsAdjudication,
    fetchError: fetchErrorLotsAdjudication,
    isFetched: isFetchedLotsAdjudication,
    loading: loadingLotsAdjudication,
    reset: resetLotsAdjudication,

    lots: lotsAdjudication,

  } = useProjetAdjudication({
    uidProjet: uidProjetMeta,
  });

  const fetchAll = async () => {
    await Promise.all([fetchProjet(), fetchIntervenants(), fetchLots()]);
  };

  const findIntervenantByUidLot = (uidLot: string) => {
    return intervenants.value?.find((i) => i.uidLot == uidLot);
  };

  const fetchIntervenantLogo = async (indexInter: number) => {
    const intervenant = intervenants.value.find((i) => i.index == indexInter);
    if (intervenant) {
      try {
        const res = await projetsService.getProjetIntervenantLogo(
          uidProjetMeta.value,
          indexInter
        );
        if (res instanceof Blob) {
          intervenant.logo = await toBase64(res as any);
        } else {
          intervenant.logo = null;
        }
      } catch (err) {
        intervenant.logo = null;
      }
    }
  };

  /**
   * Réinitialise le store l'intégralité du store
   */
  const resetAll = () => {
    resetIntervenants();
    resetLots();
    resetProjet();
    resetLotsAdjudication();
  };

  return {
    uidProjetMeta,
    fetchAll,
    resetAll,

    // Projet
    projet,
    projetCode,
    projetNom,

    fetchErrorProjet,
    isFetchedProjet,
    loadingProjet,

    fetchProjet,
    resetProjet,

    // Intervenants
    intervenants,

    fetchErrorIntervenants,
    isFetchedIntervenants,
    loadingIntervenants,

    fetchIntervenants,
    findIntervenantByUidLot,
    fetchIntervenantLogo,
    resetIntervenants,

    // Lots
    lots,

    fetchErrorLots,
    isFetchedLots,
    loadingLots,

    fetchLots,
    resetLots,
    findLotByUid,
    getLotCodeNom,

    // Adjudication
    lotsAdjudication,

    fetchErrorLotsAdjudication,
    isFetchedLotsAdjudication,
    loadingLotsAdjudication,

    fetchLotsAdjudication,
    resetLotsAdjudication,
  };
});
