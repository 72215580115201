import { ComputedRef, inject, InjectionKey, Ref } from "vue";
import { ThemeType, VariantType } from "../type";

// Basé sur le composant RadioGroup de HeadlessUI
// https://github.com/tailwindlabs/headlessui/blob/main/packages/%40headlessui-vue/src/components/radio-group/radio-group.ts

// Basé sur le composant Segment de Ionic
// https://ionicframework.com/docs/api/segment

export interface Option {
    id: string;
    element: Ref<HTMLElement | null>;
    propsRef: Ref<{ value: unknown; disabled: boolean; }>;
}

export type VariantSegment = Exclude<
    VariantType,
    "danger" | "warning" | "success" | "light" | "dark" | "transparent"
>

export interface ThemeDefinition {
    enabled: boolean | ComputedRef<boolean>;
    name: ThemeType | ComputedRef<ThemeType>;
    variant: VariantSegment | ComputedRef<VariantSegment>;

    containerClass: string | string[] | ComputedRef<string | string[]>;
    selectorClass: string | string[] | ComputedRef<string | string[]>;

    selectorStyle: string | ComputedRef<string>;
}

export interface StateDefinition {
    // State
    options: Ref<Option[]>;
    value: Ref<unknown>;

    // Styling
    theme: ThemeDefinition;
    variant: ComputedRef<VariantType>;

    disabled: Ref<boolean>;
    firstOption: Ref<Option | undefined>;
    containsCheckedOption: Ref<boolean>;

    compare(a: unknown, z: unknown): boolean;

    // State mutators
    change(nextValue: unknown): boolean;
    registerOption(action: Option): void;
    unregisterOption(id: Option["id"]): void;
}

export enum OptionState {
    Empty = 1 << 0,
    Active = 1 << 1,
}

export function defaultComparator<T>(a: T, z: T): boolean {
    return a === z;
}

export const BSegmentContext = Symbol(
    "BSegmentContext"
) as InjectionKey<StateDefinition>;

export function useSegmentContext(component: string) {
    const context = inject(BSegmentContext, null);

    if (context === null) {
        const err = new Error(
            `<${component} /> is missing a parent <BSegment /> component.`
        );
        if (Error.captureStackTrace)
            Error.captureStackTrace(err, useSegmentContext);
        throw err;
    }

    return context;
}