import App from "./App.vue";

import { createApp } from "vue";
import { createPinia } from "pinia";

import router from "./router";

import "splitpanes/dist/splitpanes.css";
import "@/assets/index.css";
import { setAppName } from "@/utils";

const pinia = createPinia();
const app = createApp(App);

app.use(pinia);
app.use(router);

setAppName(app, "preview");

app.mount("#app");
