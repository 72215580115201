
import { IConsultation } from '@/@models/consultations'
import { consultationsService } from '@/api/consultations'
import { checkData, isValidStatus } from '@/utils'
import { defineStore } from 'pinia'
import { useToast } from 'vue-toastification'

const toastification = useToast();

/**
 * Type authentification: Intervenant
 */
export const useConsultationsStore = defineStore('consultations', {
    state: () => {
        return {
            consultations: [] as IConsultation[],
            error: null
        }
    },
    getters: {
        getConsultationByID(state) {
            return (uid) => state.consultations.find(c => c.uid == uid)
        }
    },
    actions: {
        /**
         * Recuperes les consultations
         * @returns
         */
        async fetchConsultations() {
            const { status, data } = await consultationsService.getConsultations();
            if (!isValidStatus(status) || !checkData(data)) {
                this.consultations = [];
                toastification.error("La récupération des consultations a échoué.")
                return false;
            }
            this.consultations = data.consultations
            return true;
        },
    }
})
