
import { useViewerStore } from "@/stores/viewer";
import {
  ChevronLeft,
  MenuClose,
  Search,
  Maquette,
  Bordereau,
} from "@/components/global/icons";
import { BOverlay } from "@/components/global";
import ViewerLoader from "@/components/viewer/ViewerLoader.vue";
import { defineComponent, computed, ref } from "vue";
import { Pane } from "splitpanes";
import {
  BListBox,
  BListBoxButton,
  BListBoxOption,
  BListBoxOptions,
} from "@/components/global/listbox";
import { useViewer } from "@/composables/viewer";

export default defineComponent({
  name: "app-sidepanel",
  props: {
    /**
     * Taille du Pane en pourcentage
     */
    size: Number,
  },
  setup() {
    const currentRoute = ref<string>("maquette");

    const viewerStore = useViewerStore();

    const { isReady, isLoaded } = useViewer();

    const canShow = computed(() => isReady.value && isLoaded.value);

    const { toggleSidepanel } = viewerStore;

    const getRouteLabel = (route_name) => {
      switch (route_name) {
        case "maquette":
          return "Explorer la maquette";
        case "bordereaux":
          return "Explorer les bordereaux";
        case "search":
          return "Recherche";
      }
    };

    return {
      canShow,
      currentRoute,

      toggleSidepanel,

      getRouteLabel,
    };
  },
  components: {
    ChevronLeft,
    MenuClose,
    Pane,
    ViewerLoader,

    BListBox,
    BListBoxButton,
    BListBoxOption,
    BListBoxOptions,
    Search,
    Maquette,
    Bordereau,
    BOverlay,
  },
});
