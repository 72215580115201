export * from "./http";
export * from "./math";
export * from "./device";
export * from "./components";
export * from "./entity";
export * from "./keys";
export * from "./form";
export * from "./render";
export * from "./vue";
export * from "./date";
export * from "./devOnly";
export * from "./phone";
export * from "./url";
export * from "./image";
export * from "./data";
export * from "./deepCopy";

export function contains(target: string, pattern: string[]): boolean {
  let value = 0;
  if (pattern) {
    if (target) {
      const targetValue = target.toLowerCase();
      pattern.forEach((word) => {
        if (
          targetValue
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(word.normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
        )
          value++;
      });
    }
    return value == pattern.length;
  }
  return false;
}

export function descendantProp(obj: any, key: string): any {
  if (obj) {
    return key.split(".").reduce((a, b) => {
      if (a) {
        return a[b];
      }
    }, obj);
  }
}

export function groupBy(xs: any[], key: string): any {
  return xs.reduce((rv, x) => {
    if (x) {
      if (key.includes(".")) {
        (rv[descendantProp(x, key)] = rv[descendantProp(x, key)] || []).push(x);
      } else {
        (rv[x[key]] = rv[x[key]] || []).push(x);
      }
    }
    return rv;
  }, {});
}

export function isNumeric(str: string) {
  return !isNaN(parseFloat(str));
}

export function nextLetter(str: string): string {
  if (!str) return "A";
  let tail = "";
  let i = str.length - 1;
  let char = str[i];
  // find the index of the first character from the right that is not a 'Z'
  while (char === "Z" && i > 0) {
    i--;
    char = str[i];
    tail = "A" + tail; // tail contains a string of 'A'
  }
  if (char === "Z")
    // the string was made only of 'Z'
    return "AA" + tail;
  // increment the character that was not a 'Z'
  return str.slice(0, i) + String.fromCharCode(char.charCodeAt(0) + 1) + tail;
}

export function partition(array, isValid): [any[], any[]] {
  return array.reduce(
    ([pass, fail], elem) => {
      return isValid(elem) ? [[...pass, elem], fail] : [pass, [...fail, elem]];
    },
    [[], []]
  );
}

export const isValidEmail = (str): boolean => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(str).toLowerCase().trim());
};

export const isValidPhone = (str: string): boolean => {
  const phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  return phoneno.test(String(str).trim());
};

export const removeDiacriticsAndNormalize = (str: string): string => {
  return str
    .trim()
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");
};

// CREDIT HEADLESS.UI
// https://github.com/tailwindlabs/headlessui
export function match<
  TValue extends string | number = string,
  TReturnValue = unknown
>(
  value: TValue,
  lookup: Record<TValue, TReturnValue | ((...args: any[]) => TReturnValue)>,
  ...args: any[]
): TReturnValue {
  if (value in lookup) {
    const returnValue = lookup[value];
    return typeof returnValue === "function"
      ? returnValue(...args)
      : returnValue;
  }

  const error = new Error(
    `Tried to handle "${value}" but there is no handler defined. Only defined handlers are: ${Object.keys(
      lookup
    )
      .map((key) => `"${key}"`)
      .join(", ")}.`
  );
  if (Error.captureStackTrace) Error.captureStackTrace(error, match);
  throw error;
}

export const toBase64 = (
  file: File | Blob,
  whitoutDataAttr = false
): Promise<string | ArrayBuffer> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    if (whitoutDataAttr) {
      reader.onload = () => {
        const result = reader.result as string;
        resolve(result.substring(result.indexOf(",") + 1));
      };
    } else {
      reader.onload = () => resolve(reader.result);
    }
    reader.onerror = (error) => reject(error);
  });

export function base64toBlob(base64Data, contentType = "") {
  contentType = contentType || "";
  const sliceSize = 1024;
  const byteCharacters = atob(
    base64Data.substring(base64Data.indexOf(",") + 1)
  );
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
}

export const rgbToHex = (r, g, b) =>
  "#" +
  [r, g, b]
    .map((x) => {
      const hex = x.toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    })
    .join("");

export const hexToRgb = (hex) =>
  hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => "#" + r + r + g + g + b + b
    )
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16));
