import { ISociete } from "./societe.model";

export interface IUserState {
  token: string;
  nom: string;
  prenom: string;
  roles: UserRole[];
  email: string;
  rememberMe: boolean;
  societe: ISociete;
  userLogin: string;
}

export enum UserRole {
  logged = "logged",
  admin = "admin",
}
