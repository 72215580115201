
import { defineComponent, computed } from "vue";
import { Pane } from "splitpanes";
import { useRoute } from "vue-router";
import { FitSelection, ZoomIn, ZoomOut } from "@/components/global/icons";
import { SimulationDevice, usePreviewStore } from "@/stores/preview";
import { storeToRefs } from "pinia";
import {
  BListBox,
  BListBoxButton,
  BListBoxOption,
  BListBoxOptions,
} from "@/components/global";

export default defineComponent({
  setup() {
    const route = useRoute();
    const previewStore = usePreviewStore();

    const pageName = computed<string>(() => {
      if (route.meta?.pageName) {
        return route.meta.pageName as string;
      }
      return "Prévisualisation";
    });

    const onZoomIn = () => {
      previewStore.previewZoomIn();
    };
    const onZoomOut = () => {
      previewStore.previewZoomOut();
    };
    const onReset = () => {
      previewStore.previewReset();
    };

    const { darkMode, device } = storeToRefs(previewStore);

    return {
      pageName,

      darkMode,

      device,
      devices: Object.values(SimulationDevice).filter(
        (e) => typeof e != "string"
      ),
      SimulationDevice,

      onReset,
      onZoomIn,
      onZoomOut,
    };
  },
  components: {
    Pane,

    BListBox,
    BListBoxButton,
    BListBoxOption,
    BListBoxOptions,

    ZoomIn,
    ZoomOut,
    FitSelection,
  },
});
