import { ILocalisationError } from "@/pages/bureau/@types/localisation";

const localisationError: ILocalisationError = {
  required: "Le champ est requis !",
  notFound: "Ressource introuvable.",
  default: "Une erreur est survenue.",
  stringEmpty: "Le champs ne peut pas être vide",
  stringInvalid: "Le champs est invalid",
  tooLong: "La valeur est trop longue",
  tooShort: "La valeur est trop courte",
  dateInvalid: "La date est invalide",
  download: "Echec du téléchargement",
};

export default localisationError;
