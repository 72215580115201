import Vuex, { Module, Store } from "vuex";
import VuexPersistence, { AsyncStorage } from "vuex-persist";
import { ModuleOptions } from "vuex-module-decorators/dist/types/moduleoptions";
import localforage from "localforage";

// Interfaces modules
import { IUserState } from "@/pages/bureau/@models/user.model";
import { ISettingsState } from "@/pages/bureau/@models/settings.model";
import { ISocietesState } from "@/pages/bureau/@models/societe.model";
import { IProjetsState } from "@/pages/bureau/@models/projet.model";
import { IIntervenantState } from "@/pages/bureau/@models/intervenants.model";
import { IPointagesState } from "@/pages/bureau/@models/pointage.model";
import { deepCopy } from "@/utils/deepCopy";
import { IFraisState } from "@/pages/bureau/@models/frais.model";

type ModuleType =
  | IUserState
  | ISettingsState
  | ISocietesState
  | IIntervenantState
  | IFraisState
  | IProjetsState
  | IPointagesState;

export interface IRootState {
  user: IUserState;
  settings: ISettingsState;
  societes: ISocietesState;
  frais: IFraisState;
  projets: IProjetsState;
  intervenants: IIntervenantState;
  pointages: IPointagesState;
}

function dynamicRegisterPlugin(store: Store<IRootState>) {
  // Override dynamic module handling in vuex
 try {
   let origRegisterModule: any;
   if (store.registerModule) {
     origRegisterModule = store.registerModule.bind(store);
     // your module interfaces

     store.registerModule = (
       path: string | any,
       module: Module<ModuleType, IRootState> | any,
       options?: ModuleOptions | any
     ) => {
       if (Object.prototype.hasOwnProperty.call(store.state, path)) {
         origRegisterModule(path, module, { preserveState: true });
       } else {
         origRegisterModule(path, module, options);
       }
     };
   }
 } catch (error) {
   console.error("AR", error);
 }
}

export const storage = localforage.createInstance({
  driver: localforage.INDEXEDDB,
  name: "bimDB",
  version: 1.0,
  storeName: "bimStore",
});

export const vuexLocal = new VuexPersistence({
  storage: storage as AsyncStorage,
  asyncStorage: true,
  reducer: (state) => deepCopy(state),
});

export default new Vuex.Store<IRootState>({
  plugins: [dynamicRegisterPlugin, vuexLocal.plugin],
});
