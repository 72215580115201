import {  IConsultationBordereauDetail, IConsultationBordereauPrixConsult } from "@/@models/consultations";
import { consultationsService } from "@/api";
import { AssociativeUtils, checkData, clone, isUUIDValid, isValidStatus } from "@/utils";
import { ref, unref, Ref } from "vue";

interface IUseBordereau {
    bordereau: Ref<IConsultationBordereauDetail>;
    loading: Ref<boolean>;
    loaded: Ref<boolean>;

    copy: () => IConsultationBordereauDetail | null;
    clear: () => void;

    fetch: (() => Promise<boolean>) | ((uidLot: string, uidOffre: string, uidBordereau: string) => Promise<boolean>);
    //save: () => Promise<boolean>;

    findPrix: (predicate: (prix: IConsultationBordereauPrixConsult) => boolean) => IConsultationBordereauPrixConsult | null;
    filterPrix: (predicate: (prix: IConsultationBordereauPrixConsult) => boolean) => IConsultationBordereauPrixConsult[];

    getAllPrixMontantNull: () => IConsultationBordereauPrixConsult[];
    getAllPrixQuantiteNull: () => IConsultationBordereauPrixConsult[];

    findPrixByUid: (uid_prix: string) => IConsultationBordereauPrixConsult | null;
    getVariant: (uid_prix: string) => IConsultationBordereauPrixConsult | null;

    getFlatDetail: () => IConsultationBordereauPrixConsult[];
    getRecordDetail: () => Record<string, IConsultationBordereauPrixConsult>;
}

function useBordereau(): IUseBordereau;
function useBordereau(uidLot: string, uidOffre: string, uidBordereau: string): IUseBordereau;
function useBordereau(...args: string[]): IUseBordereau {

    let uid_lot = unref(args[0]);
    let uid_offre = unref(args[1]);
    let uid_bordereau = unref(args[2]);

    const bordereau = ref<IConsultationBordereauDetail>(null);

    const loading = ref<boolean>(false);
    const loaded = ref<boolean>(false);

    const copy = () => {
        if (bordereau.value) {
            return clone(unref(bordereau.value))
        }
        return null;
    }

    const clear = () => {
        bordereau.value = null;
        loading.value = false;
        loaded.value = false;
    }

    async function fetch(): Promise<boolean>;
    async function fetch(uidLot: string, uidOffre: string, uidBordereau: string): Promise<boolean>;
    async function fetch(uidLot?: string, uidOffre?: string, uidBordereau?: string): Promise<boolean> {
        loading.value = true;

        if (args?.length) {
            uid_lot = unref(args[0]);
            uid_offre = unref(args[1]);
            uid_bordereau = unref(args[2]);
        }

        if (!isUUIDValid(uid_bordereau)) {
            throw new Error("useBordereau: parametre 'uidBordereau' manquant ou invalide")
        }

        if (!isUUIDValid(uid_offre)) {
            throw new Error("useBordereau: parametre 'uidOffre' manquant ou invalide")
        }

        if (!isUUIDValid(uid_lot)) {
            throw new Error("useBordereau: parametre 'uidLot' manquant ou invalide")
        }

        const { data, status } = await consultationsService.getConsultationBordereau(
            uid_lot,
            uid_offre,
            uid_bordereau
        );

        if (!isValidStatus(status) || !checkData(data)) {
            bordereau.value = null;
            loading.value = false;
            loaded.value = false;
            return false;
        }

        bordereau.value = data.bordereau

        loading.value = false;
        loaded.value = true;

        return true;
    }

    const save = async () => {

    }

    const findPrix = (predicate: (prix: IConsultationBordereauPrixConsult) => boolean): IConsultationBordereauPrixConsult => {
        if (bordereau.value?.details) {
            return findPrixConsult(bordereau.value.details, predicate)
        }
        return null;
    }

    const filterPrix = (predicate: (prix: IConsultationBordereauPrixConsult) => boolean): IConsultationBordereauPrixConsult[] => {
        if (bordereau.value?.details) {
            return filterPrixConsult(bordereau.value.details, predicate)
        }
        return [];
    }

    const getAllPrixMontantNull = (): IConsultationBordereauPrixConsult[] => {
        const result = [];
        for (const prix of filterPrix((p) => !p.prixUnit && p.estPrisEnCompte && !p.isDeleted && !p.estElementForfait)) {
            if ((prix.estArticle && !getVariant(prix.uid)) || (!prix.estArticle && prix.estForfait)) {
                result.push(prix);
            }
        }
        return result;
    };

    const getAllPrixQuantiteNull = (): IConsultationBordereauPrixConsult[] => {
        const result = [];
        for (const prix of filterPrix((p) => !p.quantite && p.estArticle && p.estPrisEnCompte && !p.isDeleted && !p.estForfait && !p.estElementForfait)) {
            if (!getVariant(prix.uid)) {
                result.push(prix);
            }
        }
        return result;
    };

    const findPrixByUid = (uid_prix: string) => {
        return findPrix((p) => p.uid == uid_prix)
    }

    /**
     * Recupere tout les prix du bordereau dans une array 'plat'
     * @returns 
     */
    const getFlatDetail = () => {
        if (bordereau.value?.details) {
            return getFlatPrixConsult(bordereau.value.details);
        }
        return [];
    }

    /**
    * Recupere tout les prix du bordereau dans un objet clé/valeur ([uid]: prix)
    * @returns 
    */
    const getRecordDetail = () => {
        if (bordereau.value?.details) {
            return AssociativeUtils.convertToAssociative(getFlatDetail(), "uid");
        }
        return {};
    }

    const getVariant = (uid_prix: string): IConsultationBordereauPrixConsult => {
        return findPrix((prix) => prix.articleRef == uid_prix && !prix.isDeleted);
    }

    return {
        bordereau,

        loading,
        loaded,

        copy,
        clear,
        fetch,
        //save,

        findPrix,
        filterPrix,

        findPrixByUid,
        getVariant,

        getAllPrixMontantNull,
        getAllPrixQuantiteNull,
        getFlatDetail,
        getRecordDetail,
    }
}

/**
* Retourne le détails du bordereau en 'array plate'
* @param bordereau 
* @param addTotalOuvrage ? ajoute une ligne total apres les articles d'un ouvrage 
 * @returns 
*/
function getFlatPrixConsult(arr: IConsultationBordereauPrixConsult[], deleteChildKey = false): IConsultationBordereauPrixConsult[] {
    let ouvrages: IConsultationBordereauPrixConsult[] = [];
    if (!Array.isArray(arr)) return ouvrages;

    arr.forEach((x) => {
        const ouvrage = { ...x } as IConsultationBordereauPrixConsult
        ouvrages.push(ouvrage);
        if (x.ouvrages) {
            const childResults = getFlatPrixConsult(x.ouvrages);
            if (childResults.length) {
                ouvrages = ouvrages.concat(childResults);
            }
        }
        if (deleteChildKey) {
            delete ouvrage.ouvrages; // On supprime pour ne pas avoir des données en doubles
        }

    });
    return ouvrages;
}

/**
 * Retoune l'array de résultat, attention au type
 * @param arr source
 * @param key champs sur lequel chercher
 * @param value valeur à chercher
 * @returns array
 */
function getPrixConsultById(arr: IConsultationBordereauPrixConsult[], uid: string): IConsultationBordereauPrixConsult {
    let matche = null;

    arr.forEach((x) => {
        if (x.uid == uid) {
            matche = x;
        }
        if (x.ouvrages) {
            const result = getPrixConsultById(x.ouvrages, uid);
            if (result) {
                matche = result;
            }
        }
    })

    return matche;
}

/**
 * Retoune l'array de résultat, attention au type
 * @param arr source
 * @param key champs sur lequel chercher
 * @param value valeur à chercher
 * @returns array
 */
function findAllPrixConsultByKey(arr: IConsultationBordereauPrixConsult[], key: keyof IConsultationBordereauPrixConsult, value: any): IConsultationBordereauPrixConsult[] {
    let matches = [];
    if (!Array.isArray(arr)) return matches;

    arr.forEach((x) => {
        if (x[key] == value) {
            matches.push(x);
        }
        if (x.ouvrages) {
            const childResults = findAllPrixConsultByKey(x.ouvrages, key, value);
            if (childResults.length) {
                matches = matches.concat(childResults);
            }
        }
    })

    return matches;
}

/**
 * Retoune l'array de résultat, attention au type
 * @param arr source
 * @returns array
 */
function filterPrixConsult(arr: IConsultationBordereauPrixConsult[], predicate: (prix: IConsultationBordereauPrixConsult) => boolean): IConsultationBordereauPrixConsult[] {
    let matches = [];
    if (!Array.isArray(arr)) return matches;
    if (predicate == undefined) return matches;

    arr.forEach((x) => {
        if (predicate(x)) {
            matches.push(x);
        }
        if (x.ouvrages) {
            const childResults = filterPrixConsult(x.ouvrages, predicate);
            if (childResults.length) {
                matches = matches.concat(childResults);
            }
        }
    })

    return matches;
}

/**
 * Retoune l'array de résultat, attention au type
 * @param arr source
 * @returns array
 */
function findPrixConsult(arr: IConsultationBordereauPrixConsult[], predicate: (prix: IConsultationBordereauPrixConsult) => boolean): IConsultationBordereauPrixConsult {
    let matche = null;
    if (!Array.isArray(arr)) return matche;
    if (predicate == undefined) return matche;

    arr.forEach((x) => {
        if (predicate(x)) {
            matche = x
        }
        if (x.ouvrages) {
            const childResult = filterPrixConsult(x.ouvrages, predicate);
            if (childResult.length) {
                matche = childResult
            }
        }
    })

    return matche;
}

/**
 * Permet d'appliquer une valeur à l'ensemble des prix consult et leur enfants
 * @param arr source
 * @param key champs sur lequel chercher
 * @param value valeur à chercher
 * @returns array
 */
function applyAllPrixConsult(arr: IConsultationBordereauPrixConsult[], key: keyof IConsultationBordereauPrixConsult, newValue: any): void {
    arr.forEach((x: any) => {
        x[key] = newValue;
        if (x.ouvrages) {
            applyAllPrixConsult(x.ouvrages, key, newValue);
        }
    })
}

function sortPrixConsult(arr: IConsultationBordereauPrixConsult[]) {
    if (!Array.isArray(arr) || arr.length == 0) return;
    arr.forEach((x) => {
        if (x.ouvrages) {
            x.ouvrages = x.ouvrages.sort((a, b) => a.ordre - b.ordre)
            sortPrixConsult(x.ouvrages)
        }
    })
}

export {
    useBordereau,

    IUseBordereau
}