import { IPageContext } from "@/@models/preview";
import { defineStore, storeToRefs } from "pinia";
import { useConsultationStore } from "../../pages/ao/store";
import { usePreviewStore } from "./preview";

/**
 * Contient les info d'une consultation pour la partie appel d'offre
 */
export const usePreviewConsultationStore = defineStore(
  "preview-consultation-info",
  () => {
    const previewStore = usePreviewStore();
    const consultationStore = useConsultationStore();

    const {
      consultation,

      uid,
      ao,
      architecte,
      canEdit,
      chargeProjet,
      dateDebut,
      dateFin,
      dateFinNego,
      estVisite,
      fichierCao,
      moa,
      nom,
      params,
      procedure,
      projet,
      societe,
      statut,
      tva,
      viewerIfc,
      confidentialite,
    } = storeToRefs(consultationStore);

    async function initialize() {
      const { consultation } = await previewStore.fetchContext({
        consultation: {
          uid: "D63C554499974E60ADFCC7748CA033D9",
          links: [
            "ao",
            "projet",
            "moa",
            "fichierCao",
            "architecte",
            "chargeProjet",
            "societe",
          ],
        },
      });


      if (consultation) {
        uid.value = consultation.y32_UUIDIndex;
        dateDebut.value = consultation.y14_Lancement;
        dateFin.value = consultation.y14_DateLimite;
        dateFinNego.value = consultation.y14_FinNego;
        estVisite.value = false;
        nom.value = consultation.yt_Objet;
        procedure.value = consultation.yt_Procedure;
        statut.value = consultation.yl_Statut;
        tva.value = [];
        viewerIfc.value = consultation.yb_ViewerIFC;
        params.value = consultation.yo_OptionWeb;

        ao.value = {
          datePrevDebut: consultation.lien_ao?.yd_Debut,
          datePrevFin: consultation.lien_ao?.yd_Fin,
          datePrevLivraison: consultation.lien_ao?.yd_Livraison,
        };

        architecte.value = {
          index: consultation.lien_architecte?.yl_index,
          nom: consultation.lien_architecte?.yt_nomprenom,
          sigle: consultation.lien_architecte?.yt_Sigle,
        };

        moa.value = {
          adresse: consultation.lien_moa?.yt_adresse,
          codePostal: consultation.lien_moa?.yt_codepostal,
          email: consultation.lien_moa?.yt_Email,
          index: consultation.lien_moa?.yl_index,
          nom: consultation.lien_moa?.yt_nomprenom,
          pays: consultation.lien_moa?.yt_Pays,
          sigle: consultation.lien_moa?.yt_Sigle,
          telephone: consultation.lien_moa?.yt_Tel,
          ville: consultation.lien_moa?.yt_Ville,
          contact: null,
          image: null,
        };

        chargeProjet.value = {
          index: consultation.lien_chargeProjet?.yl_Index,
          nom: consultation.lien_chargeProjet?.y80_Name,
          prenom: consultation.lien_chargeProjet?.yt_Prenom,
          sigle: null,
        };

        fichierCao.value = {
          estConsultable: consultation.yb_ViewerIFC,
          nom: consultation.lien_fichierCao?.yt_Nom,
          uid: consultation.lien_fichierCao?.y32_UUIDIndex,
          uidEtude: consultation.lien_fichierCao?.y32_UUIDEtude,
        };

        projet.value = {
          adresse: consultation.lien_projet?.yt_AdrChantier,
          appelation: consultation.lien_projet?.yt_appelation,
          budget: consultation.lien_projet?.yr_BudClient,
          code: consultation.lien_projet?.y20_CodeProjet,
          cp: consultation.lien_projet?.y15_Cp,
          dateDebutTrav: consultation.lien_projet?.yd_DebTrav,
          dateFinTrav: consultation.lien_projet?.yd_FinTrav,
          dateLivraison: consultation.lien_projet?.yd_Date,
          descriptif: consultation.lien_projet?.y80_Inf4,
          index: consultation.lien_projet?.yl_Indx_projet,
          nature: consultation.lien_projet?.yt_Nature,
          nom: consultation.lien_projet?.yt_Objet,
          uid: "",
          ville: consultation.lien_projet?.y80_Inf4,
        };

        societe.value = {
          adresse: consultation.lien_societe?.yt_adresse,
          cp: consultation.lien_societe?.yt_codepostal,
          ville: consultation.lien_societe?.yt_Ville,
        };

        consultationStore.consultation = {
          uid: uid.value,
          ao: ao.value,
          architecte: architecte.value,
          chargeProjet: chargeProjet.value,
          dateDebut: dateDebut.value,
          dateFin: dateFin.value,
          dateFinNego: dateFinNego.value,
          estVisite: estVisite.value,
          fichierCao: fichierCao.value,
          moa: moa.value,
          nom: nom.value,
          params: params.value,
          procedure: procedure.value,
          projet: projet.value,
          societe: societe.value,
          statut: statut.value,
          tva: tva.value,
          viewerIfc: viewerIfc.value,
          confidentialite: confidentialite.value,
        };
      }
    }

    /**
     * Enregistre les prefs
     */
    async function save() {
      const previewStore = usePreviewStore();

      const data: Partial<IPageContext> = {
        consultation: {
          y32_UUIDIndex: uid.value,
          yo_OptionWeb: params.value,
        },
      };

      await previewStore.saveContext(data);
    }

    return {
      consultation,

      uid,
      ao,
      architecte,
      canEdit,
      chargeProjet,
      dateDebut,
      dateFin,
      dateFinNego,
      estVisite,
      fichierCao,
      moa,
      nom,
      params,
      procedure,
      projet,
      societe,
      statut,
      tva,
      viewerIfc,

      initialize,
      save,
    };
  }
);
