import axios from "./index";
import { ITache } from "@/pages/bureau/@models/taches.model";
 
const BASE_ENDPOINT = "BUREAU";

export const tachesService = {
  /**
   * Recupere tous les projets
   */
  getFilteredTaches(filters = {}) {
    return axios.post(`${BASE_ENDPOINT}/TACHES`, filters);
  },

  getTache(indexTache: number) {
    return axios.get(`${BASE_ENDPOINT}/TACHE/${indexTache}`);
  },

  getCollaboInfosTaches() {
    return axios.get(`${BASE_ENDPOINT}/MODULES/TACHES`);
  },

  updateTache(tache: ITache) {
    return axios.post(`${BASE_ENDPOINT}/TACHE/` + tache.index, tache);
  },
  createTache(tache: ITache) {
    return axios.put(`${BASE_ENDPOINT}/TACHE`, tache);
  },

  getDocsListe(indexTache: number) {
    return axios.get(`${BASE_ENDPOINT}/TACHE/${indexTache}/DOCS`);
  },
  createDoc(indexTache: number, docName: string, b64: string) {
    return axios.put(`${BASE_ENDPOINT}/TACHE/${indexTache}/DOC`, {
      fileName: docName,
      fileB64: b64,
    });
  },
  deleteDoc(indexTache: number, indexDoc: number) {
    return axios.delete(`${BASE_ENDPOINT}/TACHE/${indexTache}/DOC/${indexDoc}`);
  },
};
