import { computed, ref } from "vue";
import { useThrottle } from '@vueuse/core'

const THROTTLE_TIME = 0;

const _loading = ref(false);
const _loadingThrottled = useThrottle(_loading, THROTTLE_TIME);

export default function useLoader() {

    const loading = computed(() => _loading.value || _loadingThrottled.value);

    const setLoading = (value: boolean) => {
        _loading.value = value;
    }

    const getLoading = (): boolean => {
        return _loading.value;
    }

    return {
        loading,

        setLoading,
        getLoading
    }
}