
import { defineComponent, nextTick, watch , computed, ref} from "vue";
import { useFichiersCaoStore, useViewerFiltresStore } from "@/stores/viewer";
import BBtn from "@/components/global/BBtn.vue";
import BDropdown from "@/components/global/BDropdown.vue";
import MenuClose from "@/components/global/icons/BIconMenuClose.vue";

export default defineComponent({
  setup() {
    const pulse = ref(false);

    const fichierCaoStore = useFichiersCaoStore();
    const filtersStore = useViewerFiltresStore();

    const calques = computed(() => fichierCaoStore.calques);

    const layers = computed(() => filtersStore.layers);

    const { addLayer, removeLayer, isFilterLayer } = filtersStore;

    const onChange = (name: string) => {
      if (isFilterLayer(name)) {
        removeLayer(name);
      } else {
        addLayer(name);
      }
    };

    const onCheckAll = () => {
      filtersStore.layers = [];
    };

    const onUncheckAll = () => {
      filtersStore.layers = [...calques.value];
    };

    watch(
      () => layers.value.length,
      async () => {
        pulse.value = true;
        await nextTick();
        setTimeout(() => (pulse.value = false), 200);
      }
    );

    return {
      pulse,
      calques,
      layers,

      onChange,
      onCheckAll,
      onUncheckAll,

      isFilterLayer,
    };
  },
  components: {
    BBtn,
    BDropdown,
    MenuClose,
  },
});
