import axios from "axios";
import axiosInstance from "@/plugins/axios";
import { generateToken } from "@/utils/tokens";

const BASE_ENDPOINT = "BOX";

export enum BOX_ENUM {
  DOWNLOAD = 1,
  UPLOAD = 2,
  LIST = 3,
  DELETE = 4,
  MOVE = 6,
  COPY = 10,
  TRASH = 11,
  SEARCH = 13,
  DELETE_PERMANENTLY = 14,
  VERSIONS = 15,
  PROMOTE_VERSION = 16,
  EVENTS = 17,
  SHARE = 18,
  ZIP_DOWNLOAD = 19,
  GET_EMAIL = 20,
}

export const boxService = {
  getLoginUrl(clientId: string) {
    /* return (
      "https://www.box.com/api/oauth2/authorize?response_type=code&client_id=" +
      clientId +
      "&redirect_uri=" +
      "https://10.0.0.162:8081/ao&boxtoken" +
      "&state=boxtoken" +
      generateToken()
    );
    */
    return (
      "https://www.box.com/api/oauth2/authorize?response_type=code&client_id=" +
      clientId +
      "&redirect_uri=" +
      "https://10.0.0.162:4000/boxtoken" +
      "&state=boxtoken" +
      generateToken()
    );
  },
  getBoxSettings() {
    return axiosInstance.get<{
      clientID: string;
      clientSecret: string;
    }>(`${BASE_ENDPOINT}/SETTINGS`);
  },
  async getTokenWithCode(code: string, clientID: string, clientSecret: string) {
    return await axios.post("https://api.box.com/oauth2/token", {
      client_id: clientID,
      client_secret: clientSecret,
      code,
      grant_type: "authorization_code",
    });
  },

  async refreshToken(
    refreshToken: string,
    clientID: string,
    clientSecret: string
  ) {
    return axios.post(
      "https://api.box.com/oauth2/token",
      {
        client_id: clientID,
        client_secret: clientSecret,
        refresh_token: refreshToken,
        grant_type: "refresh_token",
      },
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );
  },

  getList(id: string, estDossier: boolean, token: string) {
    return axiosInstance.post(`${BASE_ENDPOINT}/URL/${BOX_ENUM.LIST}`, {
      id,
      estDossier,
      token,
    });
  },
  getUserInfos(token: string) {
    return axiosInstance.post(`${BASE_ENDPOINT}/URL/${BOX_ENUM.GET_EMAIL}`, {
      token,
    });
  },
};
