import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex h-screen w-screen overflow-hidden border-l-8 border-[rgb(171,145,110)] bg-background !font-sans text-base" }
const _hoisted_2 = { class: "flex flex-grow flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppSideNav = _resolveComponent("AppSideNav")!
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_ScenePane = _resolveComponent("ScenePane")!
  const _component_OptionsPane = _resolveComponent("OptionsPane")!
  const _component_Splitpanes = _resolveComponent("Splitpanes")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isSingle)
      ? (_openBlock(), _createBlock(_component_AppSideNav, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_RouterView),
    _createElementVNode("main", _hoisted_2, [
      _createVNode(_component_AppHeader),
      _createVNode(_component_Splitpanes, {
        ref: "splitpanesEl",
        class: "overflow-hidden",
        onResized: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onResized($event))),
        onSplitterClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onSplitterResized()))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ScenePane, { size: _ctx.mainPanelSize }, null, 8, ["size"]),
          _createVNode(_component_OptionsPane, { size: _ctx.sidePanelSize }, null, 8, ["size"])
        ]),
        _: 1
      }, 512)
    ])
  ]))
}