
import { defineComponent, ref } from "vue";
import { BModal, BBtn } from "@/components/global";

type ButtonType = "accept" | "cancel" | "denied" | "close";

export default defineComponent({
  emits: ["close"],
  setup(props, { expose }) {
    const message = ref("");
    const show = ref(false);

    const buttons = ref<ButtonType[]>([]);

    let resolvePromise = null;

    const open = (_message: string, _buttons: ButtonType[]) => {
      message.value = _message;
      buttons.value = _buttons;

      show.value = true;

      return new Promise<ButtonType>((resolve) => {
        resolvePromise = resolve;
      });
    };

    const submit = (type: ButtonType) => {
      show.value = false;
      if (resolvePromise) {
        resolvePromise(type);
      }
    };

    const close = () => {
      show.value = false;
      if (resolvePromise) {
        resolvePromise("close");
      }
    };

    expose({
      open,
    });

    return {
      show,

      buttons,
      message,
      open,
      submit,
      close,
    };
  },
  components: {
    BModal,
    BBtn,
  },
});
