import { IFichier, } from "@/@models/fichier";
import { IFichierCao, ILigneCao, ILigneCaoLien, ILigneCaoQuantite, ILotsCao, IOuvrageCao } from "@/@models/fichier-cao"
import axios from "@/plugins/axios"


const BASE_ENDPOINT = "VIEWER-IFC";

export const bimService = {
    /**
    * Recupere un fichier CAO
    * @param payload uid fichier CAO
    * @returns 
    */
    getFichierCao(uid_fichier_cao) {
        return axios.get<
            {
                fichierCao: IFichierCao
            }
        >(`${BASE_ENDPOINT}/FICHIERS-CAO/${uid_fichier_cao}`)
    },
    /**
     * Recupere le fichier JSON de la maquette
     * @param uid_fichier_cao 
     * @param uid_maquette 
     */
    downloadJson(uid_fichier_cao, uid_maquette, predicate?) {
        return axios.get(`${BASE_ENDPOINT}/FICHIERS-CAO/${uid_fichier_cao}/MAQUETTES/${uid_maquette}/DOWNLOAD/JSON`, { responseType: 'blob', onDownloadProgress: predicate })
    },
    /**
    * Recupere le fichier XKT de la maquette
    * @param uid_fichier_cao 
    * @param uid_maquette 
    */
    downloadXkt(uid_fichier_cao, uid_maquette, predicate?) {
        return axios.get(`${BASE_ENDPOINT}/FICHIERS-CAO/${uid_fichier_cao}/MAQUETTES/${uid_maquette}/DOWNLOAD/XKT`, { responseType: 'blob', onDownloadProgress: predicate })
    },
    /**
    * Recupere tous les lots et leurs bordereaux et ouvrages
    * @param payload uid fichier CAO
    * @returns 
    */
    getLots(uid_fichier_cao) {
        return axios.get<
            {
                lots: ILotsCao[]
            }
        >(`${BASE_ENDPOINT}/FICHIERS-CAO/${uid_fichier_cao}/LOTS`)
    },
    /**
     * Recupere tout les calques d'un fichier CAO
     * @param uid_fichier_cao 
     * @returns 
     */
    getCalques(uid_fichier_cao) {
        return axios.get<
            {
                calques: string[]
            }
        >(`${BASE_ENDPOINT}/FICHIERS-CAO/${uid_fichier_cao}/IFC/CALQUES`)
    },
    getOuvrage(uid_fichier_cao, uid_ouvrage) {
        return axios.get<
            {
                ouvrage: IOuvrageCao
            }
        >(`${BASE_ENDPOINT}/FICHIERS-CAO/${uid_fichier_cao}/OUVRAGES/${uid_ouvrage}`)
    },
    getOuvrageLocalisations(uid_fichier_cao, uid_ouvrage) {
        return axios.get<
            {
                localisations: ILigneCao[]
            }
        >(`${BASE_ENDPOINT}/FICHIERS-CAO/${uid_fichier_cao}/OUVRAGES/${uid_ouvrage}/LOCALISATIONS`)
    },
    getOuvrageDocuments(uid_fichier_cao, uid_ouvrage) {
        return axios.get<
            {
                documents: IFichier[]
            }
        >(`${BASE_ENDPOINT}/FICHIERS-CAO/${uid_fichier_cao}/OUVRAGES/${uid_ouvrage}/DOCUMENTS`)
    },
    getLigneCaoByUid(uid_fichier_cao, uid_ligne_cao) {
        return axios.get<
            {
                element: ILigneCao
            }
        >(`${BASE_ENDPOINT}/FICHIERS-CAO/${uid_fichier_cao}/ELEMENTS/${uid_ligne_cao}`)
    },
    getLigneCaoByIfc(uid_fichier_cao, ifc) {
        return axios.get<
            {
                element: ILigneCao
            }
        >(`${BASE_ENDPOINT}/FICHIERS-CAO/${uid_fichier_cao}/IFC/ELEMENTS/${ifc}`)
    },
    getLigneCaoByCao(uid_fichier_cao, cao) {
        return axios.get<
            {
                element: ILigneCao
            }
        >(`${BASE_ENDPOINT}/FICHIERS-CAO/${uid_fichier_cao}/CAO/ELEMENTS/${cao}`)
    },
    getOuvragesLigneCaoByUid(uid_fichier_cao, uid_ligne_cao) {
        return axios.get<
            {
                ouvrages: IOuvrageCao[]
            }
        >(`${BASE_ENDPOINT}/FICHIERS-CAO/${uid_fichier_cao}/ELEMENTS/${uid_ligne_cao}/OUVRAGES`)
    },
    getOuvragesLigneCaoByIfc(uid_fichier_cao, ifc) {
        return axios.get<
            {
                ouvrages: IOuvrageCao[]
            }
        >(`${BASE_ENDPOINT}/FICHIERS-CAO/${uid_fichier_cao}/IFC/ELEMENTS/${ifc}/OUVRAGES`)
    },
    getOuvragesLigneCaoByCao(uid_fichier_cao, cao) {
        return axios.get<
            {
                ouvrages: IOuvrageCao[]
            }
        >(`${BASE_ENDPOINT}/FICHIERS-CAO/${uid_fichier_cao}/CAO/ELEMENTS/${cao}/OUVRAGES`)
    },
    getQuantitesLigneCaoByUid(uid_fichier_cao, uid_ligne_cao) {
        return axios.get<
            {
                quantites: ILigneCaoQuantite[]
            }
        >(`${BASE_ENDPOINT}/FICHIERS-CAO/${uid_fichier_cao}/ELEMENTS/${uid_ligne_cao}/QUANTITES`)
    },
    getQuantitesLigneCaoByIfc(uid_fichier_cao, ifc) {
        return axios.get<
            {
                quantites: ILigneCaoQuantite[]
            }
        >(`${BASE_ENDPOINT}/FICHIERS-CAO/${uid_fichier_cao}/IFC/ELEMENTS/${ifc}/QUANTITES`)
    },
    getQuantitesLigneCaoByCao(uid_fichier_cao, cao) {
        return axios.get<
            {
                quantites: ILigneCaoQuantite[]
            }
        >(`${BASE_ENDPOINT}/FICHIERS-CAO/${uid_fichier_cao}/CAO/ELEMENTS/${cao}/QUANTITES`)
    },
    getLiensLigneCaoByUid(uid_fichier_cao, uid_ligne_cao) {
        return axios.get<
            {
                liens: ILigneCaoLien[]
            }
        >(`${BASE_ENDPOINT}/FICHIERS-CAO/${uid_fichier_cao}/ELEMENTS/${uid_ligne_cao}/LIENS`)
    },
    getLiensLigneCaoByIfc(uid_fichier_cao, ifc) {
        return axios.get<
            {
                liens: ILigneCaoLien[]
            }
        >(`${BASE_ENDPOINT}/FICHIERS-CAO/${uid_fichier_cao}/IFC/ELEMENTS/${ifc}/LIENS`)
    },
    getLiensLigneCaoByCao(uid_fichier_cao, cao) {
        return axios.get<
            {
                liens: ILigneCaoLien[]
            }
        >(`${BASE_ENDPOINT}/FICHIERS-CAO/${uid_fichier_cao}/CAO/ELEMENTS/${cao}/LIENS`)
    },
    getAllZones(uid_fichier_cao) {
        return axios.get<
            {
                zones: ILigneCao[]
            }
        >(`${BASE_ENDPOINT}/FICHIERS-CAO/${uid_fichier_cao}/IFC/ZONES`)
    },

    /**
 * Recherche tout les elements 
 * Recupere uniquement les IFC
 * @param uid_fichier_cao 
 * @param data 
 * @returns 
 */
    postRechercheElementsCao(uid_fichier_cao, data: SearchElementCaoParameter) {
        return axios.post<
            {
                elements: string[]
            }
        >(`${BASE_ENDPOINT}/FICHIERS-CAO/${uid_fichier_cao}/RECHERCHE/ELEMENTS`, data)
    },

    postRechercheOuvrage(uid_fichier_cao, data: SearchOuvrageParameter) {
        return axios.post<
            {
                ouvrages: IOuvrageCao[]
            }
        >(`${BASE_ENDPOINT}/FICHIERS-CAO/${uid_fichier_cao}/RECHERCHE/OUVRAGES`, data)
    },

    postRechercheDoc(uid_fichier_cao, data: SearchDocParameter) {
        return axios.post<
            {
                docs: IFichier[]
            }
        >(`${BASE_ENDPOINT}/FICHIERS-CAO/${uid_fichier_cao}/RECHERCHE/DOCUMENTS`, data)
    },

    /**
 * Recherche tout les elements 
 * Recupere uniquement les IFC
 * @param uid_fichier_cao 
 * @param data 
 * @returns 
 */
    postFilreElementsCao(uid_fichier_cao, pData: FiltreElementCaoIfcParameter) {
        const data = {
            ...pData,
        }
        return axios.post<
            {
                elements: string[]
            }
        >(`${BASE_ENDPOINT}/FICHIERS-CAO/${uid_fichier_cao}/FILTRES/ELEMENTS`, data)
    }
}


export type SearchElementCaoParameter = {
    etageElement?: string | string[],
    calqueElement?: string | string[],
    batimentElement?: string | string[],
    appartementElement?: string | string[],
    typeElement?: string,
    nomElement?: string,
    avecArticles?: boolean,
    avecElementsComposition?: boolean,
    uniquementIfc?: boolean,
}

export type SearchElementCaoIfcParameter = {
    etageElement: string | string[],
    calqueElement: string | string[],
    batimentElement: string | string[],
    appartementElement: string | string[],
    avecElementsComposition: boolean,
    nullInclu: boolean
}

export type FiltreElementCaoIfcParameter = {
    etageElement?: string[],
    calqueElement?: string[],
    batimentElement?: string[],
    appartementElement?: string[],
    typeElement?: string[]
}

export type SearchOuvrageParameter = {
    nom: string,
    typeRecherche: "finiPar" | "commencePar" | "contient"
}

export type SearchDocParameter = {
    nomDoc: string,
    typeRecherche: "finiPar" | "commencePar" | "contient"
}


