
import { defineComponent } from "vue";

export default defineComponent({
  setup() {
    const beforeEnter = (element: HTMLElement) => {
      requestAnimationFrame(() => {
        if (!element.style.height) {
          element.style.height = "0px";
        }
      });
    };

    const enter = (element: HTMLElement) => {
      requestAnimationFrame(() => {
        element.style.height = `${element.scrollHeight}px`;
      });
    };

    const afterEnter = (element: HTMLElement) => {
      element.style.height = null;
    };

    const beforeLeave = (element: HTMLElement) => {
      requestAnimationFrame(() => {
        if (!element.style.height) {
          element.style.height = `${element.offsetHeight}px`;
        }
      });
    };

    const leave = (element: HTMLElement) => {
      requestAnimationFrame(() => {
        element.style.height = "0px";
      });
    };

    const afterLeave = (element: HTMLElement) => {
      element.style.height = null;
    };

    return {
      enter,
      beforeEnter,
      afterEnter,

      leave,
      beforeLeave,
      afterLeave,
    };
  },
});
