import { ISocieteTag } from "@/@models";
import { IConsultationQuestionReponse } from "@/@models/consultations";
import { societesService } from "@/api";
import { consultationsService } from "@/api/consultations";
import { isValidStatus } from "@/utils";
import { defineStore } from "pinia";
import { useToast } from "vue-toastification";

const toastification = useToast();

/**
 * Type authentification: Intervenant
 */
export const useConsultationQuestionsStore = defineStore(
  "consultation-questions",
  {
    state: () => {
      return {
        questionsReponses: [] as IConsultationQuestionReponse[],

        tags: [] as ISocieteTag[],
        selectedTags: [] as ISocieteTag[],

        error: null,
      };
    },
    getters: {
      getQuestionTagsByString(state) {
        return (question: IConsultationQuestionReponse): ISocieteTag[] => {
          const usedTags = [];
          for (const uidTag of question.tags.split(";")) {
            const tag = state.tags.find((x) => x.uid == uidTag);
            if (tag) {
              usedTags.push(tag);
            }
          }
          return usedTags;
        };
      },
      isSelectedTag(state) {
        return (tag: ISocieteTag): boolean => {
          return state.selectedTags.some((t) => t.uid == tag.uid);
        };
      },
    },
    actions: {
      /**
       * Recupere la documentation DCE d'une consultation
       * @returns
       */
      async fetchQuestionsReponses(payload: string) {
        const { status, data } = await consultationsService.getQestionsReponses(
          payload
        );
        if (!isValidStatus(status) || !data || !data.questions) {
          this.questionsReponses = [];
          toastification.error(
            "La récupération des questions/reponses a échoué."
          );
          return false;
        }
        this.questionsReponses = data.questions;
        return true;
      },
      /**
       * Recupere les tags pour les questions
       * @returns
       */
      async fetchTags() {
        const { status, data } = await societesService.getTags("AO");
        if (!isValidStatus(status)) {
          this.tags = [];
          toastification.error("La récupération des tags a échoué.");
          return false;
        }
        this.resetSelectedTags();
        this.tags = data.tags;
        return true;
      },

      /**
       * Recupere la documentation DCE d'une consultation
       * @returns
       */
      async postQuestion(uid_consultation: string, payload) {
        const { status } = await consultationsService.postQuestionReponse(
          uid_consultation,
          payload
        );
        if (!isValidStatus(status)) {
          toastification.error("L'ajout de la question a échoué.");
          return false;
        }
        toastification.success("Question envoyée !");
        return true;
      },

      addSelectedTag(tag: ISocieteTag) {
        if (tag) {
          const exist = this.selectedTags.find((t) => t.uid == tag.uid);
          if (!exist) {
            this.selectedTags.push(tag);
          }
        }
      },
      removeSelectedTag(tag: ISocieteTag) {
        if (tag) {
          const index = this.selectedTags.findIndex((t) => t.uid == tag.uid);
          if (index >= 0) {
            this.selectedTags.splice(index, 1);
          }
        }
      },
      resetSelectedTags() {
        this.selectedTags = [];
      },
    },
  }
);

const questionTags: QuestionTag[] = [
  {
    id: 0,
    label: "Projet",
    color:
      "bg-[color:#FFE8EE] text-[color:#DD073B] border-[color:#DD073B] dark:bg-[color:#33020E]",
    colorActive: "bg-[color:#DD073B] border-transparent text-white",
    isSelected: false,
  },
  {
    id: 1,
    label: "Administratif",
    color:
      "bg-[color:#EDF0FA] text-[color:#7054E6] border-[color:#7054E6] dark:bg-[color:#191238]",
    colorActive: "bg-[color:#7054E6] border-transparent text-white",
    isSelected: false,
  },
  {
    id: 2,
    label: "Localisation",
    color:
      "bg-[color:#FFF1ED] text-[color:#FC6032] border-[color:#FC6032] dark:bg-[color:#2E1109]",
    colorActive: "bg-[color:#FC6032] border-transparent text-white",
    isSelected: false,
  },

  {
    id: 3,
    label: "MOA",
    color:
      "bg-[color:#FFF7E5] text-[color:#D19100] border-[color:#D19100] dark:bg-[color:#342400]",
    colorActive: "bg-[color:#D19100] border-transparent text-white",
    isSelected: false,
  },
  {
    id: 4,
    label: "Appel d'offre",
    color:
      "bg-[color:#EBFFF3] text-[color:#13C758] border-[color:#13C758] dark:bg-[color:#222724]",
    colorActive: "bg-[color:#13C758] border-transparent text-white",
    isSelected: false,
  },
  {
    id: 5,
    label: "Planning",
    color:
      "bg-[color:#EBFFF3] text-[color:#34B9FF] border-[color:#34B9FF] dark:bg-[color:#071F2C]",
    colorActive: "bg-[color:#34B9FF] border-transparent text-white",
    isSelected: false,
  },
  {
    id: 6,
    label: "Finance",
    color:
      "bg-[color:#EBFEFF] text-[color:#2B9EA7] border-[color:#2B9EA7] dark:bg-[color:#092224]",
    colorActive: "bg-[color:#2B9EA7] border-transparent text-white",
    isSelected: false,
  },
];

type QuestionTag = {
  id: number;
  label: string;
  color: string;
  colorActive: string;
  isSelected: boolean;
};
