
import { defineComponent, nextTick, watch, computed, ref } from "vue";
import { useViewerFiltresStore } from "@/stores/viewer";
import BBtn from "@/components/global/BBtn.vue";
import BDropdown from "@/components/global/BDropdown.vue";
import MenuClose from "@/components/global/icons/BIconMenuClose.vue";
import BimIcon from "@/components/viewer/BimIcon.vue";

const typesAvailable = [
  "Mur",
  "Murs",
  "Poteau",
  "Poutre",
  "Dalle",
  "Toiture",
  "Coque",
  "Escalier",
  "Garde-corps",
  "Murs rideau",
  "Porte",
  "Fenêtre",
  "Ouverture de toit",
  "Forme",
  "Percement",
  "Zone",
  "Maillage",
  "Objet",
  "Lampe",
  "Equipement",
  "Fenêtre d’angle",
  "Tête de mur",
  "Marches",
  "Contre-marches",
  "Structure d’escalier",
  "Balustre",
  "Balustres",
  "Noeud",
  "Noeuds",
  "Panneau",
  "Panneaux",
  "Main courante",
  "Segment de poutres",
  "Segment de poteau",
].sort((a, b) => a.localeCompare(b));

export default defineComponent({
  setup() {
    const pulse = ref(false);

    const filtersStore = useViewerFiltresStore();

    const types = computed(() => filtersStore.types);

    const { addType, removeType, isFilterType } = filtersStore;

    const onChange = (name: string) => {
      if (isFilterType(name)) {
        removeType(name);
      } else {
        addType(name);
      }
    };

    const onCheckAll = () => {
      filtersStore.types = [];
    };

    const onUncheckAll = () => {
      filtersStore.types = [...typesAvailable];
    };

    watch(
      () => types.value.length,
      async () => {
        pulse.value = true;
        await nextTick();
        setTimeout(() => (pulse.value = false), 200);
      }
    );

    return {
      pulse,

      types,
      typesAvailable,

      onChange,
      onCheckAll,
      onUncheckAll,

      isFilterType,
    };
  },
  components: {
    BBtn,
    BimIcon,
    BDropdown,
    MenuClose,
  },
});
