
import { defineComponent } from "vue";

export default defineComponent({
  name: "b-overlay",
  props: {
    show: {
      type: Boolean,
      default: () => true,
    },
    hoverAll: Boolean,
  },
});
