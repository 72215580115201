
import { defineComponent } from "vue";
import { Pane } from "splitpanes";

export default defineComponent({
  name: "app-sidepanel",
  props: {
    /**
     * Taille du Pane en pourcentage
     */
    size: Number,
  },
  setup() {
    return {};
  },
  components: {
    Pane,
  },
});
