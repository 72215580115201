import { Bimcradle } from "@/@types/bimcradle";
import {
  ElementIfc,
  ElementStatut,
  useFichiersCaoStore,
  useViewerFiltresStore,
  useViewerSectionsStore,
  useViewerStore,
} from "@/stores/viewer";
import { AssociativeUtils } from "@/utils";
import { reactiveComputed } from "@vueuse/core";
import { nextTick, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { isDev } from "@/utils";

const VIEWER_PATH = isDev() ? "/_viewer/index.html" : "/web/_viewer/index.html";
const VIEWER_SANDBOX = [
  "allow-same-origin",
  "allow-scripts",
  "allow-popups",
  "allow-forms",
] as const;

/**
 * Instance de l'onglet du viewer
 */
const viewerWindow = ref<Window>(null);

/**
 * Instance de BIMCradle, ne peut pas s'utilisée sans etre initialisée et si 'isReady' est a faux
 */
const bimcradle = ref<Bimcradle>(null);

/**
 * Pour les lourdes tâches, on peut attendre que le travail soit fini
 */
const isWorking = ref(false);

/**
 * Apres initialisation (attention, ne veut pas dire que la maquette est prete seulement la scene!)
 */
const isReady = ref(false);

/**
 * Apres initialisation, une fois que toutes les données initiale sont récupéré
 */
const isLoaded = ref(false);

export function useViewer() {
  const route = useRoute();
  const router = useRouter();

  /**
   * Ouvre un viewer dans nouvelle onglet
   * @param uidFichierCao
   */
  const open = (uidFichierCao: string, uidOuvrage?: string, popup = false) => {
    let endpoint = "";
    if (!isDev()) {
      endpoint += "web/";
    }
    endpoint += `viewer#/${uidFichierCao}`;
    if (uidOuvrage) {
      endpoint += `/ouvrages/${uidOuvrage}/articles`;
    }

    if (viewerWindow.value && !viewerWindow.value.closed) {
      viewerWindow.value.location = `${window.location.origin}/${endpoint}`;
    } else {
      viewerWindow.value = window.open(
        `${window.location.origin}/${endpoint}`,
        "Viewer IFC",
        popup ? "popup" : null
      );
    }

    viewerWindow.value.focus();
  };

  /**
   * Recupere l'instance de bimcradle
   * @param frame
   * @returns
   */
  const initialise = async (
    frame: HTMLIFrameElement,
    config?,
    maxTry = 100
  ) => {
    isReady.value = false;

    let i = 0;
    if (frame) {
      if (config) {
        frame.height = config.height;
        frame.width = config.width;
      } else {
        frame.height = "100%";
        frame.width = "100%";
      }

      frame.src = VIEWER_PATH;
      frame.sandbox.add(...VIEWER_SANDBOX);

      await nextTick();

      const iframeDoc = frame.contentWindow as any;
      while (!iframeDoc.bimcradle) {
        if (i++ == maxTry) break;
        await new Promise((resolve) => setTimeout(resolve, 75));
      }
      if (iframeDoc.bimcradle) {
        bimcradle.value = iframeDoc.bimcradle;
        isReady.value = true;
        return true;
      }
    }
    return false;
  };

  const loadMaquette = async (
    urlXkt: string,
    urlJson: string,
    idModel?
  ): Promise<boolean> => {
    if (bimcradle.value) {
      return new Promise((resolve) => {
        bimcradle.value.subscribe("loaded", () => {
          bimcradle.value.unsubscribe("loaded");
          resolve(true);
        });
        bimcradle.value.loadModel({
          id: idModel ?? "model",
          src: urlXkt,
          metaModelSrc: urlJson,
        });
      });
    }
    return false;
  };

  const getParentIfcByElementIfc = async (ifc: string): Promise<string> => {
    const { getJson } = useFichiersCaoStore();
    const json = await getJson;
    if (json && json.metaObjects) {
      const child = json.metaObjects.find((x) => x.id == ifc);
      if (child) {
        const parent = json.metaObjects.find((x) => x.id == child.parent);
        if (parent) {
          return parent.id;
        }
      }
    }

    return null;
  };

  const getChildrenIfcByElementIfc = async (ifc: string): Promise<string[]> => {
    const { getJson } = useFichiersCaoStore();
    const json = await getJson;
    if (json && json.metaObjects) {
      return json.metaObjects.filter((x) => x.parent == ifc).map((x) => x.name);
    }

    return null;
  };

  const getElementName = async (ifc: string): Promise<string> => {
    const { getJson } = useFichiersCaoStore();
    const json = await getJson;
    if (json && json.metaObjects) {
      const result = json.metaObjects.find((x) => x.id == ifc);
      if (result) {
        return result.name;
      }
    }
    return null;
  };

  /**
   * Recupere toutes les instances de la maquette IFC
   */
  const getAllIfc = (): string[] => {
    if (bimcradle.value) {
      return bimcradle.value.viewer.scene.objectIds;
    }
    return [];
  };

  /**
   * Recupere toutes les instances de la maquette IFC
   */
  const getInstances = () => {
    const instances = [];
    if (bimcradle.value) {
      bimcradle.value
        .getClassesWithInstances()
        .ifcClasses.forEach((element) => {
          if (element.instances) {
            instances.push(...element.instances);
          }
        });
    }
    return instances;
  };

  /**
   * Indique si l'ifc existe dans la maquette
   * @param ifc
   * @returns
   */
  const isIfc = (ifc: string): boolean => {
    if (bimcradle.value) {
      return (
        bimcradle.value.viewer.scene.objectIds.findIndex((o) => o == ifc) > -1
      );
    }
    return false;
  };

  const fitAll = () => {
    if (bimcradle.value) {
      bimcradle.value.zoomToFit(bimcradle.value.viewer.scene.visibleObjectIds);
    }
  };

  const fitSelection = () => {
    if (bimcradle.value) {
      bimcradle.value.zoomToFit(bimcradle.value.getSelection().elements);
    }
  };

  const fitHighlight = () => {
    if (bimcradle.value) {
      bimcradle.value.zoomToFit(bimcradle.value.getHighlight().elements);
    }
  };

  const xrayAll = () => {
    if (bimcradle.value) {
      bimcradle.value.setXRay(getAllIfc());
    }
  };

  const manageView = () => {
    const { elements } = useViewerFiltresStore();
    const { getExcludeZoneIfc } = useFichiersCaoStore();
    const { reverse, xray } = useViewerStore();
    const excludeIfc = [...getExcludeZoneIfc];

    if (bimcradle.value) {
      let el: ElementIfc;
      AssociativeUtils.forEach(bimcradle.value.viewer.scene.objects, (o) => {
        el = elements[o.id];
        // on gere les elements exclu par les prefs de l'études
        if (excludeIfc.indexOf(o.id as string) > -1) {
          o.visible = false;
        } else {
          if (el) {
            o.visible = reverse
              ? el.statut != ElementStatut.visible
              : el.statut == ElementStatut.visible;
          } else {
            o.visible = reverse ? false : true;
          }
        }

        o.xrayed = xray;
      });
    }
  };

  const getSectionPanelPlugin = () => {
    if (bimcradle.value && bimcradle.value.sectionPlanesPlugin) {
      return reactiveComputed(() => {
        return bimcradle.value.sectionPlanesPlugin;
      });
    }
    return null;
  };

  const getBcfViewpointsPlugin = () => {
    if (bimcradle.value) {
      return bimcradle.value.getBcfViewpointsPlugin();
    }
    return null;
  };

  /**
   * Prends une photo du viewer
   * @returns PNG en Base64
   */
  const getBcfImage = (): string => {
    const plugin = getBcfViewpointsPlugin();
    if (plugin) {
      const bcf = plugin.getViewpoint({
        spacesVisible: false,
        openingsVisible: false,
        spaceBoundariesVisible: false,
      });
      return bcf?.snapshot?.snapshot_data;
    }
    return null;
  };

  /**
   * Génere un BCF du viewer
   * @param includeViewerStates inclus les filtres actuelles + sections + données de la maquette au BCF
   * @returns
   */
  const getBcf = (includeViewerStates = true, includeNavigation = true) => {
    const plugin = getBcfViewpointsPlugin();
    if (plugin) {
      const bcf = plugin.getViewpoint({
        spacesVisible: false,
        openingsVisible: false,
        spaceBoundariesVisible: false,
        snapshot: false,
      });
      if (includeViewerStates) {
        const { $state: filtresState } = useViewerFiltresStore();
        const { $state: sectionsState } = useViewerSectionsStore();
        const { projet, fichierCao, maquetteConsultation, consultation } =
          useFichiersCaoStore();

        bcf.bimoffice = {
          name: `bcf_${projet?.nom?.toLowerCase().trim()}_${fichierCao?.nom
            ?.toLowerCase()
            .trim()}`,
          projet,
          fichierCao: {
            uid: fichierCao.uid,
            nom: fichierCao.nom,
          },
          consultation,
          maquette: maquetteConsultation,
          filtres: filtresState,
          sections: sectionsState,
        };
      }
      if (includeNavigation) {
        if (bcf.bimoffice) {
          bcf.bimoffice.navigation = {
            name: route.name,
            params: route.params,
            query: route.query,
            path: route.path,
            fullPath: route.fullPath,
          };
        } else {
          bcf.bimoffice = {
            navigation: {
              name: route.name,
              params: route.params,
              query: route.query,
              path: route.path,
              fullPath: route.fullPath,
            },
          };
        }
      }
      return bcf;
    }
    return null;
  };

  /**
   * Charge un BCF dans le viewer
   * @param bcf
   * @param ignoreConflit pour pouvoir ignorer uid projet courant différent de l'uid projet du bcf
   * @returns
   */
  const loadBcf = async (
    bcf,
    useNavigation = true,
    ignoreConflit = false
  ): Promise<boolean> => {
    const plugin = getBcfViewpointsPlugin();
    if (plugin && bcf) {
      // on regarde s'il y a des données de la maquette
      if (bcf.bimoffice) {
        const { $patch: patchFiltres } = useViewerFiltresStore();
        const { $patch: patchSections } = useViewerSectionsStore();
        const { projet, fichierCao, maquetteConsultation } =
          useFichiersCaoStore();

        if (!ignoreConflit) {
          // On regade si il s'agit du meme projet, si non on ne charge pas le BCF
          if (bcf.bimoffice.projet?.uid != projet.uid) {
            return false;
          }
          if (bcf.bimoffice.fichierCao?.uid != fichierCao.uid) {
            return false;
          }
          if (bcf.bimoffice.maquette?.uid != maquetteConsultation.uid) {
            return false;
          }
        }
        patchFiltres(bcf.bimoffice.filtres);
        patchSections(bcf.bimoffice.sections);

        if (bcf.bimoffice.navigation) {
          if (useNavigation) {
            router.push({
              name: bcf.bimoffice.navigation.name,
              params: bcf.bimoffice.navigation.params,
              query: bcf.bimoffice.navigation.query,
              path: bcf.bimoffice.navigation.path,
            });
          }
        }
      }

      plugin.setViewpoint(bcf);
      return true;
    }
    return false;
  };

  return {
    bimcradle,
    isWorking,
    isReady,
    isLoaded,

    open,
    initialise,
    loadMaquette,

    manageView,

    getChildrenIfcByElementIfc,
    getParentIfcByElementIfc,
    getInstances,
    getAllIfc,
    getElementName,

    getBcf,
    getBcfImage,
    loadBcf,

    isIfc,

    fitAll,
    fitSelection,
    fitHighlight,

    xrayAll,

    getSectionPanelPlugin,
    getBcfViewpointsPlugin,
  };
}
