import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import { defineStore } from 'pinia'


export const useViewerStore = defineStore('viewer', {
    state: () => {
        const breakpoints = useBreakpoints(breakpointsTailwind)
        return {
            sidePanelSize: breakpoints.isSmaller("md") ? 0 : 30,
            collapseToolbar: false,

            dark: false,
            xray: false,
            hideOnClick: false,
            reverse: false,
        }
    },
    getters: {
        mainPanelSize(state) {
            return 100 - state.sidePanelSize
        }
    },
    actions: {
        toggleReverse() {
            this.reverse = !this.reverse
        },
        toggleXray() {
            this.xray = !this.xray
        },
        toggleHideOnClick() {
            this.hideOnClick = !this.hideOnClick
        },
        toggleToolbar() {
            this.collapseToolbar = !this.collapseToolbar
        },
        toggleSidepanel() {
            const breakpoints = useBreakpoints(breakpointsTailwind)
            if (breakpoints.isSmaller("md")) {
                if (this.sidePanelSize > 0) {
                    this.sidePanelSize = 0;
                } else {
                    this.sidePanelSize = 100;
                }
            } else {
                if (this.sidePanelSize > 30) {
                    this.sidePanelSize = 30;
                } else {
                    this.sidePanelSize = 70;
                }
            }
        }
    }
})