
import { defineComponent, PropType, computed } from "vue";
import { SizeType, VariantType } from "./index";
import { concatClassCSS } from "@/utils";

export default defineComponent({
  name: "b-spinner",
  props: {
    variant: {
      type: String as PropType<VariantType | "current">,
      default: () => "primary",
    },
    size: {
      type: String as PropType<SizeType>,
      default: () => "md",
    },
    hideBackground: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props) {
    const getVariantClass = (variant: VariantType | "current") => {
      let Class = "";
      switch (variant) {
        case "primary":
          Class = "fill-primary text-primary-light";
          break;
        case "secondary":
          Class = "fill-secondary text-secondary-light";
          break;
        case "success":
          Class = "fill-success text-success-light";
          break;
        case "danger":
          Class = "fill-danger text-danger-light";
          break;
        case "light":
          Class = "fill-dark text-light";
          break;
        case "dark":
          Class = "fill-light text-dark";
          break;
        case "transparent":
          Class = "text-transparent";
          break;
        case "current":
          Class = "fill-currentColor text-light";
          break;
      }
      return Class;
    };

    const getSizeClass = (size: SizeType) => {
      let Class = "";
      switch (size) {
        case "xs":
          Class = "icon-size-4";
          break;
        case "sm":
          Class = "icon-size-6";
          break;
        case "md":
          Class = "icon-size-8";
          break;
        case "lg":
          Class = "icon-size-10";
          break;
        case "xl":
          Class = "icon-size-12";
          break;
        case "2xl":
          Class = "icon-size-14";
          break;
        case "4xl":
          Class = "icon-size-16";
          break;

        case "full":
          Class = "flex-1";
          break;

        case "auto":
          Class = "";
          break;
      }
      return Class;
    };

    return {
      spinnerClass: computed(() =>
        concatClassCSS(
          "animate-spin",
          getSizeClass(props.size),
          getVariantClass(props.variant)
        )
      ),
    };
  },
});
