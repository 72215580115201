import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.iconComponent)
    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.iconComponent), {
        key: 0,
        size: _ctx.size
      }, null, 8, ["size"]))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(["m-0.5", _ctx.getSizeClass(_ctx.size)])
      }, null, 2))
}