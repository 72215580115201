import { defineStore } from "pinia";

const STORE_KEY = "ao-options-store-key";

export const useOptionsStore = defineStore("ao-options-store", {
  state: () => {
    const storedOptions = JSON.parse(localStorage.getItem(STORE_KEY));
    return {
      eventsOptions: storedOptions || { scroll: { questions: 0 } },
    };
  },
  getters: {},
  actions: {
    setEventsOptions(op) {
      const key = Object.keys(op)[0];
      switch (key) {
        case "scroll":
          this.eventsOptions.scroll = {
            ...this.eventsOptions.scroll,
            ...op.scroll,
          };
          break;
        default:
          break;
      }
      localStorage.setItem(STORE_KEY, JSON.stringify(this.eventsOptions));
    },
  },
});
