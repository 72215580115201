import { IProjet } from "@/@models/projets";
import { projetsService } from "@/api";
import { checkData, isValidStatus } from "@/utils";
import { ref } from "vue";
import { useToast } from "vue-toastification";

export function useProjets() {

  const projets = ref<IProjet[]>([]);
  const loading = ref<boolean>(false);
  const isFetched = ref<boolean>(false);

  const fetchError = ref<Error>(null);

  async function fetch() {
    const toastification = useToast();

    loading.value = true;
    fetchError.value = null;

    try {
      const { status, data } = await projetsService.getProjets();

      if (!isValidStatus(status) || !checkData(data)) {
        projets.value = [];
        toastification.error(
          "La récupération de la liste des projets a échouée."
        );
      } else {
        projets.value = data.projets;
        isFetched.value = true;
      }
    } catch (err) {
      projets.value = [];
      fetchError.value = err;
    } finally {
      loading.value = false;
    }
  }

  const reset = () => {
    projets.value = [];
    isFetched.value = false;
    loading.value = false;
    fetchError.value = null;
  }

  return {
    projets,
    loading,

    isFetched,
    fetchError,

    fetch,
    reset
  };
}
