import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "m-0.5 inline-flex justify-center fill-current" }
const _hoisted_2 = ["viewBox", "aria-labelledby", "fill"]
const _hoisted_3 = { fill: "current" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("i", _hoisted_1, [
    (_openBlock(), _createElementBlock("svg", {
      xmlns: "http://www.w3.org/2000/svg",
      class: _normalizeClass(_ctx.iconClass),
      viewBox: _ctx.$attrs.viewBox as string ?? '0 0 16 16',
      "aria-labelledby": _ctx.name,
      role: "presentation",
      fill: _ctx.fill
    }, [
      _createElementVNode("g", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ], 10, _hoisted_2))
  ]))
}