
import {
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  watch,
  computed,
  ref,
} from "vue";
import BBtn from "@/components/global/BBtn.vue";
import BDropdown from "@/components/global/BDropdown.vue";
import { Close, MenuClose, Hide, HideBis } from "@/components/global/icons";

import { useViewerSectionPlugin } from "@/composables/viewer";
import { useViewerSectionsStore } from "@/stores/viewer";
import { useRoute } from "vue-router";

export default defineComponent({
  setup() {
    const route = useRoute();

    const pulse = ref(false);

    const { fetchSectionsFromPlugin, storeSections } = useViewerSectionsStore();

    const {
      subscribe,
      unsubscribe,
      getSections,
      createSection,
      deleteSection,
      setControl,
      hasControl,
      deleteAll,
    } = useViewerSectionPlugin();

    const visibleSections = computed(() =>
      getSections().filter((s) => s.active)
    );

    onMounted(() => {
      subscribe("created", handleSectionUpdate);
      subscribe("deleted", handleSectionUpdate);
      subscribe("updated", handleSectionUpdate);
    });

    onUnmounted(() => {
      unsubscribe("created");
      unsubscribe("deleted");
      unsubscribe("updated");
    });

    const onDeleteClick = (section) => {
      if (section) deleteSection(section.id);
    };

    const toggleControl = (section) => {
      if (section && !hasControl(section.id)) {
        setControl(section.id);
      } else {
        setControl();
      }
      handleSectionUpdate();
    };

    const handleSectionUpdate = () => {
      fetchSectionsFromPlugin();
      storeSections((route.params.idDoc || route.params.idFichier) as string);
    };

    watch(
      () => visibleSections.value.length,
      async () => {
        pulse.value = true;
        await nextTick();
        setTimeout(() => (pulse.value = false), 200);
      }
    );

    return {
      pulse,
      visibleSections,

      getSections,
      deleteAll,
      createSection,
      onDeleteClick,
      hasControl,
      toggleControl,
      handleSectionUpdate,
    };
  },
  components: {
    BBtn,
    BDropdown,
    Close,
    MenuClose,
    Hide,
    HideBis,
  },
});
