import {
  VuexModule,
  Module,
  Action,
  Mutation,
  getModule,
} from "vuex-module-decorators";
import store, { storage } from "@/pages/bureau/store";
import { authService } from "@/pages/bureau/api/auth.api";
import { getErrorMessage, getUrlParams, isValidStatus } from "@/utils";
import { ISociete } from "@/pages/bureau/@models/societe.model";
import { RouteLocation, RouteRecord } from "vue-router";
import { blobToBase64 } from "./intervenants.module";
import { SocietesModule } from "./societes.module";
import { IUserState, UserRole } from "@/pages/bureau/@models/user.model";



// TEMP
const idMachine = "1";

@Module({ dynamic: true, store, name: "user", namespaced: true })
class User extends VuexModule implements IUserState {
  // Auth
  public token = "";
  public idMachine = "";

  public nom = "";
  public prenom = "";
  public index = 0;
  public imageB64 = "";

  public roles: UserRole[] = [];
  public email = "";

  public rememberMe = true;
  public societe: ISociete = null;
  public userLogin = "";

  //#region Mutations

  @Mutation
  private SET_NOM(nom: string) {
    this.nom = nom;
  }

  @Mutation
  private SET_PRENOM(prenom: string) {
    this.prenom = prenom;
  }

  @Mutation
  private SET_INDEX(index: number) {
    this.index = index;
  }

  @Mutation
  private SET_TOKEN(token: string) {
    this.token = token;
  }

  @Mutation
  private SET_REMEMBER_ME(value: boolean) {
    this.rememberMe = value;
  }

  @Mutation
  private SET_SOCIETE(societe: ISociete) {
    this.societe = societe;
  }

  @Mutation
  private SET_USER_LOGIN(login: string) {
    this.userLogin = login;
  }

  @Mutation
  private SET_ROLES(roles: UserRole[]) {
    this.roles = roles;
  }

  @Mutation
  private ADD_ROLE(role: UserRole) {
    this.roles.push(role);
  }

  @Mutation
  private CLEAR_ALL() {
    this.token = "";
    this.idMachine = "";
    this.nom = "";
    this.prenom = "";
    this.roles = [];
    this.email = "";
    this.rememberMe = true;
    this.societe = null;
    this.userLogin = "";
  }

  //#endregion

  //#region Actions

  /**
   * Recupere le token de l'utilisateur
   */
  @Action({ rawError: true })
  public async Login(params: {
    login: string;
    password: string;
    societe: ISociete;
    rememberMe: boolean;
  }) {
    const { status, data, request } = await authService.login(
      params.login,
      params.password,
      params.societe.index,
      idMachine
    );
    if (!isValidStatus(status) || !data.token) {
      this.ResetToken();
      throw Error(getErrorMessage(request) ?? "L'authentification à échoué !");
    }
    this.SET_TOKEN(data.token);
    this.SET_ROLES([UserRole.logged]);
    if (data.user) {
      this.SET_PRENOM(data.user.prenom);
      this.SET_NOM(data.user.nom);
      this.SET_INDEX(data.user.index);
      this.fetchUserImage();
    }

    this.SET_REMEMBER_ME(params.rememberMe);
    if (this.rememberMe) {
      this.SET_SOCIETE(params.societe);
      this.SET_USER_LOGIN(params.login);
    } else {
      this.SET_SOCIETE(null);
      this.SET_USER_LOGIN("");
    }
  }

  @Mutation
  public SET_IMAGE_B64(b64: string) {
    this.imageB64 = b64;
  }

  @Action({ rawError: true })
  public async fetchUserImage() {
    const data = await authService.fetchUserImage();
    if (data instanceof Blob) {
      const b64 = await blobToBase64(data);
      this.SET_IMAGE_B64(b64);
    }
  }

  @Action({ rawError: true })
  public async setFakeUser() {
    //
    this.SET_PRENOM("John");
    this.SET_NOM("SMITH");
    this.SET_IMAGE_B64(
      "data:image/svg+xml;base64," +
        "PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI1LjQuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9ImdyaXNfYWN0aWYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxNiAxNS45OTMiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDE2IDE1Ljk5MzsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLmFjdGlme2ZpbGw6IzYzNjg3QTt9Cjwvc3R5bGU+CjxnIGlkPSJHcm91cGVfNjFfMl8iIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yMTIuNDEyIC02MC4yODMpIj4KCTxnIHRyYW5zZm9ybT0ibWF0cml4KDEsIDAsIDAsIDEsIDIxMi40MSwgNjAuMjgpIj4KCQk8ZyBpZD0iRWxsaXBzZV8xLTJfMl8iPgoJCQk8Y2lyY2xlIGNsYXNzPSJhY3RpZiIgY3g9IjguMDY5IiBjeT0iMy4yMyIgcj0iMy4xOTciLz4KCQk8L2c+Cgk8L2c+Cgk8ZyB0cmFuc2Zvcm09Im1hdHJpeCgxLCAwLCAwLCAxLCAyMTIuNDEsIDYwLjI4KSI+CgkJPGcgaWQ9IlRyYWPDqV83NC0yXzJfIj4KCQkJPHBhdGggY2xhc3M9ImFjdGlmIiBkPSJNMTMuNTI5LDEzLjc1MnYtMS4xNjljMC4wMDEtMS4yOTEtMC40NTgtMi41NC0xLjI5NC0zLjUyNGwtMC4zNTUtMC4zOEM5LjcyOCw2LjU2OSw2LjI3NCw2LjYwNCw0LjE2NSw4Ljc1NgoJCQkJYy0xLjAwMSwxLjAyMi0xLjU2MSwyLjM5Ni0xLjU1OSwzLjgyNnYzLjEzYzAsMC4xNDMsMC4xMTYsMC4yNTksMC4yNTksMC4yNTlIMTMuMjdjMC4xNDMsMCwwLjI1OS0wLjExNiwwLjI1OS0wLjI1OXYtMS40NDMiLz4KCQk8L2c+Cgk8L2c+CjwvZz4KPC9zdmc+Cg=="
    );

    try {
      const societeIndex = parseInt(
        getUrlParams().find((p) => p[0] === "societe")?.[1]
      );
      const societe = SocietesModule.societes.find(
        (s) => s.index === societeIndex
      );
      if (societe) {
        this.SET_SOCIETE(societe);
      } else {
        throw Error("Societe non trouvée");
      }
    } catch (error) {
      this.SET_SOCIETE({
        index: 1,
        nom: "Societe Exemple",
      });
    }
  }

  /**
   * Supprime toutes les données lié à l'utilisateur pour la déconnexion
   */
  @Action({ rawError: true })
  public async Logout() {
    await storage.clear();
    location.reload();
  }

  @Action
  public ResetToken() {
    this.SET_ROLES([]);
    this.SET_TOKEN("");
  }

  //#endregion

  //#region Getters

  get getToken(): string {
    return this.token;
  }

  get getIdMachine(): string {
    return idMachine;
  }

  get userRoles(): UserRole[] {
    return this.roles;
  }

  get getUserNomPrenom(): string {
    return `${this.nom} ${this.prenom}`;
  }

  get getUserNom(): string {
    return this.nom;
  }

  get getUserPrenom(): string {
    return this.prenom;
  }
  get getUserIndex(): number {
    return this.index;
  }

  get getUserEmail(): string {
    return this.email;
  }

  get getUserSociete(): string {
    return this.societe ? this.societe.nom : "";
  }

  get infoLogin(): {
    rememberMe: boolean;
    societe: ISociete;
    login: string;
  } {
    return {
      login: this.userLogin,
      societe: this.societe,
      rememberMe: this.rememberMe,
    };
  }

  /**
   * Compare les roles à ceux d'une route, si 1 en commun alors vrai
   * Si la route n'a pas de roles defini, alors retourne vrai
   * @param route
   * @param roles
   * @returns
   */
  get checkRoles() {
    return (route: RouteLocation | RouteRecord): boolean => {
      if (route?.meta?.roles) {
        return this.userRoles.some(
          (r) => (route?.meta?.roles as UserRole[]).indexOf(r) >= 0
        );
      }
      return true;
    };
  }

  get isAdmin() {
    return this.userRoles.includes(UserRole.admin);
  }

  //#endregion
}

export const UserModule = getModule(User);
