import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_BSpinner = _resolveComponent("BSpinner")!

  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: _normalizeClass(_ctx.btnClass.main),
    disabled: _ctx.disabled,
    style: _normalizeStyle(          (_ctx.forcedBgColor ? `background-color: ${_ctx.forcedBgColor}` : '')
+
          ';' +
          
          (_ctx.forcedTextColor ? `color: ${_ctx.forcedTextColor}` : '') 
        )
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_component_BIcon, {
          key: 0,
          class: _normalizeClass(["text-current", _ctx.btnClass.icon]),
          size: _ctx.btnClass.iconSize,
          name: _ctx.icon
        }, null, 8, ["class", "size", "name"]))
      : _createCommentVNode("", true),
    (_ctx.hasAppendSlot)
      ? _renderSlot(_ctx.$slots, "append", { key: 1 })
      : _createCommentVNode("", true),
    (_ctx.hasDefaultSlot && _ctx.type != 'fab')
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_BSpinner, {
                key: 0,
                class: "-pl-2 mr-2",
                size: 'xs',
                variant: _ctx.variant
              }, null, 8, ["variant"]))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "default")
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.hasPrependSlot)
      ? _renderSlot(_ctx.$slots, "prepend", { key: 3 })
      : _createCommentVNode("", true)
  ], 14, _hoisted_1))
}