
import { defineComponent, watch, ref, nextTick, computed } from "vue";
import { useFichiersCaoStore, useViewerFiltresStore } from "@/stores/viewer";
import BBtn from "@/components/global/BBtn.vue";
import BDropdown from "@/components/global/BDropdown.vue";
import MenuClose from "@/components/global/icons/BIconMenuClose.vue";

export default defineComponent({
  setup() {
    const pulse = ref(false);

    const fichierCaoStore = useFichiersCaoStore();
    const filtersStore = useViewerFiltresStore();

    const stories = computed(() => fichierCaoStore.stories);
    const floors = computed(() => filtersStore.floors);

    const { addFloor, removeFloor, isFilterFloor } = filtersStore;

    const onChange = (name: string) => {
      if (isFilterFloor(name)) {
        removeFloor(name);
      } else {
        addFloor(name);
      }
    };

    const onUncheckAll = () => {
      filtersStore.floors = stories.value.map((x) => x.Name);
    };

    const onCheckAll = () => {
      filtersStore.floors = [];
    };

    watch(
      () => floors.value.length,
      async () => {
        pulse.value = true;
        await nextTick();
        setTimeout(() => (pulse.value = false), 200);
      }
    );

    return {
      pulse,
      stories,
      floors,

      onChange,
      onCheckAll,
      onUncheckAll,

      isFilterFloor,
    };
  },
  components: {
    BBtn,
    BDropdown,
    MenuClose,
  },
});
