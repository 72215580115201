import nprogress from "nprogress";

nprogress.configure({
    showSpinner: false,
    parent: '#app'
});


import router from "../dashboard/router"

router.beforeEach(async (to, from, next) => {
    nprogress.start();
    nprogress.inc();


    next();
})

router.afterEach(() => {
    nprogress.done();

})