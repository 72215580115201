
import { defineComponent, computed } from "vue";
import { ElementStatut, useViewerFiltresStore } from "@/stores/viewer";
import BBtn from "@/components/global/BBtn.vue";
import BDropdown from "@/components/global/BDropdown.vue";
import Close from "@/components/global/icons/BIconClose.vue";
import MenuClose from "@/components/global/icons/BIconMenuClose.vue";
import { AssociativeUtils } from "@/utils";

export default defineComponent({
  setup() {
    const filtersStore = useViewerFiltresStore();

    const hiddens = computed(() =>
      AssociativeUtils.filter(
        filtersStore.elements,
        (e) => e.hideOnClick == true
      )
    );

    const { toggleElementVisibility } = filtersStore;

    const onCheckAll = () => {
      AssociativeUtils.filter(
        filtersStore.elements,
        (e) => e.hideOnClick == true
      ).forEach((e) => e.statut == ElementStatut.visible);
    };

    return {
      hiddens,

      onCheckAll,
      toggleElementVisibility,
    };
  },
  components: {
    BBtn,
    BDropdown,
    Close,
    MenuClose,
  },
});
