import axios from "./index";

const BASE_ENDPOINT = "BUREAU";

export const accessService = {
  async getAccessOf(code: string) {
    return (
      await axios.get<{
        access: boolean;
      }>(`${BASE_ENDPOINT}/ACCESS/${code}`)
    )?.data?.access;
  },
};
