import { boxService } from "@/api/box";
import { awaitData, isValidStatus } from "@/utils";
import axios from "axios";
import { defineStore } from "pinia";

export const useBoxStore = defineStore("box", {
  state: () => {
    const access_token = localStorage.getItem("box_access_token");
    const refresh_token = localStorage.getItem("box_refresh_token");
    const access_token_timestamp = localStorage.getItem(
      "box_access_token_timestamp"
    );
    const clientID = "";
    const clientSecret = "";

    return {
      access_token: access_token ?? "",
      refresh_token: refresh_token ?? "",
      access_token_timestamp: access_token_timestamp ?? 0,
      clientID,
      clientSecret,
    };
  },
  actions: {
    async getLoginUrl() {
      await this.checkClient();
      console.log("getLoginUrl", boxService.getLoginUrl(this.clientID));
      return boxService.getLoginUrl(this.clientID);
    },
    async getAccessToken() {
      if (this.access_token_timestamp <= new Date().getTime()) {
        await this.refreshToken();
      }

      return this.access_token;
    },
    async getBoxSettings() {
      const { status, data } = await boxService.getBoxSettings();
      if (!isValidStatus(status)) {
        console.error("Erreur lors de la récupération des paramètres de Box");
        this.clientID = "";
        this.clientSecret = "";
      }
      this.clientID = data?.clientID ?? "";
      this.clientSecret = data?.clientSecret ?? "";
      return data;
    },

    setAccessToken(token: string, timestamp = 0) {
      this.access_token = token;
      localStorage.setItem("box_access_token", token);
      this.access_token_timestamp = timestamp;
      localStorage.setItem("box_access_token_timestamp", timestamp + "");
    },
    setRefreshToken(token: string) {
      this.refresh_token = token;
      localStorage.setItem("box_refresh_token", token);
    },

    clearTokens() {
      this.setAccessToken("");
      this.setRefreshToken("");
    },

    async fetchTokenWithCode(code: string) {
      await this.checkClient();
      const res = await boxService.getTokenWithCode(
        code,
        this.clientID,
        this.clientSecret
      );
      if (res.status !== 200) {
        console.error("Erreur lors de la récupération du token");
        return;
      }
      this.setAccessToken(res.data?.access_token);
      this.setRefreshToken(res.data?.refresh_token);
    },
    async checkClient() {
      if (this.clientID === "" || this.clientSecret === "") {
        await this.getBoxSettings();
      }
      await awaitData(() => this.clientID !== "" && this.clientSecret !== "");

    },
    async refreshToken() {
      await awaitData(() => this.refresh_token !== "");
      await this.checkClient();
      const res = await boxService.refreshToken(
        this.refresh_token,
        this.clientID,
        this.clientSecret
      );

      if (res.status == 200) {
        const timestamp = new Date().getTime() + res.data?.expires_in * 1000;
        this.setAccessToken(res.data?.access_token, timestamp);
        this.setRefreshToken(res.data?.refresh_token);
      }

      return res.data;
    },
    async getListOfElements(id: string) {
      const res = await boxService.getList(
        id,
        true,
        await this.getAccessToken()
      );

      return res?.data?.box_data;
    },

    async getUserInfos() {
      const res = await boxService.getUserInfos(await this.getAccessToken());
      return res?.data?.box_data;
    },
  },
});
