
import useLoader from "@/pages/bureau/composables/useLoader";
import { defineComponent } from "vue";

export default defineComponent({
  name: "empty-layout",
  setup() {
    const { loading } = useLoader();

    return {
      loading
    }
  }
});
