import { isValidStatus } from "@/utils";
import { societesService } from "@/pages/bureau/api/societes.api";

export async function fetchPolices(polices: string[]) {
  for (const police of polices) {
    //on verifie si la police n'est pas deja chargée
    if (!document.getElementById(police)) {
      const nomPolice = police.replace(/ /g, "_");
      const { status, data } = await societesService.getPolice(nomPolice);
      if (isValidStatus(status)) {
        //dans data, on aura b64 qui est la police en base64
        //on cherche donc a ajouter au projet de nouvelles polices a chaque fois sans supprimer les anciennes
        const style = document.createElement("style");
        style.innerHTML = `
        @font-face {
          font-family: "${police}";
          src: url(data:font/truetype;base64,${data.b64})  format("truetype");
        }
      `;
        style.id = police;
        document.head.appendChild(style);
      }
    }
  }
}

export function getPolicesArrayInBalise(balise: string) {
  const regex = /font-family:'(.*?)'/g;
  const matches = balise.match(regex);
  if (matches) {
    return matches.map((m) => m.replace(/font-family:'(.*?)'/, "$1"));
  }
  return [];
}
