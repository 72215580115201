
import { defineComponent, computed } from "vue";
import { useViewerLoaderInfo } from "@/composables/viewer";
import { Done, Cancel, Close } from "@/components/global/icons";
import { BBtn } from "@/components/global";

export default defineComponent({
  setup() {
    const { maxSteps, primarySteps, secondarySteps, hasError } =
      useViewerLoaderInfo();

    const stepDone = computed(
      () =>
        [...primarySteps.value, ...secondarySteps.value].filter(
          (x) => x.state == "done" || x.state == "echec"
        ).length
    );

    const isCriticError = () => {
      return !!primarySteps.value.find((s) => s.state == "echec");
    };

    const reload = () => {
      location.reload();
    };

    return {
      hasError,
      stepDone,
      maxSteps,
      primarySteps,
      secondarySteps,

      isCriticError,
      reload,
    };
  },
  components: {
    Done,
    Cancel,
    Close,
    BBtn,
  },
});
