import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, {
    "enter-active-class": "ease-out duration-200",
    "enter-from-class": "opacity-0",
    "enter-to-class": "opacity-100",
    "leave-active-class": "ease-out duration-200",
    "leave-from-class": "opacity-100",
    "leave-to-class": "opacity-0"
  }, {
    default: _withCtx(() => [
      (_ctx.show)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["fixed inset-0 flex flex-grow cursor-wait bg-dark bg-opacity-20 backdrop-grayscale dark:bg-light dark:bg-opacity-20", [_ctx.hoverAll ? 'z-999999' : 'z-40']])
          }, [
            _renderSlot(_ctx.$slots, "default")
          ], 2))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}