import { ILocalisationIntervenants } from "@/pages/bureau/@types/localisation";

const localisationIntervenants: ILocalisationIntervenants = {
  list: {
    title: "Intervenants",
    job: "Qualité",
    filter: "Filtrer",
    total: "{total} Intervenant(s)",
    empty: "Aucun intervenant trouvé",
    noCity: "Non renseigné",
    noJob: "Non renseigné",
  },
  detail: {
    title: "Detail",
    logo: "Logo",
    contact: "Contact",
    docs: "Documents",
    email: "Email",
    job: "Qualité",
    jobs: "Corps de metier",
    localisation: "Localisation",
    mobile: "Portable",
    name: "Appellation",
    phone: "Téléphone",
    tags: "Tags",
    empty: "Non renseigné",
    allWorks: "Tous type de travaux",
    heavyWorks: "Gros travaux uniquement",
    heavyMediumWorks: "Gros et moyens travaux",
    heavyLightWorks: "Gros et petits travaux",
    mediumWorks: "Moyens travaux uniquement",
    mediumLighWorks: "Moyens et petits travaux",
    lightWorks: "Petits travaux uniquement",
    noWorks: "Sur demande",
    decennal: "Décennale",
    resCivil: "Responsabilité civile",
    statut: "Statut particulier",
    insurance: "Assureur",
    numPolice: "N° de police",
    dateEnd: "Fin de validaté",
    active: "En cours",
    outdated: "Dépassement",
    outdatedCount: "{days} jours",
    exoneration: "Exonération RG",
    judiciaryProcess: "Procédure judiciaire",
    noExoneration: "Pas d'exonération",
    false: "Non",
    true: "Oui",
  },
  contacts: {
    title: "Contacts",
    noContact: "Pas de contact",
    form: "Formulaire",
    prenom: "Prénom",
    genre: "Genre",
    fonction: "Fonction",
  },
  new: {
    title: "Nouvel intervenant",
    success: "Intervenant créé",
    error: "Erreur lors de la création",
    colors: "Couleurs",

    titleGeneral: "Général",
    sigle: "Sigle / Civ",
    name: "Nom",
    statut: "Type d'entité",
    qualite: "Qualités",
    agence: "Agence",
    logo: "Logo",
    couleur: "Couleur",

    titleAddress: "Localisation",
    adresse: "Adresse",
    codePostal: "Code postal",
    ville: "Ville",
    departement: "Département",
    region: "Région",
    pays: "Pays",

    titleContact: "Contact",
    indicatif: "Indicatif",
    telephone: "Téléphone",
    portable: "Portable",
    fax: "Fax",
    email: "Email",
    url: "Site web",

    titlePro: "Professionnel",
    siret: "N° Siret",
    siren: "N° Siren",
    rcs: "N° RCS",
    naf: "Code NAF",
    capital: "Capital",
    formesJuridiques: "Forme juridique",
    tva: "N° TVA",

    titleLibre: "Champs libres",
    libre1: "Champ libre 1",
    libre2: "Champ libre 2",
    libre3: "Champ libre 3",
  },
  docs: {
    title: "Documents",
    noDoc: "Aucun document",
    new: "Nouveau document",
    details: "Détails",
  },
  newDoc: {
    nom: "Nom",
    dateValidite: "Date de fin de validité",
    statut: "Statut",
    commentaire: "Commentaire",
    type: "Type",
    fichier: "Fichier",
    libelle: "Libellé",
    ajouterFichier: "Ajouter un fichier",
    remplaceFichier: "Remplacer le fichier",
    numPolice: "N° de police",
    assureur: "Assureur",
    redressement: "Redressement",
    estRedressement: "Est en redressement",
  },
};

export default localisationIntervenants;
