import { IFichier } from '@/@models/fichier';
import { IBordereauCao, ILigneCao, ILotsCao, IOuvrageCao } from '@/@models/fichier-cao';
import { bimService } from '@/api/bim';
import { checkData, isValidStatus } from '@/utils';
import { defineStore } from 'pinia'
import { useFichiersCaoStore } from './viewer-fichiers-cao';

export const useOuvragesCaoStore = defineStore('ouvrages-cao', {
    state: () => {
        return {
            ouvrages: [] as IOuvrageCao[],
            lot: null as ILotsCao,
            bordereau: null as IBordereauCao,

            ouvrage: null as IOuvrageCao,
            ouvrageParent: null as IOuvrageCao,

            localisations: [] as ILigneCao[],
            documents: [] as IFichier[],
        }
    },
    getters: {
        getOuvrage: (state) => {
            return (uidOuvrage): IOuvrageCao => {
                const result = findOuvrageByUid(state.ouvrages, uidOuvrage)
                if (result) {
                    return result;
                }
                return null;
            }
        },
    },
    actions: {
        setOuvragesFromStore(uidTarget) {
            const caoStore = useFichiersCaoStore();
            this.ouvrages = [];
            if (caoStore.lots) {
                caoStore.lots.forEach(l => {
                    if (l.bordereaux) {
                        l.bordereaux.forEach(b => {
                            const result = findOuvrageByUid(b.ouvrages, uidTarget)
                            if (result) {
                                this.lot = l;
                                this.bordereau = b
                                this.ouvrages = b.ouvrages;
                                return;
                            }
                        })
                    }
                })
            }
            if (!this.ouvrages.length) {
                this.lot = null;
                this.bordereau = null;
            }
        },
        async fetchOuvrage(uidFichier, uidOuvrage) {
            const { status, data } = await bimService.getOuvrage(uidFichier, uidOuvrage);
            if (!isValidStatus(status) || !checkData(data)) {
                this.ouvrage = null;
                this.error = Error("La récupération de l'ouvrage a échoué.");
                return false;
            }
            this.ouvrage = data.ouvrage;
            return true;
        },
        async fetchOuvrageParent(uidFichier, uidOuvrage) {
            const { status, data } = await bimService.getOuvrage(uidFichier, uidOuvrage);
            if (!isValidStatus(status) || !checkData(data)) {
                this.ouvrageParent = null;
                this.error = Error("La récupération de l'ouvrage parent a échoué.");
                return false;
            }
            this.ouvrageParent = data.ouvrage;
            return true;
        },
        async fetchOuvrageLocalisations(uidFichier, uidOuvrage) {
            const { status, data } = await bimService.getOuvrageLocalisations(uidFichier, uidOuvrage);
            if (!isValidStatus(status) || !checkData(data)) {
                this.localisations = [];
                this.error = Error("La récupération des localisations de l'ouvrage a échoué.");
                return false;
            }
            this.localisations = data.localisations;
            return true;
        },
        async fetchOuvrageDocuments(uidFichier, uidOuvrage) {
            const { status, data } = await bimService.getOuvrageDocuments(uidFichier, uidOuvrage);
            if (!isValidStatus(status) || !checkData(data)) {
                this.documents = [];
                this.error = Error("La récupération des documents de l'ouvrage a échoué.");
                return false;
            }
            this.documents = data.documents;
            return true;
        },
    }
})


function findOuvrageByUid(arr: IOuvrageCao[], uid: string) {
    let matche = null;

    arr.forEach((x) => {
        if (x.uid == uid) {
            matche = x;
        }
        if (x.ouvrages) {
            const result = findOuvrageByUid(x.ouvrages, uid);
            if (result) {
                matche = result;
            }
        }
    })

    return matche;
}