import { ILocalisation } from "@/pages/bureau/@types/localisation";
import { createI18n } from "vue-i18n";
import frLocal from "./localisation/fr";

const i18n = createI18n<[ILocalisation], "fr-FR">({
  legacy: false,
  globalInjection: true,
  locale: (process.env.VUE_APP_I18N_LOCALE as string) || "fr-FR",
  messages: {
    "fr-FR": { ...frLocal },
  },
  numberFormats: {
    "fr-FR": {
      currency: {
        style: "currency",
        currency: "EUR",
        notation: "standard",
      },
      decimal: {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      percent: {
        style: "percent",
        useGrouping: false,
      },
    },
  },
  datetimeFormats: {
    "fr-FR": {
      short: {
        year: "numeric",
        month: "short",
        day: "numeric",
      },
      long: {
        year: "numeric",
        month: "long",
        day: "numeric",
      },
      datetime: {
        year: "numeric",
        month: "short",
        day: "numeric",
        weekday: "short",
        hour: "numeric",
        minute: "numeric",
      },
    },
  },
});

export default i18n;
