import { MaybeRef } from "@vueuse/core";
import { computed, ComputedRef, ref, unref, UnwrapRef } from "vue";

export function useComputedControllable<T>(
    controlledValue: ComputedRef<T | undefined>,
    onChange?: (value: T) => void,
    defaultValue?: ComputedRef<T>
) {
    const internalValue = ref(defaultValue?.value)
    const isControlled = computed(() => controlledValue.value !== undefined)

    return [
        computed(() => (isControlled.value ? controlledValue.value : internalValue.value)),
        function (value: unknown) {
            if (isControlled.value) {
                return onChange?.(value as T)
            } else {
                internalValue.value = value as UnwrapRef<T>
                return onChange?.(value as T)
            }
        },
    ] as const
}

export function useRefControllable<T>(
    controlledValue: MaybeRef<T | undefined>,
    onChange?: (value: T) => void,
    defaultValue?: MaybeRef<T>
) {
    const internalValue = ref(unref(defaultValue))
    const isControlled = computed(() => controlledValue !== undefined)

    return [
        computed(() => (isControlled.value ? controlledValue : internalValue)),
        function (value: unknown) {
            if (isControlled.value) {
                return onChange?.(value as T)
            } else {
                internalValue.value = value as UnwrapRef<T>
                return onChange?.(value as T)
            }
        },
    ] as const
}