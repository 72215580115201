import { IFichier } from "@/@models/fichier";
import { ILigneCao, IOuvrageCao } from "@/@models/fichier-cao";
import {
  bimService,
  SearchDocParameter,
  SearchElementCaoParameter,
  SearchOuvrageParameter,
} from "@/api";
import {
  contains,
  groupBy,
  isNumeric,
  isValidStatus,
  partition,
} from "@/utils";
import { defineStore } from "pinia";
import { useFichiersCaoStore } from "./viewer-fichiers-cao";
import { extractData, LigneCaoData } from "./viewer-elements-cao";

export const useViewerSearchStore = defineStore("viewer-search", {
  state: () => {
    return {
      search: "",

      loadingOuvrages: false,
      loadingZones: false,
      loadingDocs: false,

      ouvrages: [] as IOuvrageCao[],
      zones: [] as ILigneCao[],
      docs: [] as IFichier[],
    };
  },
  getters: {
    tags: (state): string[] => {
      if (state.search) {
        return state.search
          .toLowerCase()
          .split(" ")
          .filter((x) => x != "");
      }
      return [];
    },
    validTags(): string[] {
      return this.tags.filter(
        (x, i) => x.length > 1 || (i > 0 && isNumeric(x))
      );
    },
    invalidTags(): string[] {
      return this.tags.filter(
        (x, i) => x.length <= 1 && i == 0 && isNumeric(x)
      );
    },
    zonesSource(state) {
      const { preferences } = useFichiersCaoStore();
      return groupZones(
        state.zones.filter((z) => {
          const data = extractData(z, preferences);
          const base = `${data.nom}_${data.appartement}_${data.etage}`;
          return contains(base, this.validTags);
        })
      );
    },
    ouvragesSource(state) {
      return state.ouvrages
        .filter((o) => contains(o.designation, this.validTags))
        .sort((x, y) => {
          if (x.code) {
            return x.code.localeCompare(y.code);
          } else {
            return x.designation.localeCompare(y.designation);
          }
        });
    },
    docsSource(state) {
      return state.docs
        .filter((o) => contains(o.nom, this.validTags))
        .sort((x, y) => x.nom?.localeCompare(y.nom));
    },
  },
  actions: {
    /**
     * Compare les tags de recherche
     * @param newSearchTags
     * @param beforeSearchTags
     * @returns
     */
    needSearch(newSearchTags: string[], beforeSearchTags = []): string | false {
      const [valid] = partition(
        newSearchTags,
        (x, i) => x.length > 1 || (i > 0 && isNumeric(x))
      );
      if (valid.length > 0) {
        if (
          (valid[0].length > 2 && beforeSearchTags.length == 0) ||
          (valid[0].length === 2 &&
            (beforeSearchTags.length == 0 || beforeSearchTags[0].length != 3) &&
            valid[0] !== beforeSearchTags[0])
        ) {
          return valid[0];
        }
      }
      return false;
    },

    async fetchOuvrages(name) {
      this.loadingOuvrages = true;

      const { fichierCao } = useFichiersCaoStore();

      const reqData: SearchOuvrageParameter = {
        nom: name,
        typeRecherche: "contient",
      };
      const { status, data } = await bimService.postRechercheOuvrage(
        fichierCao.uid,
        reqData
      );

      if (!isValidStatus(status)) {
        this.ouvrages = [];
        this.loadingOuvrages = false;
        this.error = Error("La recherche des ouvrages a échoué.");
        return false;
      }

      this.ouvrages = data.ouvrages;
      this.loadingOuvrages = false;
    },

    async fetchZones(name) {
      this.loadingOuvrages = true;

      const { fichierCao } = useFichiersCaoStore();

      const reqData: SearchElementCaoParameter = {
        nomElement: name,
        typeElement: "Zone",
        avecArticles: false,
        avecElementsComposition: true,
        uniquementIfc: false,
      };
      const { status, data } = await bimService.postRechercheElementsCao(
        fichierCao.uid,
        reqData
      );

      if (!isValidStatus(status)) {
        this.zones = [];
        this.loadingZones = false;
        this.error = Error("La recherche des zones a échoué.");
        return false;
      }

      this.zones = data.elements;
      this.loadingZones = false;
    },

    async fetchDocs(name) {
      this.loadingDocs = true;

      const { fichierCao } = useFichiersCaoStore();

      const reqData: SearchDocParameter = {
        nomDoc: name,
        typeRecherche: "contient",
      };
      const { status, data } = await bimService.postRechercheDoc(
        fichierCao.uid,
        reqData
      );

      if (!isValidStatus(status)) {
        this.docs = [];
        this.loadingDocs = false;
        this.error = Error("La recherche des documents a échoué.");
        return false;
      }

      this.docs = data.docs;
      this.loadingDocs = false;
    },

    clearResult() {
      this.ouvrages = [];
      this.zones = [];
      this.docs = [];

      this.loadingOuvrages = false;
      this.loadingZones = false;
      this.loadingDocs = false;
    },
  },
});

function groupZones(zones: ILigneCao[]): GroupedZone[] {
  const { preferences } = useFichiersCaoStore();

  const grouped: GroupedZone[] = [];

  // On recupere que les lignes de type zones
  // Et on group les zones par batiment, par etage, et par appartememnt
  const zonesData = zones.map((z) => {
    return {
      uid: z.uid,
      idIfc: z.idIfc,
      idCao: z.idCao,
      data: extractData(z, preferences),
    };
  });

  for (const [key, value] of Object.entries(
    groupBy(zonesData as any[], "data.batiment")
  )) {
    const batiment: GroupedZone = {
      batiment: key,
      etages: [],
    };
    for (const [key1, value1] of Object.entries(
      groupBy(value as any[], "data.etage")
    )) {
      const etage = {
        etage: key1,
        appartements: [],
      };

      for (const [key2, value2] of Object.entries(
        groupBy(value1 as any[], "data.appartement")
      )) {
        etage.appartements.push({
          appartement: key2,
          zones: (
            value2 as {
              uid: string;
              idIfc: string;
              idCao: string;
              data: Partial<LigneCaoData>;
            }[]
          ).sort((a, b) => a.data.nom.localeCompare(b.data.nom)),
        });

        etage.appartements = etage.appartements.sort((a, b) =>
          a.appartement.localeCompare(b.appartement)
        );
      }

      batiment.etages.push(etage);
    }
    grouped.push(batiment);
  }

  return grouped.sort((a, b) => {
    if (a.batiment) {
      return a.batiment.localeCompare(b.batiment);
    }
    return 0;
  });
}

type GroupedZone = {
  batiment?: string;
  etages: {
    etage?: string;
    appartements?: {
      appartement?: string;
      zones: {
        uid: string;
        idIfc: string;
        idCao: string;
        data: Partial<LigneCaoData>;
      }[];
    }[];
  }[];
};
