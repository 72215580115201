import {
    computed,
    inject,
    provide,
    ref,

    // Types
    ComputedRef,
    InjectionKey,
    Ref,
} from 'vue'

export { default as BDescription } from "./Description.vue"

export const DescriptionContext = Symbol('DescriptionContext') as InjectionKey<{
    register(value: string): () => void
    slot: Ref<Record<string, any>>
    name: string
    props: Record<string, any>
}>

export function useDescriptionContext() {
    const context = inject(DescriptionContext, null)
    if (context === null) {
        throw new Error('Missing parent')
    }
    return context
}

export function useDescriptions({
    slot = ref({}),
    name = 'Description',
    props = {},
}: {
    slot?: Ref<Record<string, unknown>>
    name?: string
    props?: Record<string, unknown>
} = {}): ComputedRef<string | undefined> {
    const descriptionIds = ref<string[]>([])

    function register(value: string) {
        descriptionIds.value.push(value)

        return () => {
            const idx = descriptionIds.value.indexOf(value)
            if (idx === -1) return
            descriptionIds.value.splice(idx, 1)
        }
    }

    provide(DescriptionContext, { register, slot, name, props })

    // The actual id's as string or undefined.
    return computed(() =>
        descriptionIds.value.length > 0 ? descriptionIds.value.join(' ') : undefined
    )
}