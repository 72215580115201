export async function askNotifPermission() {
  const permission = await window.Notification.requestPermission();
  return permission === "granted";
}

export async function showNotif(title: string, body: string, timer = 5000) {
  //demander la permission si elle n'est pas encore accordée
  if (window.Notification.permission !== "granted") {
    const permission = await askNotifPermission();
    if (!permission) {
      return;
    }
  }

  const notif = new window.Notification(title, { body });

  setTimeout(() => {
    notif.close();
  }, timer);
}
