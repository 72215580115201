
import { useComputedControllable } from "@/composables/useControllable";
import { useTreeWalker } from "@/composables/useTreeWalker";
import {
  attemptSubmit,
  dom,
  generateUUID,
  getOwnerDocument,
  Keys,
  objectToFormEntries,
  sortByDomNode,
} from "@/utils";
import { Focus, focusIn, FocusResult } from "@/utils/focus";
import { compact, omit, render } from "@/utils/render";
import {
  computed,
  reactive,
  defineComponent,
  Fragment,
  h,
  onMounted,
  PropType,
  provide,
  ref,
  toRaw,
  UnwrapRef,
  watch,
} from "vue";
import { ThemeType } from "../index";
import { useLabels } from "../label";
import { useDescriptions } from "../description";
import { Hidden, Features as HiddenFeatures } from "../b-hidden";
import {
  BSegmentContext,
  VariantSegment,
  ThemeDefinition,
  Option,
  StateDefinition,
  defaultComparator,
} from "./segment";
import { useResizeObserver } from "@vueuse/core";

// Basé sur le composant RadioGroup de HeadlessUI
// https://github.com/tailwindlabs/headlessui/blob/main/packages/%40headlessui-vue/src/components/radio-group/radio-group.ts

// Basé sur le composant Segment de Ionic
// https://ionicframework.com/docs/api/segment

export default defineComponent({
  name: "BSegment",
  emits: { "update:modelValue": (_value: any) => true },
  props: {
    as: { type: [Object, String], default: "div" },
    disabled: { type: [Boolean], default: false },
    by: { type: [String, Function], default: () => defaultComparator },
    modelValue: { type: [Object, String, Number, Boolean], default: undefined },

    /**
     * Default Value
     */
    value: { type: [Object, String, Number, Boolean], default: undefined },

    name: { type: String, optional: true },
    id: { type: String, default: () => `b-segment-${generateUUID()}` },

    theme: {
      type: String as PropType<ThemeType>,
      default: () => "styless" as ThemeType,
    },
    variant: {
      type: String as PropType<VariantSegment>,
      default: () => "primary" as VariantSegment,
    },
  },
  inheritAttrs: false,
  setup(props, { emit, attrs, slots, expose }) {
    const segmentRef = ref<HTMLElement | null>(null);
    const labelledby = useLabels({ name: "BSegmentLabel" });
    const describedby = useDescriptions({ name: "BSegmentDescription" });
    const options = ref<StateDefinition["options"]["value"]>([]);
    const form = computed(() => dom(segmentRef)?.closest("form"));
    const theme = reactive<ThemeDefinition>({
      name: computed(() => props.theme),
      enabled: computed(() => theme.name != "styless"),
      variant: computed(() => props.variant),

      containerClass: computed(() => {
        if (!theme.enabled) return "";

        const className = [
          "segment-container scrollbar-none",
          attrs.class as string,
        ];
        if (theme.name == "material") {
          className.push("md");
          className.push(theme.variant);
        } else if (theme.name == "ios") {
          className.push("ios");
          className.push("group");
        }
        return className;
      }),
      selectorClass: computed(() => {
        if (!theme.enabled) return "";

        const className = ["segment-container-selector"];
        if (theme.name == "material") {
          className.push("md");
          className.push(theme.variant);
        } else if (theme.name == "ios") {
          className.push("ios");
        }
        return className;
      }),
      selectorStyle: "",
    });

    const [value, theirOnChange] = useComputedControllable(
      computed(() => props.modelValue),
      (value: unknown) => emit("update:modelValue", value),
      computed(() => props.value)
    );

    const api = {
      options,
      value,
      theme,
      disabled: computed(() => props.disabled),
      /**
       * On recupere la premiere option non désactivé
       */
      firstOption: computed(() =>
        options.value.find((option) => !option?.propsRef?.disabled)
      ),
      /**
       * On regarde si il y a une option qui correspond au model
       */
      containsCheckedOption: computed(() =>
        options.value.some((option) =>
          api.compare(toRaw(option.propsRef.value), toRaw(props.modelValue))
        )
      ),
      compare(a: any, b: any) {
        if (typeof props.by === "string") {
          return a?.[props.by] === b?.[props.by];
        }
        return props.by(a, b);
      },
      change(nextValue: unknown) {
        if (props.disabled) return false;
        if (api.compare(toRaw(value.value), toRaw(nextValue))) return false;
        const nextOption = options.value.find((option) =>
          api.compare(toRaw(option.propsRef.value), toRaw(nextValue))
        )?.propsRef;
        if (nextOption?.disabled) return false;
        theirOnChange(nextValue);
        return true;
      },
      registerOption(action: UnwrapRef<Option>) {
        options.value.push(action);
        options.value = sortByDomNode(
          options.value,
          (option) => option.element
        );
      },
      unregisterOption(id: Option["id"]) {
        const idx = options.value.findIndex((radio) => radio.id === id);
        if (idx === -1) return;
        options.value.splice(idx, 1);
      },
    };

    function handleKeyDown(event: KeyboardEvent) {
      if (!segmentRef.value) return;
      if (!segmentRef.value.contains(event.target as HTMLElement)) return;

      const all = options.value
        .filter((option) => option.propsRef.disabled === false)
        .map((radio) => radio.element) as HTMLElement[];

      switch (event.key) {
        case Keys.Enter:
          attemptSubmit(
            event.currentTarget as unknown as EventTarget & HTMLButtonElement
          );
          break;
        case Keys.ArrowLeft:
          //case Keys.ArrowUp:
          {
            event.preventDefault();
            event.stopPropagation();

            const result = focusIn(all, Focus.Previous | Focus.WrapAround);

            if (result === FocusResult.Success) {
              const activeOption = options.value.find(
                (option) =>
                  option.element === getOwnerDocument(segmentRef)?.activeElement
              );
              if (activeOption) api.change(activeOption.propsRef.value);
            }
          }
          break;

        case Keys.ArrowRight:
          //case Keys.ArrowDown:
          {
            event.preventDefault();
            event.stopPropagation();

            const result = focusIn(all, Focus.Next | Focus.WrapAround);

            if (result === FocusResult.Success) {
              const activeOption = options.value.find(
                (option) =>
                  option.element ===
                  getOwnerDocument(option.element)?.activeElement
              );
              if (activeOption) api.change(activeOption.propsRef.value);
            }
          }
          break;

        case Keys.Space:
          {
            event.preventDefault();
            event.stopPropagation();

            const activeOption = options.value.find(
              (option) =>
                option.element ===
                getOwnerDocument(option.element)?.activeElement
            );
            if (activeOption) api.change(activeOption.propsRef.value);
          }
          break;
      }
    }

    function handleWheel(event: WheelEvent) {
      event.preventDefault();
      if (segmentRef.value) segmentRef.value.scrollLeft += event.deltaY;
    }

    function updateSelectorStyle() {
      theme.selectorStyle = "";

      if (!theme.enabled) return;

      const option = options.value.find((o) => o.propsRef.value == value.value);
      const indexOption = options.value.indexOf(option);

      if (theme.name == "ios") {
        if (indexOption == 0)
          theme.selectorStyle = `left: calc(${option?.element?.offsetLeft}px + 0.125rem); width: calc(${option?.element?.offsetWidth}px - 0.125rem);`;
        else if (indexOption == options.value.length - 1)
          theme.selectorStyle = `left: ${option?.element?.offsetLeft}px; width: calc(${option?.element?.offsetWidth}px - 0.125rem);`;
      }

      if (!theme.selectorStyle)
        theme.selectorStyle = `left: ${option?.element?.offsetLeft}px; width: ${option?.element?.offsetWidth}px;`;
    }

    expose({ el: segmentRef, $el: segmentRef });

    provide(BSegmentContext, api);

    useTreeWalker({
      container: computed(() => dom(segmentRef)),
      accept(node) {
        if (node.getAttribute("role") === "tab")
          return NodeFilter.FILTER_REJECT;
        if (node.hasAttribute("role")) return NodeFilter.FILTER_SKIP;
        return NodeFilter.FILTER_ACCEPT;
      },
      walk(node) {
        node.setAttribute("role", "none");
      },
    });

    onMounted(() => {
      if (options.value?.length && !props.value) {
        api.change(api.firstOption?.value);
      }
      watch(
        [form],
        () => {
          if (!form.value) return;
          if (props.value === undefined) return;

          function handle() {
            api.change(props.value);
          }

          form.value.addEventListener("reset", handle);

          return () => {
            form.value?.removeEventListener("reset", handle);
          };
        },
        { immediate: true }
      );

      // Pour mettre à jour le selecteur
      watch(
        [value],
        () => {
          updateSelectorStyle();
        },
        { immediate: true }
      );
    });

    useResizeObserver(segmentRef, updateSelectorStyle);

    return () => {
      const { disabled, name, id, ...theirProps } = props;

      const ourProps = {
        ref: segmentRef,
        id,
        role: "tablist",
        class: theme.containerClass,
        "aria-labelledby": labelledby.value,
        "aria-describedby": describedby.value,
        onKeydown: handleKeyDown,
        onwheel: handleWheel,
      };

      const containerNode = render({
        ourProps,
        theirProps: {
          ...attrs,
          ...omit(theirProps, ["modelValue", "value"]),
        },
        slot: {},
        attrs,
        slots,
        name: "BSegment",
      });

      if (theme.enabled) {
        (containerNode as any).children.push(
          h("span", {
            class: theme.selectorClass,
            style: theme.selectorStyle,
          })
        );
      }

      return h(Fragment, [
        ...(name != null && value.value != null
          ? objectToFormEntries({ [name]: value.value }).map(([name, value]) =>
              h(
                Hidden,
                compact({
                  features: HiddenFeatures.Hidden,
                  key: name,
                  as: "input",
                  type: "hidden",
                  hidden: true,
                  readOnly: true,
                  name,
                  value,
                })
              )
            )
          : []),
        containerNode,
      ]);
    };
  },
});
