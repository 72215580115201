import {
  IProjetIntervenant,
  IProjetIntervenantContact,
  IProjetLot,
  IProjetLotOffre,
} from "@/@models/projets";
import { projetsService } from "@/api";
import { checkData, isUUIDValid, isValidStatus } from "@/utils";
import { isRef, ref, watch } from "vue";
import { useToast } from "vue-toastification";

import type { ComputedRef, Ref, WatchStopHandle } from "vue";
import { IConsultationPieceAFournir } from "@/@models/consultations";

interface UseProjetAdjudicationEntrepriseProps {
  uidProjet: string | Ref<string> | ComputedRef<string>;
  uidLot: string | Ref<string> | ComputedRef<string>;
  indexEntreprise: number | Ref<number> | ComputedRef<number>;

  /**
   * Si vrai, charge immediatement la projet et recharge à chaque changement de 'uidProjet'
   * Il faut recreer l'instance si changer apres
   */
  autoFetch?: boolean;
  disableToast?: boolean;
}

export function useProjetAdjudicationEntreprise({
  uidProjet,
  uidLot,
  indexEntreprise,
  autoFetch,
  disableToast,
}: UseProjetAdjudicationEntrepriseProps) {
  const intervenant = ref<IProjetIntervenant>(null);
  const contacts = ref<IProjetIntervenantContact[]>([]);
  const offres = ref<IProjetLotOffre[]>([]);

  /**
   * Candidature
   */
  const piecesAFournir = ref<IConsultationPieceAFournir[]>([]);

  const loadingCandidature = ref<boolean>(false);
  const isFetchedCandidature = ref<boolean>(false);

  const loadingIntervenant = ref<boolean>(false);
  const isFetchedIntervenant = ref<boolean>(false);

  const loadingOffres = ref<boolean>(false);
  const isFetchedOffres = ref<boolean>(false);

  const fetchCandidatureError = ref<Error>(null);
  const fetchOffresError = ref<Error>(null);
  const fetchIntervenantError = ref<Error>(null);

  let watchStop: WatchStopHandle = null;

  async function fetchIntervenant() {
    const toastification = useToast();

    fetchIntervenantError.value = null;

    const _uidProjet = isRef(uidProjet) ? uidProjet.value : uidProjet;
    const _indexEntreprise = isRef(indexEntreprise)
      ? indexEntreprise.value
      : indexEntreprise;

    if (!isUUIDValid(_uidProjet)) {
      intervenant.value = null;
      contacts.value = [];
      isFetchedIntervenant.value = false;
      loadingIntervenant.value = false;
      console.error("Error: UUID Projet vide ou invalide !");
      return;
    }

    if (!_indexEntreprise) {
      intervenant.value = null;
      contacts.value = [];
      isFetchedIntervenant.value = false;
      loadingIntervenant.value = false;
      console.error("Error: Index entreprise vide ou invalide !");
      return;
    }

    loadingIntervenant.value = true;

    try {
      const { status, data, request } =
        await projetsService.getProjetAdjudicationEntrepriseIntervenant(
          _uidProjet,
          _indexEntreprise
        );

      if (!isValidStatus(status) || !checkData(data)) {
        intervenant.value = null;
        contacts.value = [];
        fetchIntervenantError.value = new Error(`Erreur api: ${status}`);
        if (!disableToast) {
          toastification.error("La récupération de l'intervenant a échouée.");
        }
      } else {
        contacts.value = data.intervenant.contacts;
        delete data.intervenant.contacts;
        intervenant.value = data.intervenant;
        isFetchedIntervenant.value = true;
      }
    } catch (err) {
      intervenant.value = null;
      contacts.value = [];
      fetchIntervenantError.value = err;
    } finally {
      loadingIntervenant.value = false;
    }
  }

  async function fetchCandidature() {
    const toastification = useToast();

    fetchCandidatureError.value = null;

    const _uidProjet = isRef(uidProjet) ? uidProjet.value : uidProjet;
    const _indexEntreprise = isRef(indexEntreprise)
      ? indexEntreprise.value
      : indexEntreprise;

    if (!isUUIDValid(_uidProjet)) {
      piecesAFournir.value = [];
      isFetchedCandidature.value = false;
      loadingCandidature.value = false;
      console.error("Error: UUID Projet vide ou invalide !");
      return;
    }

    if (!_indexEntreprise) {
      piecesAFournir.value = [];
      isFetchedCandidature.value = false;
      loadingCandidature.value = false;
      console.error("Error: Index entreprise vide ou invalide !");
      return;
    }

    loadingCandidature.value = true;

    try {
      const { status, data } =
        await projetsService.getProjetAdjudicationEntrepriseCandidature(
          _uidProjet,
          _indexEntreprise
        );

      if (!isValidStatus(status) || !checkData(data)) {
        piecesAFournir.value = [];
        fetchCandidatureError.value = new Error(`Erreur api: ${status}`);
        if (!disableToast) {
          toastification.error("La récupération de la candidature a échouée.");
        }
      } else {
        piecesAFournir.value = data.pieces
        isFetchedCandidature.value = true;
      }
    } catch (err) {
      piecesAFournir.value = [];
      fetchIntervenantError.value = err;
    } finally {
      loadingCandidature.value = false;
    }
  }

  async function fetchOffres() {
    const toastification = useToast();

    fetchOffresError.value = null;

    const _uidProjet = isRef(uidProjet) ? uidProjet.value : uidProjet;
    const _uidLot = isRef(uidLot) ? uidLot.value : uidLot;
    const _indexEntreprise = isRef(indexEntreprise)
      ? indexEntreprise.value
      : indexEntreprise;

    if (!isUUIDValid(_uidProjet)) {
      offres.value = [];
      isFetchedOffres.value = false;
      loadingOffres.value = false;
      console.error("Error: UUID Projet vide ou invalide !");
      return;
    }

    if (!isUUIDValid(_uidLot)) {
      offres.value = [];
      isFetchedOffres.value = false;
      loadingOffres.value = false;
      console.error("Error: UUID Lot vide ou invalide !");
      return;
    }

    if (!_indexEntreprise) {
      offres.value = [];
      isFetchedOffres.value = false;
      loadingOffres.value = false;
      console.error("Error: Index entreprise vide ou invalide !");
      return;
    }

    loadingOffres.value = true;

    try {
      const { status, data } =
        await projetsService.getProjetAdjudicationEntrepriseLotOffres(
          _uidProjet,
          _indexEntreprise,
          _uidLot
        );

      if (!isValidStatus(status) || !checkData(data)) {
        offres.value = [];
        if (!disableToast) {
          toastification.error("La récupération des offres a échouée.");
        }
      } else {
        offres.value = data.offres;
        isFetchedOffres.value = true;
      }
    } catch (err) {
      offres.value = [];
      fetchOffresError.value = err;
    } finally {
      loadingOffres.value = false;
    }
  }

  watch(
    () => autoFetch,
    (value) => {
      if (value) {
        if (typeof watchStop == "function") {
          watchStop();
        }
        watchStop = watch(
          [
            isRef(uidProjet) ? uidProjet : () => uidProjet,
            isRef(uidLot) ? uidLot : () => uidLot,
            isRef(indexEntreprise) ? indexEntreprise : () => indexEntreprise,
          ],
          () => {
            fetchIntervenant();
            fetchOffres();
          },
          {
            immediate: true,
          }
        );
      } else {
        if (typeof watchStop == "function") {
          watchStop();
        }
      }
    },
    {
      immediate: true,
    }
  );

  const reset = () => {
    offres.value = [];
    intervenant.value = null;
    contacts.value = [];
    piecesAFournir.value = [];

    isFetchedCandidature.value = false;
    loadingCandidature.value = false;
    fetchCandidatureError.value = null;

    isFetchedOffres.value = false;
    loadingOffres.value = false;
    fetchOffresError.value = null;

    isFetchedIntervenant.value = false;
    loadingIntervenant.value = false;
    fetchIntervenantError.value = null;
  };

  return {
    contacts,
    intervenant,
    offres,
    piecesAFournir,

    loadingCandidature,
    loadingOffres,
    loadingIntervenant,

    isFetchedCandidature,
    isFetchedOffres,
    isFetchedIntervenant,

    fetchCandidatureError,
    fetchOffresError,
    fetchIntervenantError,

    fetchCandidature,
    fetchIntervenant,
    fetchOffres,
    
    reset,
  };
}
