/**
 * @param data  ()=> data
 * @returns quand les données sont prêtes || true
 * @description on attends les données avec while et await
 */
export async function awaitData(getData: () => any) {
  while (!getData()) {
    await new Promise((resolve) => setTimeout(resolve, 100));
  }
  return getData();
}
