import { IConsultationPieceAFournir } from '@/@models/consultations';
import { consultationsService } from '@/api';
import { checkData, isValidStatus } from '@/utils';
import { defineStore } from 'pinia'
import { useToast } from 'vue-toastification';

const toastification = useToast();

export const useConsultationCandidatures = defineStore('consultation-candidatures', {
    state() {
        return {
            piecesAFournir: [] as IConsultationPieceAFournir[]
        }
    },
    actions: {
        /**
         * Recupere toutes les pieces à fournir de l'intervenant pour la consultation
         * @param uidConsultation 
         * @returns success
         */
        async fetchPieceAFournir(uidConsultation) {
            const { status, data } = await consultationsService.getPieceAFournir(uidConsultation);
            if (!isValidStatus(status) || !checkData(data)) {
                this.pieceAFournir = [];
                toastification.error("La récupération des pièces à fournir a échouée.")
                return false;
            }
            this.piecesAFournir = data.piecesAFournir
            return true;
        },

        /**
         * Ajoute/Envoi un document à une piece à fournir
         * par convention, on essaye d'avoir qu'un seul fichier par pieceAFournir
         * @param uidConsultation 
         * @param uidPiece 
         * @param file 
         * @returns success
         */
        async uploadFile(uidConsultation, uidPiece, file?) {
            const { status, data } = await consultationsService.postPieceAFournir(uidConsultation, uidPiece, file, null);
            if (!isValidStatus(status) || !checkData(data)) {
                toastification.error("L'envoi de la piece a échoué.")
                return false;
            }
            const paf: IConsultationPieceAFournir = this.piecesAFournir.find(x => x.uid == uidPiece)
            if (paf) {
                paf.pieces = [data.pieceInfo]
            }
            toastification.success("Envoi réussi !")
            return true;
        },

        /**
         * Selection un document 
         * @param uidConsultation 
         * @param uidPiece 
         * @param file 
         * @returns success
         */
        async selectFile(uidConsultation, uidPiece, uidDocEntreprise) {
            const { status, data } = await consultationsService.postPieceAFournir(uidConsultation, uidPiece, null, uidDocEntreprise);
            if (!isValidStatus(status) || !checkData(data)) {
                toastification.error("L'envoi de la piece a échoué.")
                return false;
            }
            const paf: IConsultationPieceAFournir = this.piecesAFournir.find(x => x.uid == uidPiece)
            if (paf) {
                paf.pieces = [data.pieceInfo]
            }
            toastification.success("Envoi réussi !")
            return true;
        },

        /**
         * Supprime le fichier lié à une piece à fournir
         * @param uidConsultation 
         * @param uidPiece 
         * @returns success
         */
        async deleteFile(uidConsultation, uidPiece) {
            const { status, data } = await consultationsService.deleteFichierPieceAFournir(uidConsultation, uidPiece);
            if (!isValidStatus(status) || !checkData(data)) {
                toastification.error("La suppression a échoué.")
                return false;
            }
            const paf: IConsultationPieceAFournir = this.piecesAFournir.find(x => x.uid == uidPiece)
            if (paf) {
                paf.pieces = []
            }
            toastification.success("Suppression réussie !")
            return true;
        }
    }
})