
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "bim-icon",
  props: {
    name: {
      type: String,
    },
  },
  setup(props) {
    const getIconPathByElementName = (name: string): string => {
      let path = "assets/icon/bim/elements/";
      switch (name.toLowerCase()) {
        case "mur":
        case "murs":
          path = path + "ACWallToolLarge_29x20.svg";
          break;
        case "poteau":
          path = path + "ACColumnToolLarge_36x24.svg";
          break;
        case "poutre":
          path = path + "ACBeamToolLarge_36x24.svg";
          break;
        case "dalle":
          path = path + "ACSlabToolLarge_29x20.svg";
          break;
        case "toiture":
          path = path + "ACRoofToolLarge_29x20.svg";
          break;
        case "coque":
          path = path + "ACShellToolLarge_36x24.svg";
          break;
        case "escalier":
          path = path + "ACStairToolLarge_36x24.svg";
          break;
        case "garde-corps":
          path = path + "ACRailingToolLarge@2x_58x40.svg";
          break;
        case "murs rideau":
          path = path + "ACCWToolLarge_36x24.svg";
          break;
        case "porte":
          path = path + "ACDoorToolLarge_36x24.svg";
          break;
        case "fenêtre":
          path = path + "WindowToolLarge_36x24.svg";
          break;
        case "ouverture de toit":
          path = path + "ACSkylightToolLarge_36x24.svg";
          break;
        case "forme":
          path = path + "ACMorphToolLarge_36x24.svg";
          break;
        case "percement":
          path = path + "ACOpeningToolLarge_36x24.svg";
          break;
        case "zone":
          path = path + "ACZoneToolLarge_36x24.svg";
          break;
        case "maillage":
          path = path + "ACMeshToolLarge_36x24.svg";
          break;
        case "objet":
          path = path + "ObjectToolLarge_36x24.svg";
          break;
        case "lampe":
          path = path + "ACLampToolLarge_36x24.svg";
          break;
        case "equipement":
          path = path + "MEPEquipmentToolLarge_36x24.svg";
          break;
        case "fenêtre d’angle":
          path = path + "ACCornerwindowToolLarge_36x24.svg";
          break;
        case "tête de mur":
          path = path + "ACWallendToolLarge_36x24.svg";
          break;
        case "marches":
          path = path + "ACStairTreadToolLarge_36x24.svg";
          break;
        case "contre-marches":
          path = path + "ACStairRiserToolLarge_36x24.svg";
          break;
        case "structure d’escalier":
          path = path + "ACStairStructureToolLarge_36x24.svg";
          break;
        case "balustre":
        case "balustres":
          path = path + "ACRailingBalusterSetToolLarge_36x24.svg";
          break;
        case "noeud":
        case "noeuds":
          path = path + "ACRailingNodeToolLarge_36x24.svg";
          break;
        case "panneau":
        case "panneaux":
          path = path + "ACRailingPatternToolLarge_36x24.svg";
          break;
        case "main courante":
          path = path + "ACRailingToprailConnectionToolLarge_36x24.svg";
          break;
        case "segment de poutres":
          path = path + "ACBeamSegmentToolLarge_36x24.svg";
          break;
        case "segment de poteau":
          path = path + "ACColumnSegmentToolLarge_36x24.svg";
          break;
        case null: // cas du composite
          path = path + "Fill_green.svg";
          break;
        default:
          path = path + "ObjectToolLarge_36x24.svg";
          break;
      }

      return path;
    };

    const imgSource = computed(() => getIconPathByElementName(props.name));

    return {
      imgSource,
    };
  },
});
