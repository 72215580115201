
import { useFichiersCaoStore, useViewerStore } from "@/stores/viewer";
import { defineComponent } from "vue";
import {
  Bordereau,
  Maquette,
  Search,
  Favoris,
} from "@/components/global/icons";
import { storeToRefs } from "pinia";

export default defineComponent({
  name: "app-navbar",
  setup() {
    const fichierStore = useFichiersCaoStore();
    const { toggleSidepanel } = useViewerStore();

    const { codeProjet, nomConsultation, nomProjet } =
      storeToRefs(fichierStore);

    return {
      toggleSidepanel,

      codeProjet,
      nomConsultation,
      nomProjet,
    };
  },
  components: {
    Bordereau,
    Maquette,
    Search,
    Favoris,
  },
});
