import { inject, InjectionKey } from "vue";

export { default as BButton } from "./Button.vue";

export { default as BButtonHandler } from "./BButtonHandler.vue";

export const ButtonContext = Symbol("ButtonContext") as InjectionKey<{
    register(value: string): () => void;
    slot: Record<string, unknown>;
    name: string;
    props: Record<string, unknown>;
}>;

export function useButtonContext() {
    const context = inject(ButtonContext, null);
    if (context === null) {
        const err = new Error(
            "You used a <BButton /> component, but it is not inside a parent."
        );
        if (Error.captureStackTrace) Error.captureStackTrace(err, useButtonContext);
        throw err;
    }
    return context;
}
