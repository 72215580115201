export enum Keys {
    Space = ' ',
    Enter = 'Enter',
    Escape = 'Escape',
    Backspace = 'Backspace',
    Delete = 'Delete',

    ArrowLeft = 'ArrowLeft',
    ArrowUp = 'ArrowUp',
    ArrowRight = 'ArrowRight',
    ArrowDown = 'ArrowDown',

    Home = 'Home',
    End = 'End',

    PageUp = 'PageUp',
    PageDown = 'PageDown',

    Tab = 'Tab',
}