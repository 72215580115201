import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "sticky inline-flex"
}
const _hoisted_2 = {
  key: 3,
  class: "sticky inline-flex"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_loader = _resolveComponent("b-loader")!

  return (_openBlock(), _createElementBlock("section", {
    class: _normalizeClass([_ctx.pageCss])
  }, [
    (_ctx.hasHeaderSlot)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "header")
        ]))
      : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass([_ctx.pageCss])
        }, [
          _createVNode(_component_b_loader, {
            size: "2xl",
            message: _ctx.loadingMessage
          }, null, 8, ["message"])
        ], 2))
      : (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass(["flex flex-grow flex-col", 
        _ctx.hasFooterSlot || _ctx.hasHeaderSlot
          ? _ctx.forceNotSmall
            ? 'overflow-y-auto'
            : 'sm:overflow-y-auto'
          : ''
      ])
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 2)),
    (_ctx.hasFooterSlot)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "footer")
        ]))
      : _createCommentVNode("", true)
  ], 2))
}