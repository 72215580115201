import { configure, defineRule } from 'vee-validate';
import { getDateFromString } from "@/utils";
import moment from 'moment';

export type ValidationSchema<Type> = {
    [Property in keyof Type]: string | boolean | (() => string | boolean);
}

/**
 * Nom des régles pour les champs de formulaire
 * Si plusieur régles, il faut les séparer par un '|' 
 */
export enum RulesType {
    required = "required",
    /**
     * Utilistion : minLength + ':100'
     */
    minLength = "minLength",

    /**
    * Utilistion : maxLength + ':100'
    */
    maxLength = "maxLength",

    dateValid = "dateValid",

    /**
    * Utilistion : maxLength + ':100'
    */
    dateBefore = "dateBefore",

    /**
    * Utilistion : maxLength + ':100'
    */
    dateAfter = "dateAfter",
}

export default function registerRules() {
    defineRule(RulesType.required, (value) => {
        if (!value) {
            if (typeof value == "string" && !value.trim().length) {
                return "error.required";
            }
        }
        return true;
    });
    defineRule(RulesType.minLength, (value, [limit]) => {
        if (!value || !value.length) {
            return true;
        }
        if (value.length < limit) {
            return "error.tooShort"
        }
        return true;
    });
    defineRule(RulesType.maxLength, (value, [limit]) => {
        if (!value || !value.length) {
            return true;
        }
        if (value.length > limit) {
            return "error.tooLong"
        }
        return true;
    });
    defineRule(RulesType.dateValid, (value) => {
        if (!value || !moment(value).isValid()) {
            return "error.dateInvalid"
        }

        return true;
    });
    defineRule(RulesType.dateBefore, (value, [limit]) => {
        const dateLimit = moment(limit);
        if (dateLimit.isValid()) {
            throw new Error("Limite invalide !")
        }

        const date = moment(value);
        if (value || !date.isValid() || dateLimit.isBefore(date)) {
            return "error.dateInvalid"
        }

        return true;
    });
    defineRule(RulesType.dateAfter, (value, [limit]) => {
        const dateLimit = moment(limit);
        if (dateLimit.isValid()) {
            throw new Error("Limite invalide !")
        }

        const date = moment(value);
        if (value || !date.isValid() || dateLimit.isAfter(date)) {
            return "error.dateInvalid"
        }

        return true;
    });
}

