
import {
  computed,
  defineComponent,
  PropType,
  ref,
  WritableComputedRef,
} from "vue";
import BaseInput from "./BaseInput.vue";
import BIcon from "../BIcon.vue";
import { generateUUID } from "@/utils";
import { IconType, ShadowType, VariantType } from "../index";

export default defineComponent({
  name: "b-input-text",
  components: {
    BaseInput,
    BIcon
  },
  props: {
    modelValue: [String, Date],
    label: String,
    error: String,
    pattern: {
      type: String,
    },
    type: {
      type: String as PropType<"date" | "datetime" | "datetime-local" | "week" | "month" | "time">,
      default: () => "date"
    },
    variant: {
      type: String as PropType<VariantType>,
      default: () => "primary",
    },
    shadow: {
      type: String as PropType<ShadowType>,
      default: () => "none"
    },
    outlined: {
      type: Boolean,
      default: () => true
    },
    showFocus:
    {
      type: Boolean,
      default: () => true
    },
    icon: String as PropType<IconType>,
    disabled: Boolean,
    block: Boolean,
  },
  emits: ['update:modelValue'],
  setup(props, { attrs, emit }) {
    const inputRef = ref();
    const uuid = generateUUID();
    const name = computed<string>(() => (attrs.name as string) ?? uuid);

    const model: WritableComputedRef<string | Date> = computed({
      get(): string | Date {
        return props.modelValue;
      },
      set(newValue: string | Date): void {
        emit("update:modelValue", newValue);
      },
    });




    return {
      inputRef,
      model,
      name,
    };
  },
});
