import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  "aria-label": "Nav sidebar",
  class: "scrollbar-thin flex w-[93px] flex-col overflow-y-auto border-r border-gray-300 bg-menu"
}
const _hoisted_2 = { class: "flex h-[93px] w-full flex-col items-center justify-center gap-1" }
const _hoisted_3 = { class: "text-md text-black" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BIcon = _resolveComponent("BIcon")!
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("aside", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mainRoutes, (route, index) => {
      return (_openBlock(), _createBlock(_component_RouterLink, {
        to: route,
        key: index,
        "active-class": "bg-[rgb(244,235,228)]"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_BIcon, {
              name: (route.meta['icon'] as any),
              size: "auto",
              class: "h-7 w-7 text-dark/40"
            }, null, 8, ["name"]),
            _createElementVNode("span", _hoisted_3, _toDisplayString(route.meta["label"]), 1)
          ])
        ]),
        _: 2
      }, 1032, ["to"]))
    }), 128))
  ]))
}