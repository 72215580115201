
import { computed, defineComponent, PropType } from "vue";
import { IconBimType, IconType, SizeType } from "../index";

export default defineComponent({
  name: "b-icon",
  props: {
    name: {
      type: String as PropType<IconType | IconBimType>,
      required: true,
    },
    size: {
      type: String as PropType<SizeType>,
      default: () => "sm",
    },
    fill: {
      type: String,
      default: undefined,
    },
  },
  setup(props) { 
    const getSizeClass = (size: SizeType) => {
      let Class = "";
      switch (size) {
        case "xs":
          Class = "icon-size-1";
          break;
        case "sm":
          Class = "icon-size-3";
          break;
        case "md":
          Class = "icon-size-5";
          break;
        case "lg":
          Class = "icon-size-7";
          break;
        case "xl":
          Class = "icon-size-8";
          break;
        case "2xl":
          Class = "icon-size-12";
          break;
        case "4xl":
          Class = "icon-size-14";
          break;

        case "full":
          Class = "w-full h-full";
          break;

        case "auto":
          Class = "flex-1";
          break;
      }
      return Class;
    };

    return {
      iconClass: computed(() => getSizeClass(props.size)),
    };
  },
});
