
import { defineComponent, PropType } from "vue";
import BSpinner from "./BSpinner.vue";
import { SizeType } from "./index";

export default defineComponent({
  name: "b-loader",
  props: {
    message: String,
    spinnerSize: {
      type: String as PropType<SizeType>,
      default: () => "2xl",
    },
  },
  components: {
    BSpinner,
  },
});
