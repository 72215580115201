
import { defineComponent, computed } from "vue";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import { Splitpanes } from "splitpanes";
import { usePreviewStore } from "@/stores/preview";
import { storeToRefs } from "pinia";

import ScenePane from "./components/ScenePane.vue";
import OptionsPane from "./components/OptionsPane.vue";
import AppHeader from "./components/AppHeader.vue";
import AppSideNav from "./components/AppSideNav.vue";

export default defineComponent({
  name: "app-layout",
  components: {
    ScenePane,
    OptionsPane,
    AppHeader,
    AppSideNav,
    Splitpanes,
  },
  setup() {
    //#region Properties

    const previewStore = usePreviewStore();
    const breakpoints = useBreakpoints(breakpointsTailwind);

    const { mainPanelSize, sidePanelSize } = storeToRefs(previewStore);

    const isSingle = computed(() => previewStore.isSingle);

    //#endregion

    //#region Hook & events

    const onResized = (event) => {
      if (event.length > 1) {
        if (breakpoints.isSmaller("md")) {
          sidePanelSize.value = event[1].size > 50 ? 100 : 0;
        } else {
          sidePanelSize.value = event[1].size;
        }
      }
    };

    const onSplitterResized = () => {
      previewStore.toggleSidepanel();
    };

    //#endregion

    return {
      isSingle,
      mainPanelSize,
      sidePanelSize,

      onResized,
      onSplitterResized,
    };
  },
});
