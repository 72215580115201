import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "m-auto flex h-full w-full flex-1 flex-col items-center justify-center space-y-3 p-1" }
const _hoisted_2 = {
  key: 0,
  class: "text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_spinner = _resolveComponent("b-spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_b_spinner, { size: _ctx.spinnerSize }, null, 8, ["size"]),
    (_ctx.message)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.message), 1))
      : _createCommentVNode("", true)
  ]))
}