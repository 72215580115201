
import { useViewerStore } from "@/stores/viewer";
import { storeToRefs } from "pinia";
import { defineComponent, onMounted, watch, computed } from "vue";
import MainPanel from "./components/MainPanel.vue";
import NavBar from "./components/NavBar.vue";
import SidePanel from "./components/Sidepanel.vue";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";
import { Splitpanes } from "splitpanes";
import {useViewer} from "@/composables/viewer";
import BOverlay from "@/components/global/BOverlay.vue";

export default defineComponent({
  name: "app-layout",
  components: {
    MainPanel,
    NavBar,
    SidePanel,
    Splitpanes,
    BOverlay,
  },
  setup() {
    //#region Properties

    const viewerStore = useViewerStore();
    const { isReady, isLoaded, isWorking } = useViewer();

    const { mainPanelSize, sidePanelSize } = storeToRefs(viewerStore);

    const darkMode = computed(() => viewerStore.dark);

    const breakpoints = useBreakpoints(breakpointsTailwind);

    //#endregion

    //#region Methodes

    const hideSplitter = () => {
      const splitterEl = document.getElementsByClassName(
        "splitpanes__splitter"
      );
      if (splitterEl && splitterEl.length) {
        if (!splitterEl[0].classList.contains("hidden")) {
          splitterEl[0].classList.add("hidden");
        }
      }
    };

    const showSplitter = () => {
      const splitterEl = document.getElementsByClassName(
        "splitpanes__splitter"
      );
      if (splitterEl && splitterEl.length) {
        if (splitterEl[0].classList.contains("hidden")) {
          splitterEl[0].classList.remove("hidden");
        }
      }
    };

    //#endregion

    //#region Hook & events

    onMounted(() => {
      // Au lancement, on fait apparaitre le sidepanel en plein écran pour afficher le statut de chargement
      if (!(isReady.value && isLoaded.value)) {
        hideSplitter();
        if (breakpoints.isSmaller("md")) {
          sidePanelSize.value = 100;
        } else {
          sidePanelSize.value = 85;
        }
      }
    });

    const onResized = (event) => {
      if (event.length > 1) {
        if (breakpoints.isSmaller("md")) {
          sidePanelSize.value = event[1].size > 50 ? 100 : 0;
        } else {
          sidePanelSize.value = event[1].size;
        }
      }
    };

    const onSplitterResized = () => {
      viewerStore.toggleSidepanel();
    };

    watch(
      () => isReady.value && isLoaded.value,
      (newState) => {
        if (newState) {
          showSplitter();
          if (breakpoints.isSmaller("md")) {
            sidePanelSize.value = 0;
          } else {
            sidePanelSize.value = 30;
          }
        }
      }
    );

    //#endregion

    return {
      isWorking,

      mainPanelSize,
      sidePanelSize,

      onResized,
      onSplitterResized,

      darkMode,
    };
  },
});
