import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, mergeProps as _mergeProps, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["id", "disabled"]
const _hoisted_2 = {
  key: 0,
  selected: "",
  disabled: ""
}
const _hoisted_3 = ["value"]
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_icon_cancel = _resolveComponent("b-icon-cancel")!
  const _component_base_input = _resolveComponent("base-input")!

  return (_openBlock(), _createBlock(_component_base_input, {
    variant: _ctx.variant,
    block: _ctx.block,
    disabled: _ctx.disabled,
    icon: _ctx.icon,
    outlined: _ctx.outlined,
    shadow: _ctx.shadow,
    "show-focus": _ctx.showFocus,
    onPrepend: _cache[3] || (_cache[3] = ($event: any) => (_ctx.inputRef.focus())),
    onAppend: _cache[4] || (_cache[4] = ($event: any) => (_ctx.inputRef.focus()))
  }, _createSlots({
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("select", _mergeProps(_ctx.$attrs, {
        ref: "inputRef",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
        class: "w-full flex-grow border-none bg-inherit px-1 py-2 focus:ring-0 disabled:cursor-not-allowed",
        id: _ctx.name,
        disabled: _ctx.disabled,
        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('change', ($event.target as HTMLSelectElement).value)))
      }), [
        (_ctx.$attrs && _ctx.$attrs.placeholder)
          ? (_openBlock(), _createElementBlock("option", _hoisted_2, _toDisplayString(_ctx.$attrs.placeholder), 1))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.source, (item, index) => {
          return (_openBlock(), _createElementBlock("option", {
            key: index,
            value: _ctx.itemValueKey ? item[_ctx.itemValueKey] : item
          }, _toDisplayString(_ctx.itemTextKey ? item[_ctx.itemTextKey] : item), 9, _hoisted_3))
        }), 128))
      ], 16, _hoisted_1), [
        [_vModelSelect, _ctx.model]
      ]),
      (_ctx.clearable)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            type: "button",
            disabled: _ctx.disabled,
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.clearValue())),
            class: "mr-2 flex h-4 w-4 items-center justify-center"
          }, [
            _createVNode(_component_b_icon_cancel, {
              class: "w-4",
              size: "auto"
            })
          ], 8, _hoisted_4))
        : _createCommentVNode("", true)
    ]),
    _: 2
  }, [
    (_ctx.label)
      ? {
          name: "header",
          fn: _withCtx(() => [
            _createElementVNode("label", {
              style: _normalizeStyle(_ctx.forceLabelColor ? `color: ${_ctx.forceLabelColor}` : '')
            }, _toDisplayString(_ctx.label), 5)
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["variant", "block", "disabled", "icon", "outlined", "shadow", "show-focus"]))
}