import { canAccessIframe } from "@/utils";
import { DirectiveBinding } from "vue"

export type ClickOutsideParameter = {
    props: {
        open: boolean;
        triggerEl?: any;
        triggerId?: string;
    };
    handler: () => void;
    events: string[];
    detectIFrame: boolean;
};


export const clickOutside = {
    created(el, binding: DirectiveBinding<ClickOutsideParameter>) {
        el.clickOutsideEvent = (event) => {
            let dropdownEl = el;
            if (binding?.value?.props?.open) {
                return;
            }

            if (binding?.value?.props) {
                // On cherche l'element source
                if (!el && binding.value.props.triggerId) {
                    dropdownEl = document.getElementById(
                        binding.value.props.triggerEl
                    );
                }
                if (!el && binding.value.props.triggerEl) {
                    dropdownEl = binding.value.props.triggerEl;
                }
            }

            if (!dropdownEl) {
                return;
            }

            // On cherche si l'element cible est ou appartient à l'element source
            if (dropdownEl == event.target || dropdownEl.contains(event.target)) {
                return;
            }

            binding.value.handler();
        };
    },
    // On peut utilisé updated pour gerer les listeners car a la creation, le dropdown est fermé
    updated: (el, binding: DirectiveBinding<ClickOutsideParameter>) => {
        const isOpen = binding?.value?.props?.open;
        const detectIFrame = binding?.value?.detectIFrame;
        const targets = [document];
        const events = binding && binding.value && binding.value.events;

        if (!events.length) events.push("click");

        if (detectIFrame) {
            for (const iframe of document.getElementsByTagName("iframe")) {
                if (canAccessIframe(iframe)) {
                    targets.push(iframe.contentWindow.document);
                }
            }
        }

        // Si ouvert, on ajoute les listeners sinon on l'enleve
        targets.forEach((t) => {
            events.forEach((e) =>
                isOpen
                    ? t.addEventListener(e, el.clickOutsideEvent)
                    : t.removeEventListener(e, el.clickOutsideEvent)
            );
        });
    },
    unmounted: (el, binding: DirectiveBinding<ClickOutsideParameter>) => {
        const detectIFrame = binding?.value?.detectIFrame;
        const targets = [document];
        const events = binding?.value?.events;

        if (detectIFrame) {
            for (const iframe of document.getElementsByTagName("iframe")) {
                if (canAccessIframe(iframe)) {
                    targets.push(iframe.contentWindow.document);
                }
            }
        }

        targets.forEach((t) => {
            events.forEach((e) => t.removeEventListener(e, el.clickOutsideEvent));
        });
    }
}