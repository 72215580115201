import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "m-auto flex w-full h-full flex-1 flex-col items-center justify-center space-y-3 text-danger p-1" }
const _hoisted_2 = {
  key: 0,
  class: "text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Cancel = _resolveComponent("Cancel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Cancel, { size: "4xl" }),
    (_ctx.message)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.message), 1))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ]))
}